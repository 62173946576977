

































import { defineComponent } from '@vue/composition-api'
import { useStore } from '@/store/store'
import { useInnerTab } from '../../../inner-tab-template/useInnerTab'
import { priceHistoryApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import PricesHistoryChart from './PricesHistoryChart.vue'
import PricesHistoryGrid from './PricesHistoryGrid.vue'

import dayjs from 'dayjs'
import { RouteName } from '@/router/route-name'
import { useProductId } from '../../../useProductId'
import { useMetrics } from '@/utils/useMetrics'

export default defineComponent({
  components: {
    InnerTabTemplate,
    CurrentPeriodPicker,
    PricesHistoryChart,
    PricesHistoryGrid
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()

    const getPricesAnalysis = async () => {
      const res = await priceHistoryApi.getPriceHistoryV1(
        productId.value,
        dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
        dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
      )
      return res.data.data
    }

    const tabProps = useInnerTab({ fetchItems: getPricesAnalysis })

    const downloadAnalysis = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_prices_analysis_report', {
        period: `from_${from}_to_${to}`
      })

      return priceHistoryApi.priceHistoryDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const toComparison = { name: RouteName.PRODUCT_REVIEW__PRICES__COMPARISON }
    return {
      tabProps,
      downloadAnalysis,
      toComparison
    }
  }
})
