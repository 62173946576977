




















import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup () {
    const href = process.env.VUE_APP_SELLER_CABINET_URL + '/marketplaces'

    return {
      href
    }
  }
})
