































































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ProductContentBlock, ProductContentRecommendationDto, ProductContentRecommendationStatus } from '@/openapi'
import { ProductContentEntity } from '@/views/product-review/tabs/content/content-analysis/productContentEntity'
import AddCompetitorForm from '@/views/product-review/AddCompetitorForm.vue'
import { formatCurrencyWithSymbol } from '@/utils/formatters'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  components: { AddCompetitorForm },
  props: {
    data: Object as PropType<ProductContentEntity>
  },
  setup (props) {
    const i18n = useI18n()

    const computedRecommendations = computed(() => {
      if (props.data?.block === ProductContentBlock.RATING_REVIEW) {
        return [
          props.data.recommendations?.filter(el => el.block === ProductContentBlock.RATING),
          props.data.recommendations?.filter(el => el.block === ProductContentBlock.REVIEW)
        ]
      }
      return [props.data?.recommendations]
    })

    const recommendationTitle = (recommendation: ProductContentRecommendationDto) => {
      switch (recommendation.block) {
        case ProductContentBlock.REVIEW:
          return i18n.t('Reviews')
        case ProductContentBlock.RATING:
          return i18n.t('Rating')
        default:
          return null
      }
    }

    const alertType = (recommendation: ProductContentRecommendationDto) => {
      switch (recommendation.status) {
        case ProductContentRecommendationStatus.BAD:
          return 'error'
        case ProductContentRecommendationStatus.SOSO:
          return 'warning'
        case ProductContentRecommendationStatus.GOOD:
          return 'success'
      }
    }

    return {
      computedRecommendations,
      recommendationTitle,
      alertType,
      ProductContentBlock,
      formatCurrencyWithSymbol
    }
  }
})
