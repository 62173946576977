import { ConcurrentPositionEntity, SearchType } from '@/openapi'
import { ref, watch, WatchSource } from '@vue/composition-api'

export function useSearchTypeFilter<T extends ConcurrentPositionEntity[] | undefined> (items: WatchSource<T>) {
  const searchType = ref<SearchType | null>()

  watch(items, items => {
    if (items?.length) {
      const notSelected = !searchType.value
      const notAvailable = !items.find(el => el.searchType === searchType.value)

      if (notSelected) {
        searchType.value = items[0].searchType
      } else if (notAvailable) {
        searchType.value = items.find(el => el.searchType !== searchType.value)?.searchType
      }
    } else {
      searchType.value = null
    }
  })

  return {
    searchType
  }
}
