import { Marketplace } from '@/DTO/Marketplace'

export interface Shop {
  id?: number
  name?: string
  isActive?: boolean
  marketplace?: Marketplace
  productCount?: number
  createdAt?: string,
  is_paywalled_in_lkp: boolean
}

export function convertToShop (rowShop: Record<string, any>): Shop {
  return {
    id: rowShop.id,
    name: rowShop.name,
    marketplace: {
      code: rowShop.marketplace_code,
      name: rowShop.marketplace_name,
      id: rowShop.marketplace_id
    },
    createdAt: rowShop.created,
    isActive: rowShop.is_active,
    productCount: rowShop.product_count,
    is_paywalled_in_lkp: rowShop.is_paywalled_in_lkp
  }
}

export function convertToShopArray (rowShops: Record<string, any>[]): Shop[] {
  return rowShops.map(el => convertToShop(el))
}

export function prioritizeByMP (shops: Shop[]): Shop[] {
  return shops.sort((a, b) => {
    if (Number(a.marketplace?.code === 'ozon') > Number(b.marketplace?.code === 'ozon')) return -1
    if (Number(a.marketplace?.code === 'ozon') < Number(b.marketplace?.code === 'ozon')) return 1
    return 0
  }).sort((a, b) => {
    if (Number(a.marketplace?.code === 'wildberries') > Number(b.marketplace?.code === 'wildberries')) return -1
    if (Number(a.marketplace?.code === 'wildberries') < Number(b.marketplace?.code === 'wildberries')) return 1
    return 0
  }).sort((a, b) => {
    if (Number(a.is_paywalled_in_lkp) > Number(b.is_paywalled_in_lkp)) return 1
    if (Number(a.is_paywalled_in_lkp) < Number(b.is_paywalled_in_lkp)) return -1
    return 0
  })
}
