import { ShopEntity } from '@/openapi'

export const shopIconMapper = (shop: ShopEntity) => {
  switch (shop.marketplace?.id) {
    case 1: return 'aliexpress'
    case 2: return 'ozon'
    case 4: return 'yandex-market'
    case 5: return 'sber-mega-market'
    case 9: return 'wildberries'
    case 10: return 'bolshoy-kancelyarskiy'
  }
}
