
























import { computed, defineComponent, PropType } from '@vue/composition-api'
import DataChart from '../../../data-chart/DataChart.vue'
import { ConcurrentIndicatorEntity, ConcurrentIndicatorsHistory, IndicatorFieldType } from '@/openapi'
import { indicatorFormatter } from '../indicatorFormatter'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { YAxisOptions } from 'highcharts'
import { primaryItemMatcher } from './indicatorsPrimaryItemMatcher'
import { formatProductName } from '../../../formatters'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: Array as PropType<ConcurrentIndicatorsHistory[]>,
    selectedItems: Array as PropType<ConcurrentIndicatorsHistory[]>,
    loading: Boolean,
    indicator: Object as PropType<ConcurrentIndicatorEntity>
  },
  setup (props) {
    const yAxis = computed<YAxisOptions>(() => ({
      allowDecimals: props.indicator?.field === IndicatorFieldType.CONVERSION_TO_BIN
    }))

    const bindSeriesData = (items?: ConcurrentIndicatorsHistory[]) => {
      return items?.map(el => ({
        ...el,
        data: el.history.map(el => el.value ?? null)
      }))
    }

    const series = computed(() => bindSeriesData(props.items))
    const availableSeries = computed(() => bindSeriesData(props.selectedItems))

    const seriesKey = (series: ConcurrentIndicatorsHistory) => {
      return series.product.article
    }

    const tooltipItemFormatter: TooltipItemFormatter<ConcurrentIndicatorsHistory> = item => {
      return `
        <span class="font-weight-semibold">${item.shop.name}:</span>
        ${formatProductName(item)}
      `
    }

    const tooltipItemValueFormatter: TooltipItemFormatter<ConcurrentIndicatorsHistory> =
      (item, ctx) =>
        indicatorFormatter(props.indicator!, ctx.y)

    return {
      yAxis,
      series,
      availableSeries,
      seriesKey,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      primaryItemMatcher,
      formatProductName
    }
  }
})
