import { ConcurrentIndicatorEntity, IndicatorEntity, IndicatorFieldType } from '@/openapi'
import i18n from '@/plugins/vue-i18n'

export function indicatorNameMapper (item: IndicatorEntity | ConcurrentIndicatorEntity) {
  switch (item.field) {
    case IndicatorFieldType.ADDED_TO_BIN: return i18n.t('Added to cart')
    case IndicatorFieldType.CANCELED: return i18n.t('Refusals')
    // case IndicatorFieldType.CLICKED: return i18n.t('Clicks')
    case IndicatorFieldType.CONVERSION_TO_BIN: return i18n.t('Cart conversion')
    // case IndicatorFieldType.CTR: return 'CTR'
    case IndicatorFieldType.GMV: return 'GMV, ₽'
    case IndicatorFieldType.ORDERED: return i18n.t('Ordered, pcs')
    case IndicatorFieldType.ORDERED_MONEY: return i18n.t('Ordered, rub')
    case IndicatorFieldType.PRICE: return i18n.t('Price before discount, rub')
    case IndicatorFieldType.DISCOUNT_PRICE: return i18n.t('Current price, rub')
    case IndicatorFieldType.RETURNED: return i18n.t('Returns')
    case IndicatorFieldType.SHOWED: return i18n.t('Impressions')
    case IndicatorFieldType.STOCK: return i18n.t('Remainders')
    case IndicatorFieldType.RATING: return i18n.t('Rating')
    case IndicatorFieldType.REVIEWS: return i18n.t('Reviews')
    case IndicatorFieldType.ORDERED_ALIWAY: return i18n.t('Ordered MMP, pcs')
    case IndicatorFieldType.ORDERED_MONEY_ALIWAY: return i18n.t('Ordered MMP, rub')
    case IndicatorFieldType.UNKNOWN: return i18n.t('Unknown')
    case IndicatorFieldType.REDEEMED_COUNT: return i18n.t('Redeemed, pcs')
    case IndicatorFieldType.REDEEMED_MONEY: return i18n.t('Redeemed, rub')
  }
}
