




























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { searchTypeMapper } from '../../../searchTypeMapper'
import { IndicatorEntity, ProductPositionEntity } from '@/openapi'
import DataChart from '../../../data-chart/DataChart.vue'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { formatCount } from '@/utils/formatters'
import { formatShopName } from './formatShopName'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'
import { indicatorNameMapper } from '../../indicators/indicatorNameMapper'
import { indicatorFormatter } from '@/views/product-review/tabs/indicators/indicatorFormatter'
import { extraSeriesColors } from '@xway-team/ui/src/utils/colors'
import { YAxisOptions } from 'highcharts'
import { useI18n } from '@/utils/useI18n'

const colors = Object.keys(extraSeriesColors)

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: Array as PropType<ProductPositionEntity[]>,
    selectedItems: Array as PropType<ProductPositionEntity[]>,
    extraItems: Array as PropType<IndicatorEntity[]>,
    selectedExtraItems: Array as PropType<IndicatorEntity[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const MAIN_AXIS_ID = 'MAIN'

    const bindSeriesData = (items?: ProductPositionEntity[]) => {
      return items?.map(el => ({
        ...el,
        yAxis: MAIN_AXIS_ID,
        data: el.positions.map(el => el.position?.current ?? null)
      }))
    }

    const series = computed(() => bindSeriesData(props.items))
    const availableSeries = computed(() => bindSeriesData(props.selectedItems))

    const extraSeriesKey = (item: IndicatorEntity) => item.field

    const bindExtraSeriesData = (items?: IndicatorEntity[]) => {
      return items?.map(el => ({
        ...el,
        yAxis: extraSeriesKey(el),
        data: el.history.map(el => el.value ?? null)
      }))
    }

    const extraSeries = computed(() => bindExtraSeriesData(props.extraItems))
    const availableExtraSeries = computed(() => bindExtraSeriesData(props.selectedExtraItems))

    const seriesKey = formatShopName

    const yAxis = computed<YAxisOptions | YAxisOptions[]>(() => {
      const axis = { id: MAIN_AXIS_ID }

      if (extraSeries.value) {
        return [axis, ...extraSeries.value.map((el, i) => ({
          id: extraSeriesKey(el),
          className: `highcharts-yaxis-labels--${colors[i % colors.length]}`,
          opposite: true,
          reversed: false,
          min: 0,
          type: 'linear'
        }))] as YAxisOptions[]
      }

      return axis
    })

    const tooltipItemFormatter: TooltipItemFormatter<ProductPositionEntity> =
      item => `
        <span class="font-weight-semibold">${searchTypeMapper(item.searchType)}.</span>
        ${item.field}
      `

    const tooltipItemValueFormatter: TooltipItemFormatter<ProductPositionEntity> =
      (item, ctx) => i18n.t('Place', { place: formatCount(ctx.y) })

    const tooltipExtraItemFormatter: TooltipItemFormatter<IndicatorEntity> = indicatorNameMapper

    const tooltipExtraItemValueFormatter: TooltipItemFormatter<IndicatorEntity> =
      (item, ctx) => indicatorFormatter(item, ctx.y)

    return {
      series,
      availableSeries,
      extraSeries,
      availableExtraSeries,
      seriesKey,
      extraSeriesKey,
      yAxis,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      tooltipExtraItemFormatter,
      tooltipExtraItemValueFormatter,
      searchTypeMapper,
      formatShopName
    }
  }
})
