

























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { usePeriodColumns } from '../../../usePeriodColumns'
import { ConcurrentPositionEntitySpanRow } from './positionsComparisonTypes'
import DataChart from '../../../data-chart/DataChart.vue'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { primaryItemMatcher } from './positionsPrimaryItemMatcher'
import { formatShopName } from './formatShopName'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: Array as PropType<ConcurrentPositionEntitySpanRow[]>,
    selectedItems: Array as PropType<ConcurrentPositionEntitySpanRow[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const periodColumns = usePeriodColumns()

    const bindSeriesData = (items?: ConcurrentPositionEntitySpanRow[]) => {
      return items?.map(el => ({
        ...el,
        data: periodColumns.value.map(col => el[col.prop]?.current ?? null)
      }))
    }

    const series = computed(() => bindSeriesData(props.items))
    const availableSeries = computed(() => bindSeriesData(props.selectedItems))

    const seriesKey = (item: ConcurrentPositionEntitySpanRow) => item.product.article

    const formatProductName = (item: ConcurrentPositionEntitySpanRow) =>
      item.isMy
        ? i18n.t('Your product')
        : item.product.name

    const tooltipItemFormatter: TooltipItemFormatter<ConcurrentPositionEntitySpanRow> = item => {
      return `
        <span class="font-weight-semibold">${item.product.shop?.name}:</span>
        ${formatProductName(item)}:
      `
    }

    const tooltipItemValueFormatter: TooltipItemFormatter<ConcurrentPositionEntitySpanRow> =
      (item, ctx) =>
        i18n.t('Place', { place: ctx.y }) as string

    return {
      series,
      availableSeries,
      seriesKey,
      formatProductName,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      primaryItemMatcher,
      formatShopName
    }
  }
})
