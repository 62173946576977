





































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { RouteName } from '@/router/route-name'
import { ProductEntity } from '@/openapi'
import { shopIconMapper } from '@/utils/shopIconMapper'
import { AppQuery } from '@/types'
import { XDataColumn } from '@xway-team/ui'
import { formatCurrency, formatCount } from '@/utils/formatters'
import ProductStatusBadge from '@/components/ProductStatusBadge.vue'
import { useStore } from '@/store/store'
import dayjs from 'dayjs'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  components: {
    ProductStatusBadge
  },
  inheritAttrs: false,
  props: {
    products: {
      type: Array as PropType<ProductEntity[]>
    },
    loading: Boolean
  },
  setup () {
    const store = useStore()
    const i18n = useI18n()

    const isWaitingUpload = computed(() => {
      return store.currentShop?.productCount === 0
    })

    const shopMarketplaceName = computed(() => {
      return store.currentShop?.marketplace?.name
    })

    const columns = computed<XDataColumn<ProductEntity>[]>(() => {
      const { start, end } = store.currentPeriod
      const template = 'DD.MM.YY'
      const currentPeriod = dayjs(start).format(template) + ' – ' + dayjs(end).format(template)

      return [
        {
          prop: 'photo',
          name: i18n.t('photo'),
          minWidth: 82
        },
        {
          prop: 'name',
          name: i18n.t('heading'),
          minWidth: 294
        },
        {
          prop: 'price',
          name: i18n.t('price, rub'),
          tooltip: i18n.t('Product price for', [currentPeriod]),
          align: 'end',
          minWidth: 109,
          sortable: true,
          formatter: item => formatCurrency(item.price)
        },
        {
          prop: 'ordersMoney',
          name: i18n.t('products ordered rub'),
          tooltip: i18n.t('Sum of all ordered products for', [currentPeriod]),
          align: 'end',
          minWidth: 109,
          sortable: true,
          formatter: item => formatCurrency(item.ordersMoney)
        },
        {
          prop: 'ordersCount',
          name: i18n.t('products ordered pcs'),
          tooltip: i18n.t('Number of products ordered for', [currentPeriod]),
          align: 'end',
          minWidth: 107,
          sortable: true,
          formatter: item => formatCount(item.ordersCount)
        },
        {
          prop: 'redeemedMoney',
          name: i18n.t('Redeemed products, rub'),
          tooltip: i18n.t('Sum of all redeemed products for', [currentPeriod]),
          align: 'end',
          minWidth: 111,
          sortable: true,
          formatter: item => formatCurrency(item.redeemedMoney)
        },
        {
          prop: 'redeemedCount',
          name: i18n.t('Redeemed products, pcs'),
          tooltip: i18n.t('Number of redeemed products for', [currentPeriod]),
          align: 'end',
          minWidth: 108,
          sortable: true,
          formatter: item => formatCount(item.redeemedCount)
        },
        {
          prop: 'stock',
          name: i18n.t('Stock'),
          tooltip: i18n.t('The number of product stocks at the moment'),
          align: 'end',
          minWidth: 80,
          sortable: true,
          formatter: item => formatCount(item.stock)
        }
      ]
    })

    const columnsWithTooltips = computed(() => columns.value.filter(el => el.tooltip))

    const getProductReviewLocation = (product: ProductEntity) => ({
      name: RouteName.PRODUCT_REVIEW,
      params: { productId: String(product.id) },
      query: { [AppQuery.SHOP]: store.currentShop?.id }
    })

    return {
      columns,
      columnsWithTooltips,
      isWaitingUpload,
      shopMarketplaceName,
      getProductReviewLocation,
      shopIconMapper
    }
  }
})
