
























































import { computed, defineComponent } from '@vue/composition-api'
import { useInnerTab, useItemsFilter, useSelectedItems } from '../../../inner-tab-template/useInnerTab'
import { useStore } from '@/store/store'
import { concurrentIndicatorApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import IndicatorsComparisonChart from './IndicatorsComparisonChart.vue'
import IndicatorsComparisonGrid from './IndicatorsComparisonGrid.vue'
import CompetitorsLink from '../../../CompetitorsLink.vue'

import dayjs from 'dayjs'
import { indicatorNameMapper } from '../indicatorNameMapper'
import { useProductId } from '../../../useProductId'
import { useMetrics } from '@/utils/useMetrics'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: {
    NotPermitByTariff,
    InnerTabTemplate,
    IndicatorsComparisonChart,
    IndicatorsComparisonGrid,
    CurrentPeriodPicker,
    CompetitorsLink
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()

    const downloadComparison = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_indicators_compare_report', {
        period: `from_${from}_to_${to}`
      })

      return concurrentIndicatorApi.concurrentIndicatorDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const tab = useInnerTab({
      async fetchItems () {
        const res = (await concurrentIndicatorApi.getConcurrentIndicatorV1(
          productId.value,
          dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
          dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
        )).data.data

        if (res) {
          res.forEach(el => {
            el.history.forEach(el => {
              el.history.forEach(his => {
                // @ts-ignore
                el[his.date] = his.value
                // @ts-ignore
                // TODO: remove
                el.id = el.product.name
              })
            })
          })
        }

        return res
      },
      trackCompetitorsChanges: true
    })

    const { filter, filtersList, filteredItems } = useItemsFilter(tab, {
      getFilteredItems: (items, filter) => items?.find(el => el.field === filter.field)?.history,
      optionTextMapper: indicatorNameMapper
    })

    const { selectedItems } = useSelectedItems(filteredItems, {
      itemKey: item => item.product.article
    })

    const noCompetitors = computed(() => filteredItems.value?.length === 1 && filteredItems.value[0].isMy)

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_COMPETITORS_COMPARE)
    })

    return {
      tab,
      filter,
      filtersList,
      filteredItems,
      selectedItems,
      downloadComparison,
      noCompetitors,
      isPermitByTariff
    }
  }
})
