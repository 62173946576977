
















import { RouteName } from '@/router/route-name'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup () {
    const toCompetitors = { name: RouteName.PRODUCT_REVIEW__COMPETITORS }

    return {
      toCompetitors
    }
  }
})
