





















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { HistoryEntity, IndicatorEntity, IndicatorFieldType } from '@/openapi'
import { indicatorFormatter } from '../indicatorFormatter'
import { indicatorNameMapper } from '../indicatorNameMapper'
import { XDataColumn, XDataCustomDateColumn } from '@xway-team/ui'
import { indicatorTooltipMapper } from '@/views/product-review/tabs/indicators/indicatorTooltipMapper'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'
import { useI18n } from '@/utils/useI18n'
import { formatCountWithSign } from '@/utils/formatters'

type IndicatorEntityExtended = IndicatorEntity & {
  [key: string]: HistoryEntity['value']
}

export default defineComponent({
  components: {
    DataSourceTooltip
  },
  props: {
    items: Array as PropType<IndicatorEntity[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('Indicators'),
        prop: 'field',
        minWidth: 165,
        fixedLeft: true
      }
    ] as XDataColumn<IndicatorEntityExtended>[])

    const customDateColumn: XDataCustomDateColumn = col => ({
      ...col,
      formatter: item => indicatorFormatter(item, item[col.prop!])
    })

    const mappedItems = computed(() => {
      return (props.items as IndicatorEntityExtended[])?.map(el => {
        el.history.forEach(pos => {
          el[pos.date!] = pos.value
        })

        return el
      })
    })

    const rowClasses = (item: IndicatorEntity) => {
      if (item.field === IndicatorFieldType.ORDERED || item.field === IndicatorFieldType.GMV) {
        return ['gray-lightest-1', 'font-weight-semibold']
      }
    }

    return {
      columns,
      customDateColumn,
      mappedItems,
      formatCountWithSign,
      indicatorTooltipMapper,
      rowClasses,
      indicatorNameMapper
    }
  }
})
