import { ConcurrentIndicatorsHistory, ConcurrentPriceHistory } from '@/openapi'
import i18n from '@/plugins/vue-i18n'

export function formatShopName (
  item: ConcurrentIndicatorsHistory | ConcurrentPriceHistory
) {
  return item.isMy ? i18n.t('Your product') : item.shop.name
}

export function formatProductName (
  item: ConcurrentIndicatorsHistory | ConcurrentPriceHistory
) {
  return item.isMy ? i18n.t('Your product') : item.product.name
}
