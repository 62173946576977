import { sellerApi } from '@/api'
import { getRouteQuery, setRouteQuery } from '@/utils/routeQuery'
import { ShopEntity, MpRegexpSourceUrl } from '@/openapi'
import { defineStore } from 'pinia'
import axios from 'axios'
import dayjs from 'dayjs'
import { AppQuery, Period } from '@/types'
import {
  getThemeNameByCabinetType,
  applyTheme,
  clearTheme,
  getCompanyDetails,
  getDefaultCompanyDetails
  // @ts-ignore
} from '@xway-team/theme-detector'
import { setCompanyDetails } from '@/plugins/companyDetails'
import { convertToUser, User } from '@/DTO/User'
import { convertToShopArray, prioritizeByMP } from '@/DTO/Shop'
import { PermissionKey } from '@/constants/PERMISSION_KEY'

export const useStore = defineStore({
  id: 'root',
  state: () => {
    return {
      user: {} as User,
      loading: false,
      currentShop: null as ShopEntity | null,
      currentPeriod: {} as Period,
      marketplacesRegexp: [] as MpRegexpSourceUrl[]
    }
  },
  getters: {
    hasShops: state => !!state.user.shops?.length,

    isCurrentShopAvailable: state => {
      // TODO: introduce marketplace name-to-id map
      const marketplaceId = state.currentShop?.marketplace?.id
      return marketplaceId === 2 || marketplaceId === 9
    },

    dataCollectionDate: state => {
      const START_OF_COLLECTION = '2022-02-01'

      const collectStartedAt = dayjs(START_OF_COLLECTION).startOf('date')
      const shopCreatedAt = dayjs(state.currentShop?.createdAt).startOf('date')

      return shopCreatedAt.isAfter(collectStartedAt)
        ? shopCreatedAt
        : collectStartedAt
    },

    minDate: state => {
      const START_OF_COLLECTION = '2022-02-01'

      const collectStartedAt = dayjs(START_OF_COLLECTION).startOf('date')
      const shopCreatedAt = dayjs(state.currentShop?.createdAt).startOf('date')
      const sixDaysAgo = dayjs().subtract(6, 'day').startOf('date')

      if (shopCreatedAt.isBefore(collectStartedAt)) {
        return collectStartedAt
      }

      if (shopCreatedAt.isAfter(sixDaysAgo)) {
        return sixDaysAgo
      }

      return shopCreatedAt
    },

    isPermitByTariff: state => (permissionKey: PermissionKey) => state.user.active_subscription?.permission_keys?.includes(permissionKey)
  },
  actions: {
    async fetch () {
      this.loading = true
      try {
        const rowUser = (await sellerApi.getUser()).data.data
        const rowShops = (await sellerApi.getShopList({})).data?.results

        const shops = prioritizeByMP(convertToShopArray(rowShops))
        const user = convertToUser(rowUser)

        user.shops = shops

        const queryShopId = getRouteQuery(AppQuery.SHOP)

        let shop: ShopEntity | null = null

        if (shops) {
          const shopIndex = shops.findIndex((el: any) => el.id === Number(queryShopId))

          shop = shops[shopIndex !== -1 ? shopIndex : 0]

          if (shop && !queryShopId) {
            setRouteQuery(AppQuery.SHOP, shop.id)
          }
        }

        axios.interceptors.request.use(config => {
          config.headers['X-XW-Shop-Id'] = this.currentShop?.id
          return config
        })

        this.user = user
        this.currentShop = shop

        const queryDateFrom = getRouteQuery(AppQuery.DATE_FROM)
        const queryDateTo = getRouteQuery(AppQuery.DATE_TO)

        const currentPeriod = getLastWeek()

        if (queryDateFrom) {
          currentPeriod.start = dayjs(queryDateFrom).startOf('date').valueOf()
        }
        if (queryDateTo) {
          currentPeriod.end = dayjs(queryDateTo).startOf('date').valueOf()
        }

        this.setCurrentPeriod(currentPeriod)

        if (user.is_demo_account) return

        const themeName = getThemeNameByCabinetType(user.cabinet_type)
        if (themeName) {
          applyTheme(themeName)
          setCompanyDetails(getCompanyDetails(themeName))
        } else {
          clearTheme()
          setCompanyDetails(getDefaultCompanyDetails())
        }

        this.loading = false
      } catch (err) {
        console.error(err)
      }
    },
    setCurrentPeriod (period: Period) {
      const minDate = this.minDate.valueOf()
      const maxDate = dayjs().startOf('date').subtract(1, 'day').valueOf()

      const start = period.start < minDate ? minDate : period.start
      const end = period.end > maxDate ? maxDate : period.end

      setRouteQuery(AppQuery.DATE_FROM, dayjs(start).format('YYYY-MM-DD'))
      setRouteQuery(AppQuery.DATE_TO, dayjs(end).format('YYYY-MM-DD'))

      this.currentPeriod = {
        start,
        end
      }
    },
    resetCurrentPeriod () {
      this.currentPeriod = getLastWeek()
    }
  }
})

function getLastWeek (): Period {
  return {
    start: dayjs().startOf('date').subtract(7, 'day').valueOf(),
    end: dayjs().startOf('date').subtract(1, 'day').valueOf()
  }
}
