import router from '@/router'
import { AppQuery } from '@/types'

export function getRouteQuery (
  name: AppQuery
) {
  const data = router.currentRoute.query[name]

  return Array.isArray(data)
    ? data[0]
    : data
}

export function setRouteQuery (
  name: AppQuery,
  val: any
) {
  router.replace({
    query: {
      ...router.currentRoute.query,
      [name]: val
    }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  }).catch(() => {})
}
