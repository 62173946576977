import { render, staticRenderFns } from "./ProductsAdvices.vue?vue&type=template&id=7cb0bc20&"
import script from "./ProductsAdvices.vue?vue&type=script&lang=ts&"
export * from "./ProductsAdvices.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports