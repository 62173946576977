import {
  Configuration,
  ClientApiFactory,
  ProductsApiFactory,
  MarketplaceApiFactory,
  ReportsApiFactory,
  CoreApiFactory,
  PositionApiFactory,
  ConcurrentApiFactory,
  IndicatorApiFactory,
  ConcurrentIndicatorApiFactory,
  ConcurrentPriceHistoryApiFactory,
  ConcurrentStockHistoryApiFactory,
  PriceHistoryApiFactory,
  StockHistoryApiFactory,
  ContentApiFactory
} from '@/openapi'

import axios from 'axios'
import credentials from './utils/credentials'

const config = new Configuration({
  basePath: process.env.VUE_APP_API_URL
})

export const clientApi = ClientApiFactory(config)
export const productsApi = ProductsApiFactory(config)
export const marketplaceApi = MarketplaceApiFactory(config)
export const reportsApi = ReportsApiFactory(config)
export const coreApi = CoreApiFactory(config)
export const positionsApi = PositionApiFactory(config)
export const concurrentApi = ConcurrentApiFactory(config)
export const indicatorApi = IndicatorApiFactory(config)
export const concurrentIndicatorApi = ConcurrentIndicatorApiFactory(config)
export const concurrentPriceHistoryApi = ConcurrentPriceHistoryApiFactory(config)
export const concurrentStockHistoryApi = ConcurrentStockHistoryApiFactory(config)
export const priceHistoryApi = PriceHistoryApiFactory(config)
export const stockHistoryApi = StockHistoryApiFactory(config)
export const contentApi = ContentApiFactory(config)

const sellerInstance = axios.create({
  baseURL: process.env.VUE_APP_SELLER_API,
  headers: {
    'x-xway-auth': credentials.getToken()
  }
})

export const sellerApi = {
  getShopList (params: Record<string, unknown>) {
    return sellerInstance.get('/seller_cabinet/v1/shops/', { params })
  },

  getUser () {
    return sellerInstance.get('seller_cabinet/v1/me')
  },
  orderRecommendations (page: string) {
    const url = 'seller_cabinet/v1/sme_click_order'
    return sellerInstance.post(url, { page })
  },
  initTinkoffPayment (payload: {
    amount?: number,
    tariff_id: number,
    term?: number,
    is_recurrent?: boolean
  }) {
    const url = '/api/v1/payment/tinkoff/init/'
    return sellerInstance.post(url, {
      ...payload,
      is_recurrent: true
    })
  }
}
