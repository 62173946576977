import Vue from 'vue'
import Highcharts from 'highcharts'
import HighchartsVue from 'highcharts-vue'
import boostInit from 'highcharts/modules/boost'

Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'proxima_nova'
    }
  },
  credits: {
    enabled: false
  }
})

boostInit(Highcharts)

Vue.use(HighchartsVue)
