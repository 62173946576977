import '@xway-team/ui/dist/index.css'
import Vue from 'vue'
import UI, { dialogPlugin, PluginOptions } from '@xway-team/ui'
import { ErrorType } from '@xway-team/ui/src/components/XPlaceholder/ErrorType'

import i18n from '@/plugins/vue-i18n'
import { ErrorCode } from '@/openapi'
import { isCustomError } from '@/utils/isCustomError'
import companyDetails from '@/plugins/companyDetails'

const options: PluginOptions = {
  breadcrumbsBase: {
    to: '/',
    text: () => i18n.t('internal analytics')
  },
  companyDetails,
  errorTypeResolver (err, defaultResolver) {
    if (isCustomError(err)) {
      const code = err.data.error.code

      if (code) {
        switch (code) {
          case ErrorCode.DATABASE_ERROR:
          case ErrorCode.CONNECTION_ERROR:
            return ErrorType.WORKS
          case ErrorCode.ITEM_NOT_FOUND:
            return ErrorType.NOT_FOUND
          default:
            return ErrorType.UNEXPECTED
        }
      }
    }

    return defaultResolver(err)
  }
}

Vue.use(UI, options)
Vue.use(dialogPlugin)
