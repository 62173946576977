








import { useI18n } from '@/utils/useI18n'
import { RouteName } from '@/router/route-name'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup () {
    const i18n = useI18n()

    const innerTabs = computed(() => [
      {
        title: i18n.t('Analysis'),
        to: { name: RouteName.PRODUCT_REVIEW__PRICES__HISTORY }
      },
      {
        title: i18n.t('Comparison with competitors'),
        to: { name: RouteName.PRODUCT_REVIEW__PRICES__COMPARISON }
      }
    ])

    return {
      innerTabs
    }
  }
})
