
































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ProductPositionEntity } from '@/openapi'
import { searchTypeMapper } from '../../../searchTypeMapper'
import { XDataColumn, XDataCustomSort } from '@xway-team/ui'
import { formatShopName } from './formatShopName'
import { useI18n } from '@/utils/useI18n'
import { formatCountWithSign, formatCount } from '@/utils/formatters'
import {
  ProductPositionEntityExtended
} from './positionsHistoryTypes'
import { sortPositions } from '../sortPositions'
import { sortQueryPopularity } from '../sortQueryPopularity'

export default defineComponent({
  props: {
    items: Array as PropType<ProductPositionEntity[]>,
    selectedItems: Array as PropType<ProductPositionEntity[]>,
    loading: Boolean
  },
  setup () {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('Category'),
        prop: 'field',
        formatter: formatShopName,
        minWidth: 220,
        fixedLeft: true
        // sortable: true
      },
      {
        name: i18n.t('Type'),
        prop: 'searchType',
        minWidth: 84,
        fixedLeft: true
      },
      {
        name: i18n.t('Frequency'),
        prop: 'queryPopularity',
        align: 'end',
        minWidth: 120,
        fixedLeft: true,
        sortable: true,
        formatter: item => formatCount(item.queryPopularity)
      }
    ] as XDataColumn<ProductPositionEntityExtended>[])

    const customDateColumn = {
      sortable: true
    }

    const itemKey = formatShopName

    const customSort: XDataCustomSort = (
      items,
      sort
    ) => {
      return (sort.prop as keyof ProductPositionEntityExtended) === 'queryPopularity'
        ? sortQueryPopularity(items, sort)
        : sortPositions(items, sort)
    }

    const progressClasses = (val: number) => val < 0 ? 'success--text' : 'error--text'

    return {
      columns,
      customDateColumn,
      searchTypeMapper,
      itemKey,
      customSort,
      progressClasses,
      formatCountWithSign,
      formatCount
    }
  }
})
