const permissions = {
  ANALYTICS_INDICATORS: 'analytics.indicators',
  ANALYTICS_POSITIONS: 'analytics.positions',
  ANALYTICS_ABC_REPORT: 'analytics.abc_report',
  ANALYTICS_CONTENT: 'analytics.content',
  ANALYTICS_PRICES: 'analytics.prices',
  ANALYTICS_COMPETITORS_COMPARE: 'analytics.competitors_compare'
} as const

export default permissions

export type PermissionKey = typeof permissions[keyof typeof permissions]
