<script>
import CallbackDialog from './CallbackDialog.vue'

export default {
  render: h => h(null),
  created () {
    this.$dialog.show(CallbackDialog)
  }
}
</script>
