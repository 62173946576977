












































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import { PermissionKey } from '@/constants/PERMISSION_KEY'
import { useStore } from '@/store/store'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: {
    NotPermitByTariff,
    CurrentPeriodPicker
  },
  props: {
    title: String as PropType<TranslateResult>,
    alert: String as PropType<TranslateResult>,
    download: Function as PropType<() => Promise<any>>,
    metrics: String,
    permissionKey: {
      type: String as PropType<PermissionKey>,
      required: true
    },
    datePicker: {
      type: Boolean,
      default: true
    },
    divider: {
      type: Boolean,
      default: true
    }
  },

  setup (props) {
    const store = useStore()

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(props.permissionKey)
    })

    return {
      isPermitByTariff
    }
  }
})
