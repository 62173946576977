































import { defineComponent } from '@vue/composition-api'
import { useStore } from '@/store/store'
import { useInnerTab } from '../../../inner-tab-template/useInnerTab'
import { stockHistoryApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import StocksHistoryChart from './StocksHistoryChart.vue'
import StocksHistoryGrid from './StocksHistoryGrid.vue'

import dayjs from 'dayjs'
import { RouteName } from '@/router/route-name'
import { useProductId } from '@/views/product-review/useProductId'
import { useMetrics } from '@/utils/useMetrics'

export default defineComponent({
  components: {
    InnerTabTemplate,
    CurrentPeriodPicker,
    StocksHistoryChart,
    StocksHistoryGrid
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()

    const getStockAnalysis = async () => {
      const res = await stockHistoryApi.getStockHistoryV1(
        productId.value,
        dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
        dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
      )
      return res.data.data
    }

    const tabProps = useInnerTab({ fetchItems: getStockAnalysis })

    const downloadAnalysis = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_stock_analysis_report', {
        period: `from_${from}_to_${to}`
      })

      return stockHistoryApi.stockHistoryDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const toComparison = { name: RouteName.PRODUCT_REVIEW__STOCKS__COMPARISON }

    return {
      tabProps,
      downloadAnalysis,
      toComparison
    }
  }
})
