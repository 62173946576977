

















import { useStore } from '@/store/store'
import { computed, defineComponent, ref } from '@vue/composition-api'
import { setLocale } from '@/plugins/vue-i18n'
import { coreApi, sellerApi } from './api'
import { MenuDto } from './openapi'
import credentials from '@/utils/credentials'
import { useMetrics } from '@/utils/useMetrics'

export default defineComponent({
  setup () {
    const store = useStore()
    const metrics = useMetrics()

    const menuItems = ref<MenuDto[]>([])

    const tariff = computed(() => {
      const { active_subscription } = store.user
      return active_subscription && {
        name: active_subscription.tariff?.name,
        expiresAt: active_subscription.expiresAt
      }
    })

    const showTariffExpiredBanner = computed(() => {
      return store.user.hasDueBill
    })

    const orderRecommendations = () => {
      return sellerApi.orderRecommendations('Топбар')
    }

    const repeatPayment = async () => {
      const { id, term, is_recurrent } = store.user.future_tariff

      const res = await sellerApi.initTinkoffPayment({
        tariff_id: id,
        amount: store.user.next_bill?.amount,
        is_recurrent,
        term
      })

      const paymentUrl = res?.data?.data?.payment_url

      if (paymentUrl) {
        window.open(paymentUrl, '_blank')
      }
    }

    store.fetch().then(() => {
      metrics.useInterceptor(() => ({
        shop_id: store.currentShop?.id,
        marketplace_id: store.currentShop?.marketplace?.id,
        marketplace_code: store.currentShop?.marketplace?.code
      }))
    })

    coreApi.getCoreMenuV1().then(res => {
      menuItems.value = res.data.data || []
    })

    coreApi.getRegexpForMpLinkV1().then(({ data }) => {
      if (data && data.data?.length) store.marketplacesRegexp = data.data
    }).catch(err => console.log(err))

    const logout = () => {
      store.loading = true
      credentials.logout()
    }

    return {
      store,
      menuItems,
      tariff,
      showTariffExpiredBanner,
      orderRecommendations,
      repeatPayment,
      setLocale,
      logout
    }
  }
})
