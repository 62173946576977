











import { computed, defineComponent, PropType } from '@vue/composition-api'
import { HistoryEntity, PriceHistoryEntity, PriceHistoryFieldType } from '@/openapi'
import { priceIndicatorNameMapper } from '../priceIndicatorNameMapper'
import { XDataColumn, XDataCustomDateColumn } from '@xway-team/ui'
import { formatCountWithSign, formatCount, formatCurrency, formatPercent } from '@/utils/formatters'
import { useI18n } from '@/utils/useI18n'

type PriceHistoryEntityExtended = PriceHistoryEntity & {
  [key: string]: HistoryEntity['value']
}

export default defineComponent({
  props: {
    items: Array as PropType<PriceHistoryEntity[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('Indicators'),
        prop: 'field',
        formatter: priceIndicatorNameMapper,
        minWidth: 165,
        fixedLeft: true
      }
    ] as XDataColumn<PriceHistoryEntityExtended>[])

    const mappedItems = computed(() => {
      return (props.items as PriceHistoryEntityExtended[])?.map(el => {
        el.history.forEach(pos => {
          el[pos.date!] = pos.value
        })

        return el
      })
    })

    const customDateColumn: XDataCustomDateColumn<PriceHistoryEntityExtended> = el => ({
      ...el,
      formatter: item => {
        const val = item[el.prop!]

        switch (item.field) {
          case PriceHistoryFieldType.DISCOUNT_PRICE:
          case PriceHistoryFieldType.PRICE:
            return formatCurrency(val)
          case PriceHistoryFieldType.PROMO:
            return formatPercent(val)
          case PriceHistoryFieldType.SALES:
            return formatCount(val)
        }
      }
    })

    return {
      columns,
      mappedItems,
      customDateColumn,
      formatCountWithSign
    }
  }
})
