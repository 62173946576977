

















































import { computed, defineComponent, ref } from '@vue/composition-api'
import { concurrentApi } from '@/api'
import { ProductConcurrentEntity } from '@/openapi'
import DeleteCompetitorDialog from './DeleteCompetitorDialog.vue'
import AddCompetitorForm from '@/views/product-review/AddCompetitorForm.vue'
import { useBindCompetitors, useCompetitorsInjections } from '@/views/product-review/useBindCompetitors'
import { useProductId } from '@/views/product-review/useProductId'
import { useI18n } from '@/utils/useI18n'
import { useDialog } from '@/utils/useDialog'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import { useStore } from '@/store/store'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: { NotPermitByTariff, AddCompetitorForm },
  setup () {
    const i18n = useI18n()
    const dialog = useDialog()
    const store = useStore()

    const { invalidateCompetitors } = useCompetitorsInjections()

    const loading = ref(true)
    const items = ref<ProductConcurrentEntity[]>()

    const columns = computed(() => [{
      name: i18n.t('shop'),
      prop: 'shopName',
      minWidth: 152
    }, {
      name: i18n.t('Product'),
      prop: 'name',
      minWidth: 366
    }, {
      name: i18n.t('Article'),
      prop: 'article',
      minWidth: 174
    }, {
      name: i18n.t('Link'),
      prop: 'sourceUrl',
      minWidth: 334
    }, {
      name: '',
      prop: 'delete',
      minWidth: 48,
      align: 'end'
    }])

    const productId = useProductId()

    const fetchCompetitors = async () => {
      loading.value = true
      try {
        const res = await concurrentApi.getConcurrentV1(productId.value)
        items.value = res.data.data
      } finally {
        loading.value = false
      }
    }

    const showDeleteCompetitorDialog = (competitor: ProductConcurrentEntity) => {
      dialog.show(DeleteCompetitorDialog, () => ({
        productId: productId.value,
        competitor
      }), {
        success: invalidateCompetitors
      })
    }

    fetchCompetitors()
    useBindCompetitors(fetchCompetitors)

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_COMPETITORS_COMPARE)
    })

    return {
      loading,
      items,
      columns,
      showDeleteCompetitorDialog,
      isPermitByTariff
    }
  }
})
