import Vue from 'vue'
import {
  XCheckbox,
  XLayout,
  XPage,
  XBtn,
  XBtnGroup,
  XDialog,
  XInput,
  XSelect,
  XIcon,
  XTag,
  XCard,
  XLabel,
  XFormItem,
  XDivider,
  XImg,
  XLink,
  XLoader,
  XPlaceholder,
  XPaginator,
  XTabs,
  XPlacehandler,
  XErrorPlaceholder,
  XNoDataPlaceholder,
  XBadge,
  XRadio,
  XRating,
  XDropdown,
  XTooltip,
  XAlert,
  XClamp,
  XChip,
  XDotlineSpacer,
  XIconTooltip,
  XCollapse,
  XAdviceCard,
  XAdviceCardBtn,
  XDataList,
  XDataGrid,
  XSwitch
} from '@xway-team/ui'

import PeriodDataGrid from '@/components/PeriodDataGrid.vue'
import XDatePicker from '@/components/ui/XDatePicker.vue'
import XDownloadBtn from '@/components/ui/XDownloadBtn.vue'
import XUploadBtn from '@/components/ui/XUploadBtn.vue'
import XCircleLabel from '@/components/ui/XCircleLabel.vue'

Vue.component('XCheckbox', XCheckbox)
Vue.component('XLayout', XLayout)
Vue.component('XPage', XPage)
Vue.component('XBtn', XBtn)
Vue.component('XBtnGroup', XBtnGroup)
Vue.component('XDialog', XDialog)
Vue.component('XInput', XInput)
Vue.component('XSelect', XSelect)
Vue.component('XIcon', XIcon)
Vue.component('XTag', XTag)
Vue.component('XCard', XCard)
Vue.component('XLabel', XLabel)
Vue.component('XFormItem', XFormItem)
Vue.component('XDivider', XDivider)
Vue.component('XImg', XImg)
Vue.component('XLink', XLink)
Vue.component('XLoader', XLoader)
Vue.component('XPlaceholder', XPlaceholder)
Vue.component('XPaginator', XPaginator)
Vue.component('XTabs', XTabs)
Vue.component('XPlacehandler', XPlacehandler)
Vue.component('XErrorPlaceholder', XErrorPlaceholder)
Vue.component('XNoDataPlaceholder', XNoDataPlaceholder)
Vue.component('XBadge', XBadge)
Vue.component('XRadio', XRadio)
Vue.component('XRating', XRating)
Vue.component('XDropdown', XDropdown)
Vue.component('XTooltip', XTooltip)
Vue.component('XAlert', XAlert)
Vue.component('XClamp', XClamp)
Vue.component('XChip', XChip)
Vue.component('XDotlineSpacer', XDotlineSpacer)
Vue.component('XIconTooltip', XIconTooltip)
Vue.component('XCollapse', XCollapse)
Vue.component('XAdviceCard', XAdviceCard)
Vue.component('XAdviceCardBtn', XAdviceCardBtn)
Vue.component('XDataList', XDataList)
Vue.component('XDataGrid', XDataGrid)
Vue.component('XSwitch', XSwitch)

Vue.component('XDatePicker', XDatePicker)
Vue.component('XDownloadBtn', XDownloadBtn)
Vue.component('XUploadBtn', XUploadBtn)
Vue.component('XCircleLabel', XCircleLabel)

Vue.component('PeriodDataGrid', PeriodDataGrid)
