


















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { IndicatorChartChecked, IndicatorEntity, IndicatorFieldType } from '@/openapi'
import { indicatorNameMapper } from '../indicatorNameMapper'
import { indicatorFormatter } from '../indicatorFormatter'
import DataChart from '../../../data-chart/DataChart.vue'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { uniqBy } from 'lodash-es'
import { YAxisOptions } from 'highcharts'
import { mainSeriesColors } from '@xway-team/ui/src/utils/colors'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'

const colors = Object.keys(mainSeriesColors)

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    preselectedSeries: Array as PropType<IndicatorChartChecked['chartChecked']>,
    items: {
      type: Array as PropType<IndicatorEntity[]>,
      default: () => []
    },
    loading: Boolean
  },
  setup (props) {
    const axisIdMapper = (item: IndicatorEntity): string => {
      switch (item.field) {
        case IndicatorFieldType.ORDERED:
        case IndicatorFieldType.CANCELED:
        case IndicatorFieldType.RETURNED:
          return 'group'
        default: return `${item.field}--axis` || ''
      }
    }

    const axisVisibilityMapper = (item: IndicatorEntity) => {
      switch (item.field) {
        case IndicatorFieldType.PRICE:
        case IndicatorFieldType.GMV:
        case IndicatorFieldType.RATING:
        case IndicatorFieldType.REVIEWS:
          return false
        default:
          return true
      }
    }

    const axisClassNameMapper = (item: IndicatorEntity, index: number) => {
      switch (item.field) {
        case IndicatorFieldType.ORDERED:
        case IndicatorFieldType.CANCELED:
        case IndicatorFieldType.RETURNED:
          return ''
        default:
          return `highcharts-yaxis-labels--${colors[index % colors.length]}`
      }
    }

    const yAxis = computed<YAxisOptions[]>(() => uniqBy(props.items.map((el, i) => ({
      id: axisIdMapper(el),
      visible: axisVisibilityMapper(el),
      allowDecimals: el.field === IndicatorFieldType.CONVERSION_TO_BIN,
      opposite: !!(i % 2),
      className: axisClassNameMapper(el, i)
    })), 'id'))

    const series = computed(() => {
      return props.items.map(el => ({
        ...el,
        yAxis: axisIdMapper(el),
        preselect: props.preselectedSeries?.includes(el.field!),
        data: el.history.map(el => el.value ?? null)
      }))
    })

    const seriesKey = (item: IndicatorEntity) => {
      return item.field
    }

    const tooltipItemFormatter: TooltipItemFormatter<IndicatorEntity> =
      item => indicatorNameMapper(item)

    const tooltipItemValueFormatter: TooltipItemFormatter<IndicatorEntity> =
      (item, ctx) => indicatorFormatter(item, ctx.y)

    return {
      series,
      seriesKey,
      yAxis,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      indicatorNameMapper
    }
  }
})
