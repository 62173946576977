










import { computed, defineComponent, PropType } from '@vue/composition-api'
import { HistoryEntity, StockHistoryEntity } from '@/openapi'
import { XDataColumn, XDataCustomDateColumn } from '@xway-team/ui'
import { formatCountWithSign, formatCount } from '@/utils/formatters'
import { useI18n } from '@/utils/useI18n'

type StockHistoryEntityExtended = StockHistoryEntity & {
  [key: string]: HistoryEntity['value']
}

export default defineComponent({
  props: {
    items: Array as PropType<StockHistoryEntity[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('Warehouse'),
        prop: 'warehouse',
        formatter: item => item.warehouse?.name,
        minWidth: 164,
        fixedLeft: true
      }
      // {
      //   name: i18n.t('Speed'),
      //   prop: 'speed',
      //   formatter: item => item.speed == null ? '—' : i18n.t('pcs/day', [formatCount(item.speed)]),
      //   minWidth: 100,
      //   align: 'end',
      //   fixedLeft: true
      // },
      // {
      //   name: i18n.t('Lost profit'),
      //   prop: 'lostProfit',
      //   formatter: item => formatCurrency(item.lost_profit),
      //   cellClasses: item => ({ 'error-lightest-1': Number(item.lost_profit) > 0 }),
      //   minWidth: 164,
      //   align: 'end',
      //   fixedLeft: true
      // }
    ] as XDataColumn<StockHistoryEntityExtended>[])

    const mappedItems = computed(() => {
      return (props.items as StockHistoryEntityExtended[])?.map(el => {
        el.history.forEach(pos => {
          el[pos.date!] = pos.value
        })

        return el
      })
    })

    const customDateColumn: XDataCustomDateColumn<StockHistoryEntityExtended> = col => {
      return {
        ...col,
        formatter (item) {
          const val = item[col.prop!]
          return formatCount(val)
        },
        cellClasses (item) {
          const val = item[col.prop!]
          return { 'error-lightest-1': val != null && val <= 0 }
        }
      }
    }

    return {
      columns,
      mappedItems,
      customDateColumn,
      formatCountWithSign
    }
  }
})
