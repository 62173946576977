































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { priceIndicatorFormatter } from '../priceIndicatorFormatter'
import { XDataColumn } from '@xway-team/ui'
import { ConcurrentPriceHistory, ConcurrentPriceHistoryEntity, HistoryEntity } from '@/openapi'
import { primaryItemMatcher } from './pricesPrimaryItemMatcher'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  props: {
    indicator: Object as PropType<ConcurrentPriceHistoryEntity>,
    items: Array as PropType<ConcurrentPriceHistory[]>,
    selectedItems: Array as PropType<ConcurrentPriceHistory[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('shop'),
        prop: 'shop',
        minWidth: 220,
        fixedLeft: true,
        cellClasses: item => item.isMy && 'font-weight-semibold'
      },
      {
        name: i18n.t('Product'),
        prop: 'product',
        minWidth: 240,
        fixedLeft: true
      }
    ] as XDataColumn<ConcurrentPriceHistory>[])

    const itemKey = (item: ConcurrentPriceHistory) => {
      return item.product.article
    }

    const valueFormatter =
      (val: HistoryEntity['value']) => priceIndicatorFormatter(props.indicator!, val)

    const shopName = (item: ConcurrentPriceHistory) =>
      item.isMy ? i18n.t('Your product') : item.shop.name

    const yourProductRowClasses = (item: ConcurrentPriceHistory) =>
      item.isMy && 'gray-lightest-1'

    return {
      columns,
      itemKey,
      valueFormatter,
      shopName,
      yourProductRowClasses,
      primaryItemMatcher
    }
  }
})
