import { IndicatorEntity, IndicatorFieldType } from '@/openapi'
import i18n from '@/plugins/vue-i18n'

export function indicatorTooltipMapper (item: IndicatorEntity) {
  switch (item.field) {
    case IndicatorFieldType.RATING:
    case IndicatorFieldType.REVIEWS:
      return i18n.t('Data from external sources')
    default:
      return i18n.t('Data from the personal account of the marketplace')
  }
}
