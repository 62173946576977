





































































































































































import { useStore } from '@/store/store'
import { /* marketplaceApi, */ productsApi } from '@/api'
import { discardable } from '@/utils/discardable'
import { PagingByPage, ProductEntity, SorterDirection, ProductSorterField, CategoryEntity, ProductFilter, ProductSorterEntity } from '@/openapi'
import { XDataSort } from '@xway-team/ui'
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash-es'
import dayjs from 'dayjs'
import NoShopAddedPlaceholder from '@/components/placeholders/NoShopAddedPlaceholder.vue'
import ShopsAvailabilityPlaceholder from '@/components/placeholders/ShopsAvailabilityPlaceholder.vue'
import CurrentShopSelect from '@/components/CurrentShopSelect.vue'
import ProductsAdvices from './ProductsAdvices.vue'
import ProductsList from './ProductsList.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import { formatCount } from '@/utils/formatters'
import { RouteName } from '@/router/route-name'
import { useI18n } from '@/utils/useI18n'
import ShopLimitAlert from '@/components/NotPermit/ShopLimitAlert.vue'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: {
    NotPermitByTariff,
    ShopLimitAlert,
    NoShopAddedPlaceholder,
    ShopsAvailabilityPlaceholder,
    CurrentShopSelect,
    ProductsAdvices,
    ProductsList,
    CurrentPeriodPicker
  },
  setup () {
    const i18n = useI18n()
    const store = useStore()

    const showFilters = ref(false)
    const loading = ref(true)
    const error = ref(false)

    const categories = ref<CategoryEntity[]>()
    const selectedCategories = ref<CategoryEntity[]>([])
    const search = ref('')
    const inSale = ref(true)

    type Filters = Pick<ProductFilter, 'price' | 'ordersMoney' | 'ordersCount' | 'redeemedMoney' | 'redeemedCount' | 'stocks'>
    const filters = reactive<Filters>({
      price: { valueFrom: '', valueTo: '' },
      ordersMoney: { valueFrom: '', valueTo: '' },
      ordersCount: { valueFrom: '', valueTo: '' },
      redeemedMoney: { valueFrom: '', valueTo: '' },
      redeemedCount: { valueFrom: '', valueTo: '' },
      stocks: { valueFrom: '', valueTo: '' }
    })

    const products = ref<ProductEntity[]>()
    const paging = ref<PagingByPage>({})
    const sort = ref<XDataSort>({
      prop: ProductSorterField.ORDERS_MONEY,
      direction: SorterDirection.DESC
    })

    const showClearBtn = computed(() =>
      selectedCategories.value.length ||
      search.value !== '' ||
      !inSale.value ||
      Object.values(filters).some(el => el!.valueFrom !== '' || el!.valueTo !== '') ||
      dayjs(store.currentPeriod.start).startOf('date').valueOf() !== dayjs().subtract(7, 'day').startOf('date').valueOf() ||
      dayjs(store.currentPeriod.end).startOf('date').valueOf() !== dayjs().subtract(1, 'day').startOf('date').valueOf()
    )

    const getCategories = async () => {
      // const res = await marketplaceApi.getMpCategoriesV1(store.currentShop?.marketplace?.id || -1)
      // categories.value = res.data.data
    }

    const getProducts = discardable(() => productsApi.getProductsV1({
      filters: {
        label: search.value,
        inSale: inSale.value,
        dateFrom: dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
        dateTo: dayjs(store.currentPeriod.end).format('YYYY-MM-DD'),
        ...filters
      },
      pagination: paging.value,
      sorters: [{
        ...sort.value,
        field: sort.value.prop
      } as ProductSorterEntity]
    }))
      .then(res => {
        if (res.data.pagination) {
          paging.value = res.data.pagination
        }
        products.value = res.data.data
      })
      .catch((err: any) => {
        error.value = err
      })
      .finally(() => {
        loading.value = false
      })

    const debouncedGetProducts = debounce(getProducts, 500)

    const cancelGetProducts = () => {
      getProducts.discard()
      debouncedGetProducts.cancel()
    }

    const getProductsByFilters = () => {
      loading.value = true
      error.value = false

      cancelGetProducts()
      debouncedGetProducts()
    }

    const getProductsImmediatelly = () => {
      loading.value = true
      error.value = false

      cancelGetProducts()
      getProducts()
    }

    const clear = () => {
      cancelGetProducts()

      loading.value = false

      search.value = ''
      selectedCategories.value = []
      inSale.value = true

      Object.values(filters).forEach(val => {
        val!.valueFrom = val!.valueTo = ''
      })

      store.resetCurrentPeriod()
    }

    const toggleFilters = () => {
      showFilters.value = !showFilters.value
    }

    const filterLabelMapper = (key: keyof typeof filters) => {
      switch (key) {
        case 'price': return i18n.t('price, rub')
        case 'ordersMoney': return i18n.t('Ordered, rub')
        case 'ordersCount': return i18n.t('Ordered, pcs')
        case 'redeemedMoney': return i18n.t('Redeemed, rub')
        case 'redeemedCount': return i18n.t('Redeemed, pcs')
        case 'stocks': return i18n.t('Stock')
      }
    }

    if (
      store.hasShops &&
      store.isCurrentShopAvailable
    ) {
      getProducts()
      getCategories()
    }

    watch(() => store.currentShop, () => {
      getProductsImmediatelly()
      getCategories()
    })

    watch(() => [
      store.currentPeriod,
      selectedCategories.value
    ], getProductsImmediatelly)

    const toMarketingReports = {
      name: RouteName.REPORTS
    }

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_INDICATORS)
    })

    return {
      store,
      showFilters,
      search,
      inSale,
      loading,
      error,
      categories,
      selectedCategories,
      filters,
      products,
      paging,
      sort,
      showClearBtn,
      getProductsByFilters,
      getProductsImmediatelly,
      clear,
      toggleFilters,
      filterLabelMapper,
      formatCount,
      toMarketingReports,
      isPermitByTariff
    }
  }
})
