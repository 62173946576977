import { RouteConfig } from 'vue-router'
import { RouteName } from '@/router/route-name'
import Products from '@/views/products/Products.vue'
import ProductReview from '@/views/product-review/ProductReview.vue'
import Content from '@/views/product-review/tabs/content/Content.vue'
import ContentAnalysis from '@/views/product-review/tabs/content/content-analysis/ContentAnalysis.vue'
import Indicators from '@/views/product-review/tabs/indicators/Indicators.vue'
import IndicatorsHistory from '@/views/product-review/tabs/indicators/indicators-history/IndicatorsHistory.vue'
import IndicatorsComparison from '@/views/product-review/tabs/indicators/indicators-comparison/IndicatorsComparison.vue'
import Prices from '@/views/product-review/tabs/prices/Prices.vue'
import PricesHistory from '@/views/product-review/tabs/prices/prices-history/PricesHistory.vue'
import PricesComparison from '@/views/product-review/tabs/prices/prices-comparison/PricesComparison.vue'
import Stocks from '@/views/product-review/tabs/stocks/Stocks.vue'
import StocksHistory from '@/views/product-review/tabs/stocks/stocks-history/StocksHistory.vue'
import StocksComparison from '@/views/product-review/tabs/stocks/stocks-comparison/StocksComparison.vue'
import Positions from '@/views/product-review/tabs/positions/Positions.vue'
import PositionsHistory from '@/views/product-review/tabs/positions/positions-history/PositionsHistory.vue'
import PositionsComparison from '@/views/product-review/tabs/positions/positions-comparison/PositionsComparison.vue'
import Competitors from '@/views/product-review/tabs/competitors/Competitors.vue'
import Reports from '@/views/reports/Reports.vue'
import Callback from '@/views/callback/Callback.vue'

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: { name: RouteName.PRODUCTS }
  },
  {
    path: '/products',
    name: RouteName.PRODUCTS,
    component: Products,
    meta: {
      metrics: {
        screen: 'analytics_products',
        name: 'analytics_products_view'
      }
    }
  },
  {
    path: '/products/:productId',
    redirect: { name: RouteName.PRODUCT_REVIEW__POSITIONS },
    name: RouteName.PRODUCT_REVIEW,
    component: ProductReview,
    props: route => ({
      productId: Number(route.params.productId)
    }),
    meta: {
      metrics: {
        screen: 'analytics_products_product'
      }
    },
    children: [{
      path: 'content',
      name: RouteName.PRODUCT_REVIEW__CONTENT,
      component: Content,
      redirect: { name: RouteName.PRODUCT_REVIEW__CONTENT__ANALYSIS },
      children: [{
        path: 'analysis',
        name: RouteName.PRODUCT_REVIEW__CONTENT__ANALYSIS,
        component: ContentAnalysis,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_content'
          }
        }
      }/* , {
        path: 'compare',
        name: RouteName.PRODUCT_REVIEW__CONTENT__COMPARISON,
        component: ContentComparison
      } */]
    }, {
      path: 'indicators',
      name: RouteName.PRODUCT_REVIEW__INDICATORS,
      component: Indicators,
      redirect: { name: RouteName.PRODUCT_REVIEW__INDICATORS__HISTORY },
      children: [{
        path: 'analysis',
        name: RouteName.PRODUCT_REVIEW__INDICATORS__HISTORY,
        component: IndicatorsHistory,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_indicators_analysis'
          }
        }
      }, {
        path: 'compare',
        name: RouteName.PRODUCT_REVIEW__INDICATORS__COMPARISON,
        component: IndicatorsComparison,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_indicators_compare'
          }
        }
      }]
    }, {
      path: 'prices',
      name: RouteName.PRODUCT_REVIEW__PRICES,
      component: Prices,
      redirect: { name: RouteName.PRODUCT_REVIEW__PRICES__HISTORY },
      children: [{
        path: 'analysis',
        name: RouteName.PRODUCT_REVIEW__PRICES__HISTORY,
        component: PricesHistory,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_prices_analysis'
          }
        }
      }, {
        path: 'compare',
        name: RouteName.PRODUCT_REVIEW__PRICES__COMPARISON,
        component: PricesComparison,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_prices_compare'
          }
        }
      }]
    }, {
      path: 'stock',
      name: RouteName.PRODUCT_REVIEW__STOCKS,
      component: Stocks,
      redirect: { name: RouteName.PRODUCT_REVIEW__STOCKS__HISTORY },
      children: [{
        path: 'analysis',
        name: RouteName.PRODUCT_REVIEW__STOCKS__HISTORY,
        component: StocksHistory,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_stock_analysis'
          }
        }
      }, {
        path: 'compare',
        name: RouteName.PRODUCT_REVIEW__STOCKS__COMPARISON,
        component: StocksComparison,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_stock_compare'
          }
        }
      }]
    }, {
      path: 'positions',
      name: RouteName.PRODUCT_REVIEW__POSITIONS,
      component: Positions,
      redirect: { name: RouteName.PRODUCT_REVIEW__POSITIONS__HISTORY },
      children: [{
        path: 'analysis',
        name: RouteName.PRODUCT_REVIEW__POSITIONS__HISTORY,
        component: PositionsHistory,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_positions_analysis'
          }
        }
      }, {
        path: 'compare',
        name: RouteName.PRODUCT_REVIEW__POSITIONS__COMPARISON,
        component: PositionsComparison,
        meta: {
          metrics: {
            screen: 'analytics_products_product',
            name: 'analytics_products_product_positions_compare'
          }
        }
      }]
    }, {
      path: 'competitors',
      name: RouteName.PRODUCT_REVIEW__COMPETITORS,
      component: Competitors,
      meta: {
        metrics: {
          screen: 'analytics_products_product',
          name: 'analytics_products_product_competitors'
        }
      }
    }]
  },
  {
    path: '/reports',
    name: RouteName.REPORTS,
    component: Reports,
    meta: {
      metrics: {
        screen: 'analytics_reports',
        name: 'analytics_reports_view'
      }
    }
  },
  {
    path: '/callback',
    component: Callback
  },
  {
    path: '*',
    redirect: { name: RouteName.PRODUCTS }
  }
]
