





































import { defineComponent, PropType } from '@vue/composition-api'
import {
  ChartRef,
  SeriesKey,
  SeriesOptionsTypeExtended,
  SeriesVisibilities
} from '@/views/product-review/data-chart/dataChartTypes'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
  inheritAttrs: false,
  model: {
    prop: 'seriesVisibilities',
    event: 'change'
  },
  props: {
    isSomeSeriesVisible: Boolean,
    isEverySeriesVisible: Boolean,
    disabled: Boolean,
    series: {
      type: Array as PropType<SeriesOptionsTypeExtended[]>,
      required: true
    },
    seriesVisibilities: {
      type: Map as PropType<SeriesVisibilities>,
      required: true
    },
    chartRef: Object as PropType<ChartRef>,
    allText: String as PropType<TranslateResult>
  },
  setup (props, ctx) {
    const toggleEverySeries = () => {
      const res = new Map() as SeriesVisibilities
      const visibility = props.isSomeSeriesVisible

      for (const key of props.seriesVisibilities.keys()) {
        res.set(key, !visibility)
      }

      ctx.emit('change', res)
    }

    const toggleSeries = (seriesKey: SeriesKey) => {
      const visibility = !props.seriesVisibilities.get(seriesKey)

      props.seriesVisibilities.set(seriesKey, visibility)
      ctx.emit('change', new Map(props.seriesVisibilities))

      if (visibility) {
        hoverSeries(seriesKey)
      } else {
        unhoverSeries()
      }
    }

    const hoverSeries = (seriesKey: SeriesKey) => {
      if (props.seriesVisibilities.get(seriesKey)) {
        props.chartRef!.chart.series.forEach(el => {
          el.setState(seriesKey === el.options.custom!.id ? 'hover' : 'inactive')
        })
      }
    }

    const unhoverSeries = () => {
      props.chartRef!.chart.series.forEach(el => {
        el.setState('normal')
      })
    }

    const bindCheckboxProps = (series: SeriesOptionsTypeExtended) => {
      const seriesKey = series.custom.id
      const { colorName } = series.custom

      return {
        modelValue: props.seriesVisibilities.get(seriesKey),
        disabled: props.disabled,
        color: colorName === 'primary' ? null : colorName
      }
    }

    return {
      toggleEverySeries,
      toggleSeries,
      hoverSeries,
      unhoverSeries,
      bindCheckboxProps
    }
  }
})
