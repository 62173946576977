


















import { computed, defineComponent, ref } from '@vue/composition-api'
import { contentApi } from '@/api'
import { ProductContentWrapper } from '@/openapi'
import ContentSection from './content-section/ContentSection.vue'
import { pickBy } from 'lodash-es'
import { useBindCompetitors } from '../../../useBindCompetitors'
import { useProductId } from '../../../useProductId'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import { useStore } from '@/store/store'

export default defineComponent({
  components: {
    NotPermitByTariff,
    ContentSection
  },
  setup () {
    const productId = useProductId()
    const store = useStore()

    const loading = ref(true)
    const error = ref(false)

    const content = ref<ProductContentWrapper>()

    const fetchContent = async () => {
      loading.value = true
      try {
        const res = (await contentApi.productContentV1(productId.value)).data.data
        content.value = pickBy(res, Boolean)
      } catch (err: any) {
        error.value = err
        throw err
      } finally {
        loading.value = false
      }
    }

    fetchContent()
    useBindCompetitors(fetchContent)

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_CONTENT)
    })

    return {
      loading,
      error,
      content,
      isPermitByTariff
    }
  }
})
