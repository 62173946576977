




























































import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { ConcurrentPositionEntity } from '@/openapi'
import { ConcurrentPositionEntitySpanRow } from './positionsComparisonTypes'
import { XDataColumn, XDataCustomFilter } from '@xway-team/ui'
import { primaryItemMatcher } from './positionsPrimaryItemMatcher'
import { searchTypeMapper } from '../../../searchTypeMapper'
import { formatShopName } from './formatShopName'
import { useI18n } from '@/utils/useI18n'
import { formatCount, formatCountWithSign } from '@/utils/formatters'
import { sortPositions } from '../sortPositions'

export default defineComponent({
  props: {
    items: Array as PropType<ConcurrentPositionEntitySpanRow[]>,
    selectedItems: Array as PropType<ConcurrentPositionEntitySpanRow[]>,
    request: Object as PropType<ConcurrentPositionEntity>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const search = ref('')

    const columns = computed(() => [
      {
        name: i18n.t('shop'),
        prop: 'shop',
        minWidth: 220,
        fixedLeft: true,
        cellClasses: item => item.isMy && 'font-weight-semibold'
      },
      {
        name: i18n.t('Product'),
        prop: 'product',
        minWidth: 260,
        fixedLeft: true
      }
    ] as XDataColumn<ConcurrentPositionEntitySpanRow>[])

    const customDateColumn = {
      sortable: true
    }

    const progressClasses = (val: number) => val < 0 ? 'success--text' : 'error--text'

    const yourProductRowClasses = (item: ConcurrentPositionEntitySpanRow) =>
      item.isMy && (props.request?.withoutMy ? 'warning-lightest-3' : 'gray-lightest-1')

    const customFilter: XDataCustomFilter<ConcurrentPositionEntitySpanRow> =
      (search, item) =>
        item.product.name?.toLowerCase().includes(search.toLowerCase()) ||
        item.product.article?.includes(search)

    const itemKey = (item: ConcurrentPositionEntitySpanRow) => item.product.article

    return {
      search,
      columns,
      customDateColumn,
      progressClasses,
      formatShopName,
      yourProductRowClasses,
      formatCount,
      formatCountWithSign,
      primaryItemMatcher,
      searchTypeMapper,
      customFilter,
      itemKey,
      sortPositions
    }
  }
})
