import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from '@/plugins/vue-i18n'
import { createPinia } from 'pinia'
import { XMetricsPlugin, Options } from '@xway-team/metrics'
import {
  applyTheme,
  clearTheme,
  detectThemeByQuery,
  detectThemeByCookie,
  getAvailableThemeNames,
  getCompanyDetails,
  getDefaultCompanyDetails
  // @ts-ignore
} from '@xway-team/theme-detector'
import { setCompanyDetails } from '@/plugins/companyDetails'
import '@/plugins/vue-toastification'

const themeName = detectThemeByQuery() || detectThemeByCookie()

if (getAvailableThemeNames().includes(themeName)) {
  applyTheme(themeName)
  setCompanyDetails(getCompanyDetails(themeName))
} else {
  clearTheme()
  setCompanyDetails(getDefaultCompanyDetails())
}

Vue.config.productionTip = false

const requirePlugin = require.context(
  '@/plugins',
  false,
  /\w+\.(ts|js)$/
)
requirePlugin.keys().forEach(pluginName => {
  const plugin = requirePlugin(pluginName)
  if (plugin.setup) {
    plugin.setup(Vue)
  }
})

Vue.use(XMetricsPlugin, {
  product: 'analytics',
  router,
  useProd: process.env.VUE_APP_DEPLOYMENT_ENV === 'production'
} as Options)

new Vue({
  i18n,
  router,
  pinia: createPinia(),
  render: h => h(App)
}).$mount('#app')
