import { ConcurrentPriceHistoryEntity, PriceHistoryEntity, PriceHistoryFieldType } from '@/openapi'
import i18n from '@/plugins/vue-i18n'

export function priceIndicatorNameMapper (item: PriceHistoryEntity | ConcurrentPriceHistoryEntity) {
  switch (item.field) {
    case PriceHistoryFieldType.PRICE: return i18n.t('Price before discount, rub')
    case PriceHistoryFieldType.DISCOUNT_PRICE: return i18n.t('Current price, rub')
    case PriceHistoryFieldType.PROMO: return i18n.t('Promo code')
    case PriceHistoryFieldType.SALES: return i18n.t('Sales')
  }
}
