








import { defineComponent, PropType, computed } from '@vue/composition-api'
import { ProductEntity } from '@/openapi'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  inheritAttrs: false,
  props: {
    product: {
      type: Object as PropType<ProductEntity>,
      required: true
    }
  },
  setup (props) {
    const i18n = useI18n()

    const statusColor = computed(() =>
      props.product.isDeleted ? 'deep-orange-lighten-5' : 'success-lightest-2'
    )

    const statusText = computed(() =>
      props.product.isDeleted ? i18n.t('Not for sale') : i18n.t('For sale')
    )

    return {
      statusColor,
      statusText
    }
  }
})
