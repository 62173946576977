































































































import { ProductEntity } from '@/openapi'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { shopIconMapper } from '@/utils/shopIconMapper'
import { formatCurrency } from '@/utils/formatters'
import ProductStatusBadge from '@/components/ProductStatusBadge.vue'
import dayjs from 'dayjs'

export default defineComponent({
  components: {
    ProductStatusBadge
  },
  props: {
    product: Object as PropType<ProductEntity>,
    loading: Boolean,
    error: [Boolean, Error, Object]
  },
  setup (props) {
    const updatedAt = computed(() => {
      return props.product?.updatedAt && dayjs(props.product?.updatedAt).format('DD.MM.YYYY')
    })

    return {
      updatedAt,
      shopIconMapper,
      formatCurrency
    }
  }
})
