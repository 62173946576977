
















import { SELLER_PAGE_TARIFF } from '@/constants/urls'

import createCountFormatter from '@/utils/count-formatter'
import { defineComponent, computed } from '@vue/composition-api/dist/vue-composition-api'
import { useStore } from '@/store/store'

export default defineComponent({
  name: 'ShopLimitAlert',

  setup () {
    const store = useStore()

    const isShown = computed(() => {
      return typeof store.user.active_subscription?.tariff?.shops_limit === 'number'
    })

    const currentTariffName = computed(() => {
      return store.user.active_subscription?.tariff?.name
    })

    const shopAmountPlural = computed(() => {
      const getPlural = createCountFormatter({ few: 'магазинов', one: 'магазина', two: 'магазинов' })

      return `${store.user.active_subscription?.tariff?.shops_limit}
       ${getPlural(store.user.active_subscription?.tariff?.shops_limit || 0)}`
    })

    return {
      TARIFF_PATH: SELLER_PAGE_TARIFF,
      isShown,
      currentTariffName,
      shopAmountPlural
    }
  }
})
