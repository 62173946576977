<template>
  <x-btn
    v-bind="$attrs"
    :color="color"
    prepend="upload"
    :loading="isLoading"
    @click="onClick"
  >
    <slot>
      {{ $t('Add file') }}
    </slot>
    <input
      ref="input"
      type="file"
      style="display: none"
      :accept="accept"
      @change="handleFiles"
    >
  </x-btn>
</template>

<script>
export default {
  inheritAttrs: false,
  data: () => ({
    isLoading: false
  }),
  props: {
    color: {
      type: String,
      default: 'gray'
    },
    accept: String,
    action: Function
  },
  methods: {
    async onClick () {
      this.$refs.input.click()
    },
    async handleFiles () {
      const file = this.$refs.input.files[0]
      this.isLoading = true
      await this.action(file)
      this.isLoading = false
    }
  }
}
</script>
