import { formatCount, formatCurrency, formatPercent } from '@/utils/formatters'
import { ConcurrentPriceHistoryEntity, PriceHistoryEntity, PriceHistoryFieldType } from '@/openapi'

export function priceIndicatorFormatter (
  item: PriceHistoryEntity | ConcurrentPriceHistoryEntity,
  val?: number | string | null
) {
  switch (item.field) {
    case PriceHistoryFieldType.DISCOUNT_PRICE:
    case PriceHistoryFieldType.PRICE:
      return formatCurrency(val)
    case PriceHistoryFieldType.PROMO:
      return formatPercent(val)
    case PriceHistoryFieldType.SALES:
      return formatCount(val)
    default:
      return formatCount(val)
  }
}
