import ru from './ru.yaml'
import en from './en.yaml'

import uiRu from '@xway-team/ui/src/locales/ru.yaml'
import uiEn from '@xway-team/ui/src/locales/en.yaml'

export const messages = {
  ru: {
    ...ru,
    ...uiRu
  },
  en: {
    ...en,
    ...uiEn
  }
}
