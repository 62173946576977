































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { indicatorFormatter } from '../indicatorFormatter'
import { XDataColumn } from '@xway-team/ui'
import { ConcurrentIndicatorEntity, ConcurrentIndicatorsHistory, HistoryEntityFloat } from '@/openapi'
import { primaryItemMatcher } from './indicatorsPrimaryItemMatcher'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  props: {
    indicator: Object as PropType<ConcurrentIndicatorEntity>,
    items: Array as PropType<ConcurrentIndicatorsHistory[]>,
    selectedItems: Array as PropType<ConcurrentIndicatorsHistory[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('shop'),
        prop: 'shop',
        minWidth: 220,
        fixedLeft: true,
        cellClasses: item => item.isMy && 'font-weight-semibold'
      },
      {
        name: i18n.t('Product'),
        prop: 'product',
        minWidth: 240,
        fixedLeft: true
      }
    ] as XDataColumn<ConcurrentIndicatorsHistory>[])

    const itemKey = (item: ConcurrentIndicatorsHistory) => item.product.article

    const valueFormatter =
      (val: HistoryEntityFloat['value']) => indicatorFormatter(props.indicator!, val)

    const shopName = (item: ConcurrentIndicatorsHistory) =>
      item.isMy ? i18n.t('Your product') : item.shop.name

    const yourProductRowClasses = (item: ConcurrentIndicatorsHistory) =>
      item.isMy && 'gray-lightest-1'

    return {
      columns,
      itemKey,
      valueFormatter,
      shopName,
      yourProductRowClasses,
      primaryItemMatcher
    }
  }
})
