

























































































































































import { productsApi, reportsApi, sellerApi } from '@/api'
import { defineComponent, ref, watch } from '@vue/composition-api'
import { useStore } from '@/store/store'
import CurrentShopSelect from '@/components/CurrentShopSelect.vue'
import NoShopAddedPlaceholder from '@/components/placeholders/NoShopAddedPlaceholder.vue'
import ShopsAvailabilityPlaceholder from '@/components/placeholders/ShopsAvailabilityPlaceholder.vue'
import dayjs from 'dayjs'
import ReportsSection from './ReportsSection.vue'
import { useMetrics } from '@/utils/useMetrics'
import { RouteName } from '@/router/route-name'
import { downloadFileFromResponse } from '@/utils/downloadFileFromResponse'
import { ProductConcurrentCountEntity } from '@/openapi'
import { formatCount } from '@/utils/formatters'
import { XRecommendationsBanner } from '@xway-team/ui'
import ShopLimitAlert from '@/components/NotPermit/ShopLimitAlert.vue'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import toastNotification from '@/utils/toast-notification'

export default defineComponent({
  components: {
    ShopLimitAlert,
    CurrentShopSelect,
    NoShopAddedPlaceholder,
    ShopsAvailabilityPlaceholder,
    ReportsSection,
    XRecommendationsBanner
  },
  setup () {
    const metrics = useMetrics()
    const store = useStore()

    metrics.useScreenInterceptor('analytics_reports', () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      return { period: `from_${from}_to_${to}` }
    })

    const bindDownload = (
      request: (...args: any[]) => Promise<any>,
      options = { period: true }
    ) => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
      const requestOptions = { responseType: 'blob' }

      return () => options.period
        ? request(from, to, requestOptions)
        : request(requestOptions)
    }

    const toProducts = { name: RouteName.PRODUCTS }
    const toTurnoverHref = `${process.env.VUE_APP_SELLER_CABINET_URL}/product/list`

    const uploadCompetitors = async (file: File) => {
      try {
        const res = await reportsApi.uploadConcurrentV1(file, { responseType: 'blob' })
        downloadFileFromResponse(res)
        toastNotification.success('файл загружен')
      } catch (e) {
        console.error(e)
        toastNotification.error('ошибка')
      }
    }

    const competitorsData = ref<ProductConcurrentCountEntity>()
    const competitorsDataLoading = ref(true)

    const fetchCompetitorsData = () => {
      competitorsDataLoading.value = true
      productsApi.productConcurrentCountV1()
        .then(res => {
          competitorsData.value = res.data.data
        })
        .finally(() => {
          competitorsDataLoading.value = false
        })
    }

    const orderRecommendations = () => {
      return sellerApi.orderRecommendations('Отчёты')
    }

    watch(() => store.currentShop, fetchCompetitorsData)

    fetchCompetitorsData()

    return {
      store,
      bindDownload,
      toProducts,
      toTurnoverHref,
      uploadCompetitors,
      orderRecommendations,
      competitorsData,
      competitorsDataLoading,
      reportsApi,
      formatCount,
      PERMISSION_KEY
    }
  }
})
