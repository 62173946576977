import i18n from '@/plugins/vue-i18n'

// non-strict comparison assumes the value is equal to 'null' or 'undefined'

export function formatCount (val?: number | string | null) {
  return val == null ? '—' : i18n.n(+val)
}

export function formatCurrency (val?: number | string | null) {
  return val == null ? '—' : i18n.n(+val, 'currency')
}

export function formatCurrencyWithSymbol (
  val?: number | string | null,
  currency = 'RUB'
) {
  return val == null
    ? '—'
    : i18n.n(+val, {
      style: 'currency',
      currency,
      currencyDisplay: 'symbol'
    })
}

export function formatPercent (val?: number | string | null) {
  return val == null ? '—' : `${i18n.n(+val)}%`
}

export function formatEmpty (val?: number | string | null) {
  return val ?? '—'
}

export function formatCountWithSign (val: number) {
  return val > 0 ? `+${formatCount(val)}` : `−${formatCount(Math.abs(val))}`
}
