




















import { computed, defineComponent, PropType } from '@vue/composition-api'
import DataChart from '../../../data-chart/DataChart.vue'
import { StockHistoryEntity } from '@/openapi'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { formatCount } from '@/utils/formatters'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: {
      type: Array as PropType<StockHistoryEntity[]>,
      default: () => []
    },
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const series = computed(() => {
      return props.items.map(el => ({
        ...el,
        data: el.history.map(el => el.value ?? null)
      }))
    })

    const seriesKey = (item: StockHistoryEntity) => {
      return item.warehouse?.id
    }

    const tooltipItemFormatter: TooltipItemFormatter<StockHistoryEntity> =
      item => item.warehouse?.name

    const tooltipItemValueFormatter: TooltipItemFormatter<StockHistoryEntity> =
      (item, ctx) => i18n.t('pcs', [formatCount(ctx.y)])

    return {
      series,
      seriesKey,
      tooltipItemFormatter,
      tooltipItemValueFormatter
    }
  }
})
