import { TooltipFormatterContextObject } from 'highcharts'
import { TooltipItemFormatter } from '@/views/product-review/data-chart/dataChartTypes'

export function tooltipItemRenderer (
  points?: TooltipFormatterContextObject[],
  labelFormatter?: TooltipItemFormatter,
  valueFormatter?: TooltipItemFormatter,
  extra?: boolean
) {
  return points?.reduce((acc: string, el: TooltipFormatterContextObject) => {
    const boxColorClass = `data-chart__tooltip-box${extra ? '-extra' : ''}--${el.series.options.custom!.colorName}`

    const text = labelFormatter
      ? labelFormatter(el.series.options, el)
      : el.x

    const value = valueFormatter
      ? valueFormatter(el.series.options, el)
      : el.y

    return acc + `
      <div class="data-chart__tooltip-row">
        <div class="data-chart__tooltip-box ${boxColorClass}"></div>
        <div class="data-chart__tooltip-title">
          ${text}
        </div>
        :&nbsp;
        <div class="data-chart__tooltip-value">
          ${value}
        </div>
      </div>
    `
  }, '')
}
