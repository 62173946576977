import axios from 'axios'
import dayjs from 'dayjs'
import { messages } from '../locales'
import VueI18n from 'vue-i18n'
import Vue from 'vue'

import 'dayjs/locale/ru'

const LOCALE_STORAGE_LOCALE_KEY = 'lang'

Vue.use(VueI18n)

const locale = getClientLocale()

const i18n = new VueI18n({
  fallbackLocale: 'ru',
  numberFormats: {
    ru: {
      currency: {
        minimumFractionDigits: 2
      }
    }
  },
  locale,
  messages,
  pluralizationRules: {
    ru: (choice, choicesLength) => {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (!teen && endsWithOne) {
        return 1
      }

      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    }
  }
})

setLocale(locale)

function getClientLocale () {
  interface _Navigator extends Navigator {
    browserLanguage: string
    userLanguage: string
    systemLanguage: string
  }

  const _navigator = navigator as _Navigator

  const locale =
    localStorage.getItem(LOCALE_STORAGE_LOCALE_KEY) ||
    _navigator.language ||
    _navigator.languages[0] ||
    _navigator.browserLanguage ||
    _navigator.userLanguage ||
    _navigator.systemLanguage

  return locale && locale.toLowerCase().split('-')[0]
}

function setLocale (lang: string): void {
  i18n.locale = lang
  dayjs.locale(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  document.documentElement.setAttribute('lang', lang)
  document.title = 'XWAY | ' + i18n.t('internal analytics')
  localStorage.setItem(LOCALE_STORAGE_LOCALE_KEY, lang)
}

export default i18n
export { setLocale }
