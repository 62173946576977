



























































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ProductContentBlock } from '@/openapi'
import { ProductContentEntity } from '../productContentEntity'
import { formatCurrencyWithSymbol } from '@/utils/formatters'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  inheritAttrs: false,
  props: {
    data: Object as PropType<ProductContentEntity>
  },
  setup (props) {
    const i18n = useI18n()

    const title = computed(() => {
      switch (props.data?.block) {
        case ProductContentBlock.CHARACTERISTICS:
          return i18n.t('Characteristics')

        case ProductContentBlock.DESCRIPTION:
          return i18n.t('Description')

        case ProductContentBlock.IMAGE:
          return i18n.t('Photo')

        case ProductContentBlock.NAME:
          return i18n.t('Name')

        case ProductContentBlock.PRICE:
          return i18n.t('Prices')

        case ProductContentBlock.RATING_REVIEW:
          return i18n.t('Rating and reviews')

        case ProductContentBlock.SEARCH_CATEGORIES:
          return i18n.t('Categories')

        case ProductContentBlock.SEARCH_PHRASE:
          return i18n.t('Search phrases')
      }
      return null
    })

    return {
      title,
      ProductContentBlock,
      formatCurrencyWithSymbol
    }
  }
})
