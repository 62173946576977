import { XDataCustomSort } from '@xway-team/ui'
import {
  ConcurrentPositionEntitySpanRow
} from './positions-comparison/positionsComparisonTypes'
import {
  ProductPositionEntityExtended
} from './positions-history/positionsHistoryTypes'

export const sortPositions: XDataCustomSort<ProductPositionEntityExtended | ConcurrentPositionEntitySpanRow> = (
  items,
  sort
) => {
  const factor = sort.direction === 'ASC' ? -1 : 1
  return items.slice().sort((a, b) => {
    const sortProp = sort.prop
    const aVal = (a.isMy && Infinity) || (a[sortProp] != null ? (a[sortProp]!.current * factor) : -Infinity)
    const bVal = (b.isMy && Infinity) || (b[sortProp] != null ? (b[sortProp]!.current * factor) : -Infinity)
    return bVal - aVal
  })
}
