




















































import { computed, defineComponent } from '@vue/composition-api'
import NoCompetitorsPlaceholder from './placeholders/NoCompetitorsPlaceholder.vue'
import ApiKeyErrorPlaceholder from './placeholders/ApiKeyErrorPlaceholder.vue'
import { useStore } from '@/store/store'
import dayjs from 'dayjs'
import { ErrorCode } from '@/openapi'

export default defineComponent({
  components: {
    NoCompetitorsPlaceholder,
    ApiKeyErrorPlaceholder
  },
  inheritAttrs: false,
  props: {
    handleDataCollectionPeriod: {
      type: Boolean,
      default: true
    },
    handleApiKeyError: Boolean,
    loading: Boolean,
    error: [Boolean, Error, Object],
    items: Array,
    gridTitle: String,
    downloadFile: Function,
    showNoCompetitorsPlaceholder: Boolean
  },
  setup (props) {
    const store = useStore()

    const collectionDay = computed(() => {
      return dayjs(store.currentShop?.createdAt).add(1, 'day').startOf('date')
    })

    const showDataCollectionPeriodPlaceholder = computed(() => {
      const today = dayjs().startOf('date')

      return props.handleDataCollectionPeriod && today.isBefore(collectionDay.value)
    })

    return {
      collectionDay,
      showDataCollectionPeriodPlaceholder,
      ErrorCode
    }
  }
})
