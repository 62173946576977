import { Shop } from '@/DTO/Shop'
import { convertToSubscription, Subscription } from '@/DTO/Subscription'
import { Tariff } from '@/DTO/Tariff'

interface NextBill {
  amount: number,
  in_days: number
}

export interface User {
  id: number
  name?: string
  fullName?: string
  email?: string
  shops?: Shop[]
  active_subscription?: Subscription,
  future_tariff: Tariff,
  subscriptionTerm?: number,
  hasDueBill?: boolean,
  is_demo_account: boolean,
  cabinet_type: string,
  next_bill?: NextBill
}

export function convertToUser (user: Record<string, any>): User {
  return {
    id: user.id,
    name: user.name,
    fullName: user.name + ' ' + user.surname,
    email: user.email,
    active_subscription: convertToSubscription(user.active_subscription),
    future_tariff: user.future_tariff,
    subscriptionTerm: user.active_subscription.term,
    hasDueBill: user.has_due_bill,
    is_demo_account: user.is_demo_account,
    cabinet_type: user.cabinet_type,
    next_bill: user.next_bill
  }
}
