















import { computed, defineComponent, getCurrentInstance, PropType } from '@vue/composition-api'
import { SourceType } from '@/openapi'

export default defineComponent({
  inheritAttrs: false,
  props: {
    sourceType: String as PropType<SourceType>
  },
  setup (props) {
    const vm = getCurrentInstance()!

    const delay = { hide: 0, show: 1000 }

    const text = computed(() => {
      switch (props.sourceType) {
        case SourceType.EXTERNAL:
          return vm.proxy.$i18n.t('Data from external sources')
        case SourceType.INTERNAL:
          return vm.proxy.$i18n.t('Data from the personal account of the marketplace')
      }
      return null
    })

    return {
      delay,
      text
    }
  }
})
