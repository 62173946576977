

































































import { computed, defineComponent, ref } from '@vue/composition-api'
import { useStore } from '@/store/store'
import { useInnerTab, useSelectedItems } from '../../../inner-tab-template/useInnerTab'
import { useSearchTypeFilter } from '../useSearchTypeFilter'
import { indicatorApi, positionsApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import PositionsHistoryChart from './PositionsHistoryChart.vue'
import PositionsHistoryGrid from './PositionsHistoryGrid.vue'
import SearchTypeFilter from '../SearchTypeFilter.vue'

import dayjs from 'dayjs'
import { RouteName } from '@/router/route-name'
import { IndicatorEntity, IndicatorFieldType, SearchType } from '@/openapi'
import { indicatorNameMapper } from '@/views/product-review/tabs/indicators/indicatorNameMapper'
import { extraSeriesColors } from '@xway-team/ui/src/utils/colors'
import { useProductId } from '../../../useProductId'
import { useMetrics } from '@/utils/useMetrics'
import { formatShopName } from './formatShopName'
import { createNamespace } from '@xway-team/ui/src/utils/storage'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'

export default defineComponent({
  components: {
    NotPermitByTariff,
    InnerTabTemplate,
    CurrentPeriodPicker,
    PositionsHistoryChart,
    PositionsHistoryGrid,
    SearchTypeFilter
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()
    const { getItem, setItem, hasItem } = createNamespace('positions-history')

    const extraItems = ref<IndicatorEntity[]>()
    const EXTRA_ITEMS_VISIBILITIES = 'extraItemsVisibilities'

    const extraItemsVisibilities = ref(hasItem(EXTRA_ITEMS_VISIBILITIES)
      ? getItem(EXTRA_ITEMS_VISIBILITIES)
      : {
          [SearchType.CATEGORY]: [IndicatorFieldType.ORDERED],
          [SearchType.SEARCH]: [IndicatorFieldType.REDEEMED_COUNT]
        }
    )

    const chartToggle = () => {
      setItem(EXTRA_ITEMS_VISIBILITIES, extraItemsVisibilities.value)
    }

    const tab = useInnerTab({
      async fetchItems () {
        const dateFrom = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
        const dateTo = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

        const [res, res2] = await Promise.all([
          positionsApi.statisticPositionV1(
            productId.value,
            dateFrom,
            dateTo
          ).then(res => res.data.data),
          indicatorApi.getIndicatorComparisonV1(
            productId.value,
            dateFrom,
            dateTo
          ).then(res => res.data.data)
        ])

        extraItems.value = res2

        if (res) {
          res.forEach(el => {
            el.positions.forEach(pos => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              el[pos.date] = pos.position
            })
          })
        }

        return res
      }
    })

    const { searchType } = useSearchTypeFilter(() => tab.items)

    const filteredItems = computed(() => {
      return tab.items?.filter(el => el.searchType === searchType.value)
    })

    const { selectedItems } = useSelectedItems(filteredItems, {
      itemKey: formatShopName
    })

    const selectedExtraItems = computed(() => {
      if (searchType.value == null) return
      const visibilities = extraItemsVisibilities.value[searchType.value]
      return extraItems.value?.filter(el => visibilities.includes(el.field))
    })

    const downloadAnalysis = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_positions_analysis_report', {
        period: `from_${from}_to_${to}`
      })

      return positionsApi.statisticPositionDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const toComparison = { name: RouteName.PRODUCT_REVIEW__POSITIONS__COMPARISON }

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_POSITIONS)
    })

    return {
      tab,
      searchType,
      selectedItems,
      filteredItems,
      extraItems,
      extraItemsVisibilities,
      chartToggle,
      selectedExtraItems,
      downloadAnalysis,
      toComparison,
      indicatorNameMapper,
      checkboxColors: Object.keys(extraSeriesColors),
      isPermitByTariff
    }
  }
})
