













import { useStore } from '@/store/store'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import dayjs from 'dayjs'
import { TranslateResult } from 'vue-i18n'

export default defineComponent({
  inheritAttrs: false,
  props: {
    label: String as PropType<TranslateResult>
  },
  setup () {
    const store = useStore()

    const minDate = computed(() => store.minDate.toDate())
    const maxDate = () => dayjs().subtract(1, 'day').startOf('date').toDate()

    return {
      store,
      minDate,
      maxDate
    }
  }
})
