import { Tariff } from './Tariff'
import { PermissionKey } from '@/constants/PERMISSION_KEY'

export interface Subscription {
  id: number
  startedAt?: string
  expiresAt?: string
  days_left?: number
  tariff?: Tariff,
  permission_keys: Array<PermissionKey>
}

export function convertToSubscription (subscription: Record<string, any>): Subscription {
  return {
    id: subscription.id,
    startedAt: subscription.started_at,
    expiresAt: subscription.expires_at,
    days_left: subscription.days_left,
    tariff: subscription.tariff,
    permission_keys: subscription.permission_keys
  }
}
