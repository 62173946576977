










import { concurrentApi } from '@/api'
import { useMetrics } from '@/utils/useMetrics'
import { ProductConcurrentEntity, ProductEntity } from '@/openapi'
import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { useI18n } from '@/utils/useI18n'
import { useDialog } from '@/utils/useDialog'

export default defineComponent({
  props: {
    productId: {
      type: Number as PropType<ProductEntity['id']>,
      required: true
    },
    competitor: {
      type: Object as PropType<ProductConcurrentEntity>,
      required: true
    }
  },
  setup (props, ctx) {
    const i18n = useI18n()
    const dialog = useDialog()
    const metrics = useMetrics()

    const loading = ref(false)

    const description = computed(() => {
      return i18n.t('Product from shop', {
        product: props.competitor.name,
        shop: props.competitor.shopName
      })
    })

    const deleteCompetitor = async () => {
      metrics.hit('analytics_products_product_competitors_delete')

      loading.value = true

      try {
        await concurrentApi.deleteConcurrentV1(props.productId!, props.competitor.id!)
        dialog.showSuccess()
        ctx.emit('success')
      } catch (err) {
        loading.value = false
      }
    }

    return {
      loading,
      description,
      deleteCompetitor
    }
  }
})
