








import { RouteName } from '@/router/route-name'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  setup () {
    const to = { name: RouteName.PRODUCT_REVIEW__COMPETITORS }

    return {
      to
    }
  }
})
