import Vue from 'vue'

export default {
  error: (msg: string) => {
    Vue.$toast.error(msg)
  },
  success: (msg: string) => {
    Vue.$toast.success(msg)
  },
  warning: (msg: string) => {
    Vue.$toast.warning(msg)
  }
}
