import { Dictionary } from 'ts-essentials'
import { mainSeriesColors } from '@xway-team/ui/src/utils/colors'

const seriesColorsKeys = Object.keys(mainSeriesColors)

export function sortSeriesColors (colors: string[]) {
  const map: Dictionary<string, number> = {}

  colors.forEach(el => {
    const index = seriesColorsKeys.indexOf(el)
    map[index] = el
  })

  return Object
    .entries(map)
    // @ts-ignore
    .sort((a, b) => a[0] - b[0])
    .map(el => el[1])
}
