


































































import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  setup (props, ctx) {
    const name = ref('')
    const phone = ref('')

    const loading = ref(false)

    const toMainHref = process.env.VUE_APP_SELLER_CABINET_URL
    const toMainProps = { href: toMainHref }

    const toMain = () => {
      loading.value = true
      window.location = toMainHref
    }

    const submit = () => {
      loading.value = true
      setTimeout(() => {
        try {
          if (Math.random() < 0.5) {
            throw new Error('test')
          }
          ctx.root.$dialog.showSuccess(() => ({
            persistent: true,
            loading: loading.value,
            title: 'Заявка успешно отправлена!',
            submitText: 'На главную',
            submitProps: toMainProps
          }), {
            submit: toMain
          })
        } catch (e) {
          ctx.root.$dialog.showError(() => ({
            persistent: true,
            loading: loading.value,
            noCancel: true,
            submitText: 'На главную',
            submitProps: toMainProps
          }), {
            submit: toMain
          })
        } finally {
          loading.value = false
        }
      }, 2000)
    }

    return {
      name,
      phone,
      loading,
      toMainHref,
      toMainProps,
      toMain,
      submit
    }
  }
})
