const isProduction = process.env.VUE_APP_DEPLOYMENT_ENV === 'production'

function getToken () {
  const cookieToken = getCookie('xw_token')

  if (isProduction) {
    return cookieToken
  }

  const searchParams = new URLSearchParams(document.location.search)

  const paramsToken = searchParams.get('token')
  const storageToken = localStorage.getItem('XW_token')

  if (paramsToken) {
    localStorage.setItem('XW_token', paramsToken)
    return paramsToken
  } else if (paramsToken === '') {
    localStorage.removeItem('XW_token')
    return cookieToken
  } else if (storageToken) {
    return storageToken
  } else {
    return cookieToken
  }
}

function getClientId () {
  if (isProduction) {
    return
  }

  const searchParams = new URLSearchParams(document.location.search)

  const paramsClientId = searchParams.get('client_id')
  const storageClientId = localStorage.getItem('XW_client_id')

  if (paramsClientId) {
    localStorage.setItem('XW_client_id', paramsClientId)
    return paramsClientId
  } else if (paramsClientId === '') {
    localStorage.removeItem('XW_client_id')
  } else if (storageClientId) {
    return storageClientId
  }
}

function erase () {
  localStorage.removeItem('XW_client_id')
  localStorage.removeItem('XW_token')

  removeCookie('xw_token')
}

function logout () {
  erase()
  window.location.replace(process.env.VUE_APP_PASSPORT_URL)
}

export default {
  getToken,
  getClientId,
  erase,
  logout
}

function getCookie (name: string) {
  return document.cookie
    .split('; ')
    .find(el => el.startsWith(name))
    ?.split('=')[1]
}

function removeCookie (name: string) {
  const domain = process.env === 'production' ? '.xway.ru' : 'localhost'
  document.cookie = `${name}=; path=/; domain=${domain}; max-age=0;`
}
