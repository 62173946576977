






















import { computed, defineComponent, PropType, ref } from '@vue/composition-api'
import { ProductEntity } from '@/openapi'
import { productsApi } from '@/api'
import { RouteName } from '@/router/route-name'
import { useCompetitorsProviders } from '@/views/product-review/useBindCompetitors'
import ProductSummary from './ProductSummary.vue'
import { useMetrics } from '@/utils/useMetrics'
import { useI18n } from '@/utils/useI18n'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import { useStore } from '@/store/store'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: {
    NotPermitByTariff,
    ProductSummary
  },
  props: {
    productId: {
      type: Number as PropType<ProductEntity['id']>,
      required: true
    }
  },
  setup (props) {
    useCompetitorsProviders()

    const i18n = useI18n()
    const metrics = useMetrics()
    const store = useStore()

    metrics.useScreenInterceptor(() => ({
      item_id: props.productId
    }))

    const loading = ref(true)
    const error = ref(false)

    const product = ref<ProductEntity | null>(null)

    const breadcrumbs = computed(() => [{
      text: i18n.t('Product analytics'),
      to: { name: RouteName.PRODUCTS }
    }])

    const tabs = computed(() => [{
      title: i18n.t('Positions'),
      to: { name: RouteName.PRODUCT_REVIEW__POSITIONS }
    }, {
      title: i18n.t('Content'),
      to: { name: RouteName.PRODUCT_REVIEW__CONTENT }
    }, {
      title: i18n.t('Indicators'),
      to: { name: RouteName.PRODUCT_REVIEW__INDICATORS }
    }, {
      title: i18n.t('Prices'),
      to: { name: RouteName.PRODUCT_REVIEW__PRICES }
    }, {
      title: i18n.t('Remainders'),
      to: { name: RouteName.PRODUCT_REVIEW__STOCKS }
    }, {
      title: i18n.t('Competitors'),
      to: { name: RouteName.PRODUCT_REVIEW__COMPETITORS }
    }])

    const getProduct = async () => {
      loading.value = true

      try {
        const res = await productsApi.getProductV1(props.productId || 0)
        product.value = res.data.data || null
      } catch (err: any) {
        error.value = err
      } finally {
        loading.value = false
      }
    }

    getProduct()

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_INDICATORS)
    })

    return {
      loading,
      error,
      product,
      breadcrumbs,
      tabs,
      isPermitByTariff
    }
  }
})
