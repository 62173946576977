























































import { computed, defineComponent } from '@vue/composition-api'
import { useInnerTab, useItemsFilter, useSelectedItems } from '../../../inner-tab-template/useInnerTab'
import { useStore } from '@/store/store'
import { concurrentPriceHistoryApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import PricesComparisonChart from './PricesComparisonChart.vue'
import PricesComparisonGrid from './PricesComparisonGrid.vue'

import dayjs from 'dayjs'
import { priceIndicatorNameMapper } from '../priceIndicatorNameMapper'
import CompetitorsLink from '../../../CompetitorsLink.vue'
import { useProductId } from '@/views/product-review/useProductId'
import { useMetrics } from '@/utils/useMetrics'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'

export default defineComponent({
  components: {
    NotPermitByTariff,
    InnerTabTemplate,
    PricesComparisonChart,
    PricesComparisonGrid,
    CurrentPeriodPicker,
    CompetitorsLink
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()

    const downloadComparison = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_prices_compare_report', {
        period: `from_${from}_to_${to}`
      })

      return concurrentPriceHistoryApi.concurrentPriceHistoryDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const tab = useInnerTab({
      async fetchItems () {
        const res = (await concurrentPriceHistoryApi.getConcurrentPriceHistoryV1(
          productId.value,
          dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
          dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
        )).data.data

        if (res) {
          res.forEach(el => {
            el.history.forEach(historyData => {
              historyData.history.forEach(his => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                historyData[his.date] = his.value
                // @ts-ignore
                // TODO: remove
                historyData.id = historyData.product.article
              })
            })
          })
        }

        return res
      },
      trackCompetitorsChanges: true
    })

    const { filter, filtersList, filteredItems } = useItemsFilter(tab, {
      getFilteredItems: (items, filter) => items?.find(el => el.field === filter.field)?.history,
      optionTextMapper: priceIndicatorNameMapper
    })

    const { selectedItems } = useSelectedItems(filteredItems, {
      itemKey: item => item.product?.article
    })

    const noCompetitors = computed(() => filteredItems.value?.length === 1 && filteredItems.value[0].isMy)

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_COMPETITORS_COMPARE)
    })

    return {
      tab,
      filter,
      filtersList,
      filteredItems,
      selectedItems,
      downloadComparison,
      noCompetitors,
      isPermitByTariff
    }
  }
})
