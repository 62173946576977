

















import { computed, defineComponent, PropType } from '@vue/composition-api'
import { PriceHistoryEntity } from '@/openapi'
import { priceIndicatorNameMapper } from '../priceIndicatorNameMapper'
import { priceIndicatorFormatter } from '../priceIndicatorFormatter'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import DataChart from '../../../data-chart/DataChart.vue'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: {
      type: Array as PropType<PriceHistoryEntity[]>,
      default: () => []
    },
    loading: Boolean
  },
  setup (props) {
    const series = computed(() => {
      return props.items.map(el => ({
        ...el,
        data: el.history.map(el => el.value ?? null)
      }))
    })

    const seriesKey = (item: PriceHistoryEntity) => {
      return item.field
    }

    const tooltipItemFormatter: TooltipItemFormatter<PriceHistoryEntity> =
      item => priceIndicatorNameMapper(item)

    const tooltipItemValueFormatter: TooltipItemFormatter<PriceHistoryEntity> =
      (item, ctx) => priceIndicatorFormatter(item, ctx.y)

    return {
      series,
      seriesKey,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      priceIndicatorNameMapper
    }
  }
})
