























import { computed, defineComponent, PropType } from '@vue/composition-api'
import DataChart from '../../../data-chart/DataChart.vue'
import { ConcurrentPriceHistory, ConcurrentPriceHistoryEntity } from '@/openapi'
import { priceIndicatorFormatter } from '../priceIndicatorFormatter'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { primaryItemMatcher } from './pricesPrimaryItemMatcher'
import { formatProductName } from '../../../formatters'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: Array as PropType<ConcurrentPriceHistory[]>,
    selectedItems: Array as PropType<ConcurrentPriceHistory[]>,
    loading: Boolean,
    indicator: Object as PropType<ConcurrentPriceHistoryEntity>
  },
  setup (props) {
    const bindSeriesData = (items?: ConcurrentPriceHistory[]) => {
      return items?.map(el => ({
        ...el,
        data: el.history.map(el => el.value ?? null)
      }))
    }

    const series = computed(() => bindSeriesData(props.items))
    const availableSeries = computed(() => bindSeriesData(props.selectedItems))

    const seriesKey = (item: ConcurrentPriceHistory) => {
      return item.product.article
    }

    const tooltipItemFormatter: TooltipItemFormatter<ConcurrentPriceHistory> = item => {
      return `
        <span class="font-weight-semibold">${item.shop.name}:</span>
        ${formatProductName(item)}
      `
    }

    const tooltipItemValueFormatter: TooltipItemFormatter<ConcurrentPriceHistory> =
      (item, ctx) =>
        priceIndicatorFormatter(props.indicator!, ctx.y)

    return {
      series,
      availableSeries,
      seriesKey,
      formatProductName,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      primaryItemMatcher
    }
  }
})
