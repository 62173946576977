export enum RouteName {
  PRODUCTS = 'products',
  PRODUCT_REVIEW = 'product-review',
  PRODUCT_REVIEW__CONTENT = 'product-review__content',
  PRODUCT_REVIEW__CONTENT__ANALYSIS = 'product-review__content__analysis',
  PRODUCT_REVIEW__INDICATORS = 'product-review__indicators',
  PRODUCT_REVIEW__INDICATORS__HISTORY = 'product-review__indicators__history',
  PRODUCT_REVIEW__INDICATORS__COMPARISON = 'product-review__indicators__comparison',
  PRODUCT_REVIEW__PRICES = 'product-review__prices',
  PRODUCT_REVIEW__PRICES__HISTORY = 'product-review__prices__history',
  PRODUCT_REVIEW__PRICES__COMPARISON = 'product-review__prices__comparison',
  PRODUCT_REVIEW__STOCKS = 'product-review__stocks',
  PRODUCT_REVIEW__STOCKS__HISTORY = 'product-review__stocks__history',
  PRODUCT_REVIEW__STOCKS__COMPARISON = 'product-review__stocks__comparison',
  PRODUCT_REVIEW__POSITIONS = 'product-review__positions',
  PRODUCT_REVIEW__POSITIONS__HISTORY = 'product-review__positions__history',
  PRODUCT_REVIEW__POSITIONS__COMPARISON = 'product-review__positions__comparison',
  PRODUCT_REVIEW__COMPETITORS = 'product-review__competitors',
  REPORTS = 'reports',
}
