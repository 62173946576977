


































import { defineComponent, computed, ref } from '@vue/composition-api'
import { useStore } from '@/store/store'
import { concurrentApi } from '@/api'
import { useProductId } from '@/views/product-review/useProductId'
import { useCompetitorsInjections } from '@/views/product-review/useBindCompetitors'
import { useMetrics } from '@/utils/useMetrics'
import { useDialog } from '@/utils/useDialog'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  props: {
    metrics: {
      type: String,
      required: true
    }
  },
  setup (props, ctx) {
    const store = useStore()
    const i18n = useI18n()
    const metrics = useMetrics()
    const dialog = useDialog()
    const { invalidateCompetitors } = useCompetitorsInjections()
    const loading = ref(false)
    const link = ref('')
    const productId = useProductId()

    const regexp = computed(() => {
      const rexEl = store.marketplacesRegexp.find(el => el.code === store.currentShop?.marketplace?.code)
      return rexEl?.reg_template
    })

    const addCompetitor = async () => {
      metrics.hit(props.metrics)

      loading.value = true
      ctx.emit('submit')

      try {
        await concurrentApi.addConcurrentV1(productId.value, link.value)
        invalidateCompetitors()
        dialog.showSuccess()
        ctx.emit('success')
      } catch (err: any) {
        if (err?.data?.error?.code === 'NOT_VALID_DATA') {
          dialog.showError(() => ({ description: i18n.t('You are trying to add a competitor from another marketplace'), noSubmit: true }))
        } else {
          dialog.showError(() => ({ error: err, noSubmit: true }))
        }

        ctx.emit('error', err)
      } finally {
        link.value = ''
        loading.value = false
      }
    }

    return {
      loading,
      link,
      regexp,
      addCompetitor
    }
  }
})
