import axios, { AxiosError } from 'axios'
import credentials from '@/utils/credentials'
import { isCustomError } from '@/utils/isCustomError'
import { SELLER_PAGE_TARIFF } from '@/constants/urls'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true

const token = credentials.getToken()
const clientId = credentials.getClientId()

axios.interceptors.request.use(config => {
  config.headers.Authorization = token

  if (clientId) {
    config.headers['X-XW-Client-Id'] = clientId
  }

  return config
})

axios.interceptors.response.use(
  res => {
    return isCustomError(res)
      ? Promise.reject(res)
      : res
  },
  err => {
    if (
      isAxiosError(err) &&
      err.response?.status === 401
    ) {
      credentials.erase()

      const returnUrl = window.location.href
      const passportUrl = process.env.VUE_APP_PASSPORT_URL

      const url = `${passportUrl}?return_url=${returnUrl}`
      window.location.replace(url)
    } else if (
      isAxiosError(err) &&
      err.response?.status === 402
    ) {
      const tarifsUrl = `${SELLER_PAGE_TARIFF}?unpaid=true`
      window.location.replace(tarifsUrl)
    }
    return Promise.reject(err)
  }
)

function isAxiosError (err: Error): err is AxiosError {
  return (err as AxiosError).isAxiosError
}
