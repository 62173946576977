

































import { computed, defineComponent, nextTick, PropType, ref, watch } from '@vue/composition-api'
import { Options, TooltipFormatterContextObject, XAxisOptions, XAxisPlotLinesOptions, YAxisOptions } from 'highcharts'
import {
  ChartRef,
  SeriesKey,
  SeriesOptionsTypeExtended,
  SeriesVisibilities,
  TooltipItemFormatter
} from './dataChartTypes'
import { sortSeriesColors } from '@/utils/sortSeriesColors'
import { colors as allColors, extraSeriesColors, mainSeriesColors } from '@xway-team/ui/src/utils/colors'
import { useCurrentDates } from '../useCurrentDates'
import DataChartLegend from './DataChartLegend.vue'
import { tooltipItemRenderer } from '@/views/product-review/data-chart/tooltipItemRenderer'
import { useI18n } from '@/utils/useI18n'
import { useStore } from '@/store/store'

const colors = Object.keys(mainSeriesColors)

const extraColorsNames = Object.keys(extraSeriesColors)
const extraColorsValues = Object.values(extraSeriesColors)

export default defineComponent({
  components: {
    DataChartLegend
  },
  props: {
    type: {
      type: String,
      validator: (v: string) => ['spline', 'column'].includes(v),
      default: 'spline'
    },
    series: {
      type: Array as PropType<SeriesOptionsTypeExtended[]>,
      default: () => []
    },
    seriesKey: {
      type: Function as PropType<(series: SeriesOptionsTypeExtended) => SeriesKey>,
      default: (series: SeriesOptionsTypeExtended) => series.id
    },
    availableSeries: Array as PropType<SeriesOptionsTypeExtended[]>,
    extraSeries: Array as PropType<SeriesOptionsTypeExtended[]>,
    extraSeriesKey: {
      type: Function as PropType<(series: SeriesOptionsTypeExtended) => SeriesKey>,
      default: (series: SeriesOptionsTypeExtended) => series.id
    },
    availableExtraSeries: Array as PropType<SeriesOptionsTypeExtended[]>,
    allText: String,
    tooltipItemFormatter: {
      type: Function as PropType<TooltipItemFormatter>,
      required: true
    },
    tooltipItemValueFormatter: Function as PropType<TooltipItemFormatter>,
    tooltipExtraItemFormatter: Function as PropType<TooltipItemFormatter>,
    tooltipExtraItemValueFormatter: Function as PropType<TooltipItemFormatter>,
    loading: Boolean,
    reverseY: Boolean,
    logarithmicY: Boolean,
    preselectAll: Boolean,
    stacking: Boolean,
    xAxis: [Object, Array] as PropType<XAxisOptions | XAxisOptions[]>,
    yAxis: [Object, Array] as PropType<YAxisOptions | YAxisOptions[]>,
    primaryItemMatcher: Function as PropType<(series: SeriesOptionsTypeExtended) => boolean>
  },
  setup (props) {
    const i18n = useI18n()
    const store = useStore()
    const chartRef = ref<ChartRef>()

    const seriesVisibilities = ref<SeriesVisibilities>(new Map())

    const isEverySeriesVisible = computed(() => {
      for (const val of seriesVisibilities.value.values()) {
        if (!val) return false
      }
      return true
    })
    const isSomeSeriesVisible = computed(() => {
      for (const val of seriesVisibilities.value.values()) {
        if (val) return true
      }
      return false
    })

    const seriesColorsMap = ref(new Map<string | number, typeof colors[number]>())
    let availableColors = colors.slice()

    const mergeSeriesOptions = (item: SeriesOptionsTypeExtended) => {
      const isPrimary = props.primaryItemMatcher && props.primaryItemMatcher(item)

      const zIndex = isPrimary ? Infinity : undefined
      const lineWidth = isPrimary ? 4 : 2

      const id = props.seriesKey(item)

      const colorName = seriesColorsMap.value.get(id)!
      const color = allColors[colorName as keyof typeof allColors]

      return {
        id,
        dashStyle: 'solid',
        zIndex,
        lineWidth,
        color,
        custom: {
          id,
          colorName
        }
      }
    }

    const normalizedSeries = computed(() => {
      if (props.availableSeries) {
        return props.availableSeries.map(el => {
          const seriesKey = props.seriesKey(el)
          const index = props.series?.findIndex(el => props.seriesKey(el) === seriesKey)

          return {
            index,
            ...mergeSeriesOptions(el),
            ...el
          }
        }).sort((a, b) => a.index - b.index) as SeriesOptionsTypeExtended[]
      }

      return props.series.map((el, i) => {
        const index = i

        return {
          index,
          ...mergeSeriesOptions(el),
          ...el
        }
      }) as SeriesOptionsTypeExtended[]
    })

    const mergeExtraSeriesOptions = (item: SeriesOptionsTypeExtended) => {
      const id = props.extraSeriesKey(item)
      const index = props.extraSeries!.findIndex(el => props.extraSeriesKey(el) === id)

      const colorIndex = index % extraColorsNames.length
      const colorName = extraColorsNames[colorIndex]
      const color = extraColorsValues[colorIndex]

      return {
        id: id + '--extra',
        dashStyle: 'ShortDash',
        lineWidth: 2,
        color,
        custom: {
          id,
          colorName,
          isExtra: true
        }
      } as SeriesOptionsTypeExtended
    }
    const normalizedExtraSeries = computed(() => {
      return props.availableExtraSeries?.map(el => {
        return {
          ...mergeExtraSeriesOptions(el),
          ...el
        } as SeriesOptionsTypeExtended
      }) || []
    })

    const chartSeries = computed(() => {
      return normalizedSeries.value.concat(normalizedExtraSeries.value)
    })

    const isPrimaryByKey = (key: string | number) => {
      if (props.primaryItemMatcher) {
        const item = normalizedSeries.value.find(el => props.seriesKey(el) === key)
        return item && props.primaryItemMatcher(item)
      }
    }

    watch(() => props.availableSeries || props.series, val => {
      if (!val.length) {
        availableColors = colors.slice()
        seriesColorsMap.value = new Map()
        return
      }

      const previousKeys: (string | number)[] = []
      for (const key of seriesColorsMap.value.keys()) {
        previousKeys.push(key)
      }
      const currentKeys = val.map(props.seriesKey)

      const unusedKeys = previousKeys.filter(key => !currentKeys.includes(key))
      const freshKeys = currentKeys.filter(key => !previousKeys.includes(key))

      unusedKeys.forEach(key => {
        const color = seriesColorsMap.value.get(key)!
        const isPrimary = color === 'primary'
        if (!isPrimary) {
          availableColors.unshift(color)
        }
        seriesColorsMap.value.delete(key)
      })

      availableColors = sortSeriesColors(availableColors)

      freshKeys.forEach(key => {
        const isPrimary = isPrimaryByKey(key)
        const color = isPrimary ? 'primary' : availableColors.shift()!
        seriesColorsMap.value.set(key, color)
      })

      // activate rerender
      seriesColorsMap.value = new Map(seriesColorsMap.value)
    }, {
      immediate: true
    })

    const presetSeriesVisibilities = (series: SeriesOptionsTypeExtended[]) => {
      const res = new Map() as SeriesVisibilities

      series.forEach((ser, i) => {
        const seriesKey = ser.custom.id
        const oldVisibility = seriesVisibilities.value.get(seriesKey)

        res.set(seriesKey, oldVisibility ?? (props.preselectAll || (ser.preselect ?? i < 3)))
      })

      seriesVisibilities.value = res
    }

    watch(normalizedSeries, presetSeriesVisibilities, {
      immediate: true
    })

    const syncSeriesVisibilities = (visibilities: SeriesVisibilities) => {
      nextTick(() => {
        const { chart } = chartRef.value!
        const { series } = chart

        for (const [seriesKey, visibility] of visibilities.entries()) {
          series.find(el => el.options.custom!.id === seriesKey)?.setVisible(visibility, false)
        }

        chart.redraw()
      })
    }

    watch(seriesVisibilities, syncSeriesVisibilities, {
      immediate: true
    })

    const currentDates = useCurrentDates()

    const yAxis = computed<YAxisOptions | YAxisOptions[]>(() => {
      const defaultAxis: YAxisOptions = {
        title: {
          text: ''
        },
        gridLineWidth: 1,
        lineWidth: 1,
        tickWidth: 1,
        margin: 8,
        labels: {
          useHTML: true,
          formatter () {
            return `
              <div class="data-chart__axis-label">
                ${this.value}
              </div>
            `
          }
        },
        allowDecimals: false,
        showEmpty: false,
        reversed: props.reverseY,
        min: props.reverseY ? 1 : 0,
        type: props.logarithmicY ? 'logarithmic' : 'linear'
        // tickPositioner () {
        //   if (this.tickPositions) {
        //     return this.reversed
        //       ? [(this.min || 0), ...this.tickPositions.slice(1)]
        //       : this.tickPositions
        //   }
        //   return []
        // }
      }

      if (Array.isArray(props.yAxis)) {
        return props.yAxis.map(el => ({
          ...defaultAxis,
          ...el
        }))
      }

      if (props.yAxis) {
        return {
          ...defaultAxis,
          ...props.yAxis
        }
      }

      return defaultAxis
    })

    const options = computed(() => {
      const dataCollectionDate = store.dataCollectionDate.format('DD.MM.YY')
      const plotLines = [{
        value: currentDates.value.findIndex(el => el === dataCollectionDate),
        dashStyle: 'ShortDash',
        width: 1,
        color: allColors.primary,
        zIndex: Infinity,
        label: {
          useHTML: true,
          className: 'highcharts-plot-line-label--collection-date',
          text: i18n.t('Start of data collection')
        }
      } as XAxisPlotLinesOptions]

      return ({
        chart: {
          type: props.type,
          height: 300
        },
        title: {
          text: ''
        },
        legend: {
          enabled: false
        },
        xAxis: {
          categories: props.xAxis || currentDates.value,
          crosshair: true,
          plotLines,
          labels: {
            rotation: -45,
            useHTML: true,
            formatter () {
              return `
              <div class="data-chart__axis-label">
                ${this.value}
              </div>
            `
            }
          }
        },
        yAxis: yAxis.value,
        tooltip: {
          outside: true,
          shared: true,
          hideDelay: 0,
          borderWidth: 0,
          borderRadius: 0,
          padding: 0,
          shadow: false,
          useHTML: true,
          formatter () {
            const points: TooltipFormatterContextObject[] = []
            const extraPoints: TooltipFormatterContextObject[] = []

            this.points?.forEach(el => {
              if (el.series.options.custom!.isExtra) {
                extraPoints.push(el)
              } else {
                points.push(el)
              }
            })

            const items = tooltipItemRenderer(points, props.tooltipItemFormatter, props.tooltipItemValueFormatter)
            const extraItems = tooltipItemRenderer(extraPoints, props.tooltipExtraItemFormatter, props.tooltipExtraItemValueFormatter, true)

            const showDivider = items && extraItems

            return `
            <div class="data-chart__tooltip">
              <div class="font-weight-semibold">
                ${this.x}
              </div>
              ${items || ''}
              ${showDivider ? '<div class="data-chart__tooltip-divider"></div>' : ''}
              ${extraItems || ''}
            </div>
          `
          }
        },
        plotOptions: {
          series: {
            connectNulls: true,
            marker: {
              radius: 2
            }
          },
          spline: {
            marker: {
              symbol: 'circle'
            }
          },
          column: {
            grouping: false,
            stacking: props.stacking ? 'normal' : undefined
          }
        },
        series: chartSeries.value
      }) as Options
    })

    return {
      chartRef,
      isEverySeriesVisible,
      isSomeSeriesVisible,
      normalizedSeries,
      options,
      seriesVisibilities
    }
  }
})
