






































import { computed, defineComponent } from '@vue/composition-api'
import { useInnerTab } from '../../../inner-tab-template/useInnerTab'
import { useStore } from '@/store/store'
import { concurrentStockHistoryApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import StocksComparisonChart from './StocksComparisonChart.vue'
import StocksComparisonGrid from './StocksComparisonGrid.vue'

import dayjs from 'dayjs'
import CompetitorsLink from '../../../CompetitorsLink.vue'
import { useProductId } from '@/views/product-review/useProductId'
import { useMetrics } from '@/utils/useMetrics'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: {
    NotPermitByTariff,
    InnerTabTemplate,
    StocksComparisonChart,
    StocksComparisonGrid,
    CurrentPeriodPicker,
    CompetitorsLink
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()

    const downloadComparison = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_stock_compare_report', {
        period: `from_${from}_to_${to}`
      })

      return concurrentStockHistoryApi.concurrentStockHistoryDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const tab = useInnerTab({
      async fetchItems () {
        const res = (await concurrentStockHistoryApi.getConcurrentStockHistoryV1(
          productId.value,
          dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
          dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
        )).data.data

        if (res) {
          res.forEach(el => {
            // Always one element
            el.history[0].history.forEach(his => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              el[his.date] = his.value
            })
          })
        }

        return res
      },
      trackCompetitorsChanges: true
    })

    const noCompetitors = computed(() => tab.items?.length === 1 && tab.items[0].history[0].isMy)

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_COMPETITORS_COMPARE)
    })

    return {
      tab,
      downloadComparison,
      noCompetitors,
      isPermitByTariff
    }
  }
})
