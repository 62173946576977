import { formatCount, formatCurrency } from '@/utils/formatters'
import { ConcurrentIndicatorEntity, IndicatorEntity, IndicatorFieldType } from '@/openapi'

export function indicatorFormatter (
  item: IndicatorEntity | ConcurrentIndicatorEntity,
  val?: number | string | null
) {
  switch (item.field) {
    case IndicatorFieldType.GMV:
    case IndicatorFieldType.PRICE:
    case IndicatorFieldType.DISCOUNT_PRICE:
    case IndicatorFieldType.ORDERED_MONEY:
    case IndicatorFieldType.ORDERED_MONEY_ALIWAY:
      return formatCurrency(val)
    // case IndicatorFieldType.CTR:
    //   return formatPercent(val)
    default:
      return formatCount(val)
  }
}
