import { XDataCustomSort } from '@xway-team/ui'
import { ProductPositionEntity } from '@/openapi'

export const sortQueryPopularity: XDataCustomSort<ProductPositionEntity> = (
  items,
  sort
) => {
  const factor = sort.direction === 'ASC' ? -1 : 1
  return items.slice().sort((a, b) => {
    const sortProp = sort.prop as keyof ProductPositionEntity['queryPopularity']
    const aVal = (a[sortProp] != null ? (a[sortProp] * factor) : -Infinity)
    const bVal = (b[sortProp] != null ? (b[sortProp] * factor) : -Infinity)
    return bVal - aVal
  })
}
