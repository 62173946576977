<template>
  <div
    class="x-circle-label"
    :class="classes"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    warning: Boolean
  },
  computed: {
    classes () {
      return {
        'x-circle-label--warning': this.warning
      }
    }
  }
}
</script>

<style lang="stylus">
  .x-circle-label
    display inline-flex
    flex-shrink 0
    flex-grow 0
    width 34px
    height 34px
    align-items center
    justify-content center
    border-radius 50%
    border: 1px solid $colors.gray-light
    font-weight: $font-weights.semibold

    &--warning
      border-color: $colors.warning
</style>
