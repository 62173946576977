

























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ConcurrentStockHistoryEntity } from '@/openapi'
import { XDataColumn } from '@xway-team/ui'
import { formatCount } from '@/utils/formatters'
import { primaryItemMatcher } from './stocksPrimaryItemMatcher'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  props: {
    items: Array as PropType<ConcurrentStockHistoryEntity[]>,
    loading: Boolean
  },
  setup () {
    const i18n = useI18n()

    const columns = computed(() => [
      {
        name: i18n.t('shop'),
        prop: 'shop',
        formatter: item => item.history[0].shop.name,
        minWidth: 220,
        fixedLeft: true,
        cellClasses: item => item.history[0].isMy && 'font-weight-semibold'
      },
      {
        name: i18n.t('Product'),
        prop: 'product',
        minWidth: 240,
        fixedLeft: true
      }
      // {
      //   name: i18n.t('Speed'),
      //   prop: 'speed',
      //   formatter: item => item.history[0].speed == null ? '—' : i18n.t('pcs/day', [formatCount(item.history[0].speed)]),
      //   minWidth: 100,
      //   align: 'end'
      // },
      // {
      //   name: i18n.t('Lost profit'),
      //   prop: 'lostProfit',
      //   formatter: item => formatCurrency(item.history[0].lostProfit),
      //   cellClasses: item => item.history[0].lostProfit == null ? '—' : (Number(item.history[0].lostProfit) > 0 && 'error-lightest-1'),
      //   minWidth: 164,
      //   align: 'end'
      // },
    ] as XDataColumn<ConcurrentStockHistoryEntity>[])

    const shopName = (item: ConcurrentStockHistoryEntity) =>
      item.history[0].isMy ? i18n.t('Your product') : item.history[0].shop.name

    const yourProductRowClasses = (item: ConcurrentStockHistoryEntity) =>
      primaryItemMatcher(item) && 'gray-lightest-1'

    return {
      columns,
      shopName,
      yourProductRowClasses,
      formatCount
    }
  }
})
