<template>
  <x-dropdown
    class="x-date-picker"
    popperClass="x-date-picker__popper"
  >
    <x-input
      :value="inputText"
      :placeholder="placeholder"
      prependIcon="calendar"
      readonly
    />
    <template #popper>
      <v-date-picker
        :value="value"
        :columns="2"
        :locale="{ id: $i18n.locale, masks: { weekdays: 'WW' } }"
        :model-config="modelConfig"
        is-range
        color="indigo"
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </x-dropdown>
</template>

<script>
import VDatePicker from 'v-calendar/lib/components/date-picker.umd'
import dayjs from 'dayjs'

export default {
  components: {
    VDatePicker
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    modelConfig: {
      type: 'number'
    }
  }),
  computed: {
    inputText () {
      const start = dayjs(this.value.start).format('L')
      const end = dayjs(this.value.end).format('L')

      return start + ' – ' + end
    }
  },
  methods: {
    onInput (e) {
      this.$emit('input', e)
    }
  }
}
</script>

<style lang="stylus">
.x-date-picker
  &.v-popper
    width auto
  &__popper
    .vc-container
      font-family $font-family
      border none
      border-radius 0
    .vc-header
      padding 14px 18px 10px 18px
    .vc-title
      text-transform capitalize
      font-size 16px
      line-height 20px
      color: $colors.gray-darkest-2
      font-weight: $font-weights.semibold
    .vc-weekday
      color: $colors.gray-dark
      font-weight: $font-weights.normal
      font-size 12px
      text-transform uppercase
    .vc-day-content
      --font-bold: $font-weights.normal
      --indigo-900: $colors.primary
      --rounded-full $space
      &.is-disabled
        pointer-events none
    .vc-highlight
      --rounded-full $space
      --indigo-600: $colors.primary
      --indigo-200: $colors.primary-lightest-2
</style>
