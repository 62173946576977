












































import { computed, defineComponent, PropType } from '@vue/composition-api'
import { useStore } from '@/store/store'
import { usePeriodColumns } from '@/views/product-review/usePeriodColumns'
import { XDataColumn, XDataCustomDateColumn } from '@xway-team/ui'

export default defineComponent({
  inheritAttrs: false,
  props: {
    columns: Array,
    customDateColumn: [Function, Object] as PropType<XDataCustomDateColumn | XDataColumn>
  },
  setup (props) {
    const store = useStore()
    const periodColumns = usePeriodColumns()

    const collectionDateSlotName = computed(() => {
      return `headerCell.${store.dataCollectionDate.format('YYYY-MM-DD')}`
    })

    const computedPeriodColumns = computed(() => {
      if (props.customDateColumn) {
        const customDateColumn = props.customDateColumn
        const customize = typeof customDateColumn === 'function'
          ? customDateColumn
          : (col: XDataColumn) => ({ ...col, ...customDateColumn })
        return periodColumns.value.map(customize)
      }
      return periodColumns.value
    })

    const computedColumns = computed(() => {
      return props.columns?.concat(computedPeriodColumns.value)
    })

    return {
      collectionDateSlotName,
      computedPeriodColumns,
      computedColumns
    }
  }
})
