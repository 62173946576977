import { useStore } from '@/store/store'
import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'
import { MarkRequired } from 'ts-essentials'
import { XDataColumn } from '@xway-team/ui'

export function usePeriodColumns () {
  const store = useStore()

  return computed(() => {
    const dayStart = dayjs(store.currentPeriod.start)

    return Array.from({ length: dayjs(store.currentPeriod.end).diff(store.currentPeriod.start, 'day') + 1 })
      .map((el, i) => {
        const nextDay = dayStart.add(i, 'day')
        return {
          name: nextDay.format('DD.MM.YY'),
          prop: nextDay.format('YYYY-MM-DD'),
          minWidth: 100,
          align: 'end'
        } as MarkRequired<XDataColumn, 'name' | 'prop' | 'minWidth' | 'align'>
      })
  })
}
