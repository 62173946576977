import { useStore } from '@/store/store'
import { computed } from '@vue/composition-api'
import dayjs from 'dayjs'

export function useCurrentDates () {
  const store = useStore()

  return computed(() => {
    const len = dayjs(store.currentPeriod.end).diff(store.currentPeriod.start, 'day') + 1
    const startDay = dayjs(store.currentPeriod.start)

    return Array
      .from({ length: len })
      .map((el, i) => startDay.add(i, 'day').format('DD.MM.YY'))
  })
}
