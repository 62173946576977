


























import { computed, defineComponent, PropType } from '@vue/composition-api'
import { ProductContentRecommendationStatus } from '@/openapi'
import ContentRecommendations from './ContentRecommendations.vue'
import ContentData from './ContentData.vue'
import { ProductContentEntity } from '../productContentEntity'

export default defineComponent({
  components: {
    ContentRecommendations,
    ContentData
  },
  props: {
    index: Number,
    data: Object as PropType<ProductContentEntity>
  },
  setup (props) {
    const isWarning = computed(() => {
      const statuses = props.data?.recommendations?.map(el => el.status) || []
      return (
        statuses.includes(ProductContentRecommendationStatus.SOSO) ||
        statuses.includes(ProductContentRecommendationStatus.BAD)
      )
    })

    return {
      isWarning
    }
  }
})
