






















import { computed, defineComponent, PropType } from '@vue/composition-api'
import DataChart from '../../../data-chart/DataChart.vue'
import { ConcurrentStockHistoryEntity } from '@/openapi'
import { TooltipItemFormatter } from '../../../data-chart/dataChartTypes'
import { primaryItemMatcher } from './stocksPrimaryItemMatcher'
import DataSourceTooltip from '@/views/product-review/DataSourceTooltip.vue'
import { useI18n } from '@/utils/useI18n'

export default defineComponent({
  components: {
    DataChart,
    DataSourceTooltip
  },
  props: {
    items: Array as PropType<ConcurrentStockHistoryEntity[]>,
    loading: Boolean
  },
  setup (props) {
    const i18n = useI18n()

    const series = computed(() => {
      return props.items?.map(el => ({
        ...el,
        data: el.history[0].history.map(el => el.value ?? null)
      }))
    })

    const seriesKey = (item: ConcurrentStockHistoryEntity) => {
      return item.history[0].product.article
    }

    const formatProductName = (item: ConcurrentStockHistoryEntity) =>
      item.history[0].isMy
        ? i18n.t('Your product')
        : item.history[0].product.name

    const tooltipItemFormatter: TooltipItemFormatter<ConcurrentStockHistoryEntity> = item => {
      return `
        <span class="font-weight-semibold">${item.warehouse.name}:</span>
        ${formatProductName(item)}
      `
    }

    const tooltipItemValueFormatter: TooltipItemFormatter<ConcurrentStockHistoryEntity> =
      (item, ctx) =>
        i18n.t('pcs', [ctx.y]) as string

    return {
      series,
      seriesKey,
      tooltipItemFormatter,
      tooltipItemValueFormatter,
      formatProductName,
      primaryItemMatcher
    }
  }
})
