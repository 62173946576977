import i18n from '@/plugins/vue-i18n'
import { ConcurrentPositionEntitySpanRow } from './positionsComparisonTypes'

export function formatShopName (item: ConcurrentPositionEntitySpanRow) {
  if (item.isMy) {
    const typeSku = item.product.type_sku
    const postfix = typeSku ? ` (${typeSku.toUpperCase()})` : ''
    return i18n.t('Your product') + postfix
  }
  return item.product.shop?.name
}
