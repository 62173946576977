import { AxiosResponse } from 'axios'
import { XPaging } from '@xway-team/ui'

export interface ApiResponse<T = any> {
  data: T
  isSuccess: boolean
  error?: {
    code: number
    msg: string
    params: any
  }
  paging?: XPaging
}

export type AxiosApiResponse<T = any> = AxiosResponse<ApiResponse<T>>

export interface Period {
  start: number
  end: number
}

export enum AppQuery {
  SHOP = 'shop',
  DATE_FROM = 'dateFrom',
  DATE_TO = 'dateTo'
}
