import { useStore } from '@/store/store'
import { ref, Ref, onActivated, watch, onDeactivated, WatchStopHandle, reactive, computed, UnwrapRef } from '@vue/composition-api'
import { TranslateResult } from 'vue-i18n'
import { discardable } from '@/utils/discardable'
import { useCompetitorsInjections } from '@/views/product-review/useBindCompetitors'

type Tab<T> = UnwrapRef<{
  loading: boolean
  error: boolean
  items?: T[]
}>

export function useInnerTab<T> (options: {
  fetchItems: () => Promise<T[] | undefined>,
  trackCompetitorsChanges?: boolean
}): Tab<T> {
  const { fetchItems, trackCompetitorsChanges } = options

  const store = useStore()
  const { token: competitorsToken } = useCompetitorsInjections()

  const loading = ref(false)
  const error = ref(false)
  const items = ref<T[] | undefined>()

  const getData = discardable(() => {
    loading.value = true
    return fetchItems()
  })
    .then(res => {
      error.value = false
      items.value = res
    })
    .catch((err: any) => {
      error.value = err
    })
    .finally(() => {
      loading.value = false
    })

  let period = store.currentPeriod
  let token = competitorsToken.value

  let unwatchPeriod: WatchStopHandle

  onActivated(() => {
    const differentPeriods =
      (period.start !== store.currentPeriod.start) ||
      (period.end !== store.currentPeriod.end)

    const differentTokens = token !== competitorsToken.value

    if (differentPeriods) {
      period = store.currentPeriod
    }

    if (differentTokens) {
      token = competitorsToken.value
    }

    if (
      differentPeriods ||
      (trackCompetitorsChanges && differentTokens)
    ) {
      getData()
    }

    unwatchPeriod = watch(() => store.currentPeriod, val => {
      getData()
      period = val
    })
  })

  onDeactivated(() => {
    if (unwatchPeriod) {
      unwatchPeriod()
    }
  })

  getData()

  return reactive({
    loading,
    error,
    items
  })
}

export function useItemsFilter<T, R> (
  tab: Tab<T>,
  options: {
    getFilteredItems: (items: Tab<T>['items'], filter: T) => R,
    optionTextMapper: (item: T) => TranslateResult | undefined
  }
) {
  const { getFilteredItems, optionTextMapper } = options

  const filter = ref<T>()

  const filtersList = computed(() => {
    if (tab.items) {
      const items = tab.items as T[]

      return items.map(el => ({
        ...el,
        label: optionTextMapper(el)
      }))
    }
    return []
  })

  const filteredItems = computed(() =>
    filter.value &&
    getFilteredItems(tab.items, filter.value)
  )

  watch(filtersList, filtersList => {
    if (
      !filter.value ||
      !filtersList.find(el => el === filter.value)
    ) {
      filter.value = filtersList[0]
    }
  })

  return {
    filter,
    filtersList,
    filteredItems
  }
}

export function useSelectedItems<T> (
  sourceItems: Ref<T[] | undefined>,
  options: {
    itemKey: (item: T) => string | number | undefined | null
    preselectAll?: boolean
  }
) {
  const store = useStore()
  let savedPeriod = store.currentPeriod

  const { itemKey, preselectAll } = options
  const selectedItems = ref([]) as Ref<T[]>

  watch(sourceItems, () => {
    if (!sourceItems.value) {
      selectedItems.value = []
      return
    }

    const matcher = (item: T) => selectedItems.value.find(el => itemKey(item) === itemKey(el))
    const preselectedItems = sourceItems.value.filter(matcher)

    const hasPreselectedItems = preselectedItems?.length
    const samePeriods = savedPeriod === store.currentPeriod

    if (
      !samePeriods &&
      hasPreselectedItems
    ) {
      selectedItems.value = preselectedItems
    } else {
      const end = preselectAll ? Infinity : 3
      selectedItems.value = sourceItems.value.slice(0, end)
      savedPeriod = store.currentPeriod
    }
  })

  return {
    selectedItems
  }
}

export function useFilter<T> (
  options?: Ref<T[] | undefined>,
  optionText?: (item: T) => TranslateResult | undefined
) {
  const filter = ref<T | null>(null)
  const filterOptions = computed(() => {
    if (!options || !options.value) {
      return []
    }

    return optionText
      ? options.value.map(el => ({ ...el, label: optionText(el) }))
      : options.value
  })

  return {
    filter,
    filterOptions
  }
}
