


























































import { defineComponent, ref } from '@vue/composition-api'
import { RouteName } from '@/router/route-name'
import { useI18n } from '@/utils/useI18n'
import { useDialog } from '@/utils/useDialog'
import { sellerApi } from '@/api'

export default defineComponent({
  setup () {
    const i18n = useI18n()
    const dialog = useDialog()

    const toReports = { name: RouteName.REPORTS }

    const orderLoading = ref(false)

    const orderRecommendations = async () => {
      orderLoading.value = true
      try {
        await sellerApi.orderRecommendations('Товарная аналитика')
        dialog.showSuccess(() => ({
          title: `${i18n.t('The service has been successfully ordered')}!`
        }))
      } catch (e) {
        dialog.showError(() => ({
          error: e,
          noSubmit: true
        }))
      } finally {
        orderLoading.value = false
      }
    }

    return {
      toReports,
      orderLoading,
      orderRecommendations
    }
  }
})
