













import { useStore } from '@/store/store'
import { computed, defineComponent } from '@vue/composition-api'
import { shopIconMapper } from '@/utils/shopIconMapper'
import { setRouteQuery } from '@/utils/routeQuery'
import { ShopEntity } from '@/openapi'
import { AppQuery } from '@/types'

export default defineComponent({
  inheritAttrs: false,
  setup () {
    const store = useStore()

    const shops = computed(() => {
      return store.user.shops?.map(el => {
        return {
          ...el,
          $isDisabled: el.is_paywalled_in_lkp
        }
      })
    })

    const changeShop = (shop: ShopEntity) => {
      store.currentShop = shop
      setRouteQuery(AppQuery.SHOP, shop.id)
    }

    return {
      store,
      shops,
      shopIconMapper,
      changeShop
    }
  }
})
