/* tslint:disable */
/* eslint-disable */
/**
 *  backend service
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: local
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BodyGetProductAnalyticsApiV1ProductsAnalyticsPost
 */
export interface BodyGetProductAnalyticsApiV1ProductsAnalyticsPost {
    /**
     * 
     * @type {ProductFilter}
     * @memberof BodyGetProductAnalyticsApiV1ProductsAnalyticsPost
     */
    filters?: ProductFilter;
    /**
     * 
     * @type {PagingByPage}
     * @memberof BodyGetProductAnalyticsApiV1ProductsAnalyticsPost
     */
    pagination?: PagingByPage;
    /**
     * 
     * @type {Array<ProductSorterEntity>}
     * @memberof BodyGetProductAnalyticsApiV1ProductsAnalyticsPost
     */
    sorters?: Array<ProductSorterEntity>;
}
/**
 * 
 * @export
 * @interface BodyGetProductListApiV1ProductsListPost
 */
export interface BodyGetProductListApiV1ProductsListPost {
    /**
     * 
     * @type {ProductFilter}
     * @memberof BodyGetProductListApiV1ProductsListPost
     */
    filters?: ProductFilter;
    /**
     * 
     * @type {PagingByPage}
     * @memberof BodyGetProductListApiV1ProductsListPost
     */
    pagination?: PagingByPage;
    /**
     * 
     * @type {Array<ProductSorterEntity>}
     * @memberof BodyGetProductListApiV1ProductsListPost
     */
    sorters?: Array<ProductSorterEntity>;
}
/**
 * Бизнес-сущность категории.
 * @export
 * @interface CategoryEntity
 */
export interface CategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof CategoryEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CategoryEntity
     */
    name: string;
}
/**
 * Бизнес-сущность клиента.
 * @export
 * @interface ClientEntity
 */
export interface ClientEntity {
    /**
     * 
     * @type {number}
     * @memberof ClientEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClientEntity
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof ClientEntity
     */
    partner_id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClientEntity
     */
    isActive?: boolean;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ConcurrentContentEntity
 */
export interface ConcurrentContentEntity {
    /**
     * 
     * @type {ShopFullEntity}
     * @memberof ConcurrentContentEntity
     */
    shop?: ShopFullEntity;
    /**
     * 
     * @type {ProductEntity}
     * @memberof ConcurrentContentEntity
     */
    product?: ProductEntity;
    /**
     * 
     * @type {string}
     * @memberof ConcurrentContentEntity
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConcurrentContentEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ConcurrentContentEntity
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentContentEntity
     */
    productImages?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentContentEntity
     */
    productImages360?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentContentEntity
     */
    productVideo?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ConcurrentContentEntity
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ConcurrentContentEntity
     */
    review?: number;
    /**
     * 
     * @type {ProductContentPriceEntity}
     * @memberof ConcurrentContentEntity
     */
    productPrice?: ProductContentPriceEntity;
    /**
     * 
     * @type {Array<ProductAttributesEntity>}
     * @memberof ConcurrentContentEntity
     */
    productAttributes?: Array<ProductAttributesEntity>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentContentEntity
     */
    allProductAttributesName?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentContentEntity
     */
    search_categories?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConcurrentContentEntity
     */
    search_phrase?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ConcurrentContentEntity
     */
    isMy?: boolean;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ConcurrentIndicatorEntity
 */
export interface ConcurrentIndicatorEntity {
    /**
     * 
     * @type {IndicatorFieldType}
     * @memberof ConcurrentIndicatorEntity
     */
    field?: IndicatorFieldType;
    /**
     * 
     * @type {boolean}
     * @memberof ConcurrentIndicatorEntity
     */
    hasComments?: boolean;
    /**
     * 
     * @type {Array<ConcurrentIndicatorsHistory>}
     * @memberof ConcurrentIndicatorEntity
     */
    history: Array<ConcurrentIndicatorsHistory>;
}
/**
 * 
 * @export
 * @interface ConcurrentIndicatorsHistory
 */
export interface ConcurrentIndicatorsHistory {
    /**
     * 
     * @type {ProductEntity}
     * @memberof ConcurrentIndicatorsHistory
     */
    product: ProductEntity;
    /**
     * 
     * @type {ShopEntity}
     * @memberof ConcurrentIndicatorsHistory
     */
    shop: ShopEntity;
    /**
     * 
     * @type {boolean}
     * @memberof ConcurrentIndicatorsHistory
     */
    isMy: boolean;
    /**
     * 
     * @type {SourceType}
     * @memberof ConcurrentIndicatorsHistory
     */
    dataSource?: SourceType;
    /**
     * 
     * @type {Array<HistoryEntityFloat>}
     * @memberof ConcurrentIndicatorsHistory
     */
    history: Array<HistoryEntityFloat>;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ConcurrentPositionEntity
 */
export interface ConcurrentPositionEntity {
    /**
     * 
     * @type {string}
     * @memberof ConcurrentPositionEntity
     */
    field: string;
    /**
     * 
     * @type {SearchType}
     * @memberof ConcurrentPositionEntity
     */
    searchType?: SearchType;
    /**
     * 
     * @type {CategoryEntity}
     * @memberof ConcurrentPositionEntity
     */
    category?: CategoryEntity;
    /**
     * 
     * @type {number}
     * @memberof ConcurrentPositionEntity
     */
    categoryProductCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ConcurrentPositionEntity
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ConcurrentPositionEntity
     */
    type_sku?: string;
    /**
     * 
     * @type {number}
     * @memberof ConcurrentPositionEntity
     */
    queryPopularity?: number;
    /**
     * 
     * @type {number}
     * @memberof ConcurrentPositionEntity
     */
    concurrentCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ConcurrentPositionEntity
     */
    withoutMy?: boolean;
    /**
     * 
     * @type {Array<object>}
     * @memberof ConcurrentPositionEntity
     */
    spanRows?: Array<object>;
}
/**
 * 
 * @export
 * @interface ConcurrentPriceHistory
 */
export interface ConcurrentPriceHistory {
    /**
     * 
     * @type {ProductEntity}
     * @memberof ConcurrentPriceHistory
     */
    product: ProductEntity;
    /**
     * 
     * @type {SourceType}
     * @memberof ConcurrentPriceHistory
     */
    dataSource?: SourceType;
    /**
     * 
     * @type {ShopEntity}
     * @memberof ConcurrentPriceHistory
     */
    shop: ShopEntity;
    /**
     * 
     * @type {boolean}
     * @memberof ConcurrentPriceHistory
     */
    isMy: boolean;
    /**
     * 
     * @type {Array<HistoryEntity>}
     * @memberof ConcurrentPriceHistory
     */
    history: Array<HistoryEntity>;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ConcurrentPriceHistoryEntity
 */
export interface ConcurrentPriceHistoryEntity {
    /**
     * 
     * @type {PriceHistoryFieldType}
     * @memberof ConcurrentPriceHistoryEntity
     */
    field: PriceHistoryFieldType;
    /**
     * 
     * @type {string}
     * @memberof ConcurrentPriceHistoryEntity
     */
    product_id?: string;
    /**
     * 
     * @type {Array<ConcurrentPriceHistory>}
     * @memberof ConcurrentPriceHistoryEntity
     */
    history: Array<ConcurrentPriceHistory>;
}
/**
 * Список возможных статусов конкурента.
 * @export
 * @enum {string}
 */

export enum ConcurrentStatus {
    NEW = 'NEW',
    DOWNLOAD = 'DOWNLOAD',
    ERROR = 'ERROR',
    ACTIVE = 'ACTIVE'
}

/**
 * 
 * @export
 * @interface ConcurrentStockHistory
 */
export interface ConcurrentStockHistory {
    /**
     * 
     * @type {ProductEntity}
     * @memberof ConcurrentStockHistory
     */
    product: ProductEntity;
    /**
     * 
     * @type {ShopEntity}
     * @memberof ConcurrentStockHistory
     */
    shop: ShopEntity;
    /**
     * 
     * @type {SourceType}
     * @memberof ConcurrentStockHistory
     */
    dataSource?: SourceType;
    /**
     * 
     * @type {boolean}
     * @memberof ConcurrentStockHistory
     */
    isMy: boolean;
    /**
     * 
     * @type {Array<HistoryEntity>}
     * @memberof ConcurrentStockHistory
     */
    history: Array<HistoryEntity>;
}
/**
 * 
 * @export
 * @interface ConcurrentStockHistoryEntity
 */
export interface ConcurrentStockHistoryEntity {
    /**
     * 
     * @type {string}
     * @memberof ConcurrentStockHistoryEntity
     */
    field: string;
    /**
     * 
     * @type {Warehouse}
     * @memberof ConcurrentStockHistoryEntity
     */
    warehouse: Warehouse;
    /**
     * 
     * @type {Array<ConcurrentStockHistory>}
     * @memberof ConcurrentStockHistoryEntity
     */
    history: Array<ConcurrentStockHistory>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ErrorCode {
    UNKNOWN = 'UNKNOWN',
    BASE_ERROR_CODE = 'BASE_ERROR_CODE',
    ITEM_NOT_FOUND = 'ITEM_NOT_FOUND',
    ITEM_IS_BLOCKED = 'ITEM_IS_BLOCKED',
    BUSINESS_LOGIC = 'BUSINESS_LOGIC',
    DATABASE_ERROR = 'DATABASE_ERROR',
    PERMISSION_DENIED = 'PERMISSION_DENIED',
    SERVER_ERROR = 'SERVER_ERROR',
    CONNECTION_ERROR = 'CONNECTION_ERROR',
    NOT_VALID_DATA = 'NOT_VALID_DATA',
    UNAUTHORIZED = 'UNAUTHORIZED',
    DUPLICATE = 'DUPLICATE',
    EMPTY_HEADER = 'EMPTY_HEADER',
    INVALID_HEADER = 'INVALID_HEADER',
    NOT_PRESENT_ARTICLE = 'NOT_PRESENT_ARTICLE',
    TARIFF_EXPIRED = 'TARIFF_EXPIRED',
    MP_API_KEY_ERROR = 'MP_API_KEY_ERROR',
    MARKETPLACE_ERROR = 'MARKETPLACE_ERROR',
    MARKETPLACE_TIMEOUT_ERROR = 'MARKETPLACE_TIMEOUT_ERROR',
    SHOP_MARKETPLACE_ERROR = 'SHOP_MARKETPLACE_ERROR'
}

/**
 * Список возможных типов ошибок.
 * @export
 * @enum {string}
 */

export enum ErrorType {
    BUSINESS = 'BUSINESS',
    DATABASES = 'DATABASES',
    EXTERNAL_CONNECTIONS = 'EXTERNAL_CONNECTIONS',
    MARKETPLACE_CONNECTIONS = 'MARKETPLACE_CONNECTIONS',
    VALIDATION = 'VALIDATION',
    BASE = 'BASE'
}

/**
 * 
 * @export
 * @interface ErrorWrapper
 */
export interface ErrorWrapper {
    /**
     * 
     * @type {ErrorType}
     * @memberof ErrorWrapper
     */
    type?: ErrorType;
    /**
     * 
     * @type {ErrorCode}
     * @memberof ErrorWrapper
     */
    code?: ErrorCode;
    /**
     * 
     * @type {string}
     * @memberof ErrorWrapper
     */
    msg?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ErrorWrapper
     */
    params?: { [key: string]: string; };
}
/**
 * Бизнес-сущность комплексного фильтра.
 * @export
 * @interface FilterItemComplex
 */
export interface FilterItemComplex {
    /**
     * 
     * @type {FilterOperation}
     * @memberof FilterItemComplex
     */
    operation?: FilterOperation;
    /**
     * 
     * @type {string}
     * @memberof FilterItemComplex
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterItemComplex
     */
    valueFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterItemComplex
     */
    valueTo?: string;
}
/**
 * Список возможных типов операция комплексных фильтров.
 * @export
 * @enum {string}
 */

export enum FilterOperation {
    EQUALS = 'EQUALS',
    MORE_THAN = 'MORE_THAN',
    LESS_THAN = 'LESS_THAN',
    BETWEEN = 'BETWEEN'
}

/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    detail?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HistoryEntity
 */
export interface HistoryEntity {
    /**
     * 
     * @type {string}
     * @memberof HistoryEntity
     */
    date?: string;
    /**
     * 
     * @type {any}
     * @memberof HistoryEntity
     */
    value?: any | null;
}
/**
 * 
 * @export
 * @interface HistoryEntityFloat
 */
export interface HistoryEntityFloat {
    /**
     * 
     * @type {string}
     * @memberof HistoryEntityFloat
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof HistoryEntityFloat
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface IndicatorChartChecked
 */
export interface IndicatorChartChecked {
    /**
     * Значения параметра
     * @type {Array<IndicatorFieldType>}
     * @memberof IndicatorChartChecked
     */
    chartChecked?: Array<IndicatorFieldType>;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface IndicatorEntity
 */
export interface IndicatorEntity {
    /**
     * 
     * @type {IndicatorFieldType}
     * @memberof IndicatorEntity
     */
    field?: IndicatorFieldType;
    /**
     * 
     * @type {boolean}
     * @memberof IndicatorEntity
     */
    hasComments?: boolean;
    /**
     * 
     * @type {Array<HistoryEntity>}
     * @memberof IndicatorEntity
     */
    history: Array<HistoryEntity>;
    /**
     * 
     * @type {SourceType}
     * @memberof IndicatorEntity
     */
    dataSource?: SourceType;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum IndicatorFieldType {
    SHOWED = 'SHOWED',
    ADDED_TO_BIN = 'ADDED_TO_BIN',
    CONVERSION_TO_BIN = 'CONVERSION_TO_BIN',
    ORDERED = 'ORDERED',
    ORDERED_MONEY = 'ORDERED_MONEY',
    CANCELED = 'CANCELED',
    GMV = 'GMV',
    PRICE = 'PRICE',
    DISCOUNT_PRICE = 'DISCOUNT_PRICE',
    STOCK = 'STOCK',
    RETURNED = 'RETURNED',
    RATING = 'RATING',
    REVIEWS = 'REVIEWS',
    UNKNOWN = 'UNKNOWN',
    ORDERED_ALIWAY = 'ORDERED_ALIWAY',
    ORDERED_MONEY_ALIWAY = 'ORDERED_MONEY_ALIWAY',
    REDEEMED_COUNT = 'REDEEMED_COUNT',
    REDEEMED_MONEY = 'REDEEMED_MONEY'
}

/**
 * Бизнес-сущность маркетплейса.
 * @export
 * @interface MarketplaceEntity
 */
export interface MarketplaceEntity {
    /**
     * 
     * @type {number}
     * @memberof MarketplaceEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceEntity
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketplaceEntity
     */
    url?: string;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface MenuDto
 */
export interface MenuDto {
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    icon?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MenuDto
     */
    exact?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    resource?: string;
    /**
     * 
     * @type {number}
     * @memberof MenuDto
     */
    parent_id?: number;
    /**
     * 
     * @type {Array<MenuDto>}
     * @memberof MenuDto
     */
    children?: Array<MenuDto>;
    /**
     * 
     * @type {string}
     * @memberof MenuDto
     */
    eventName?: string;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface MpRegexpSourceUrl
 */
export interface MpRegexpSourceUrl {
    /**
     * 
     * @type {string}
     * @memberof MpRegexpSourceUrl
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof MpRegexpSourceUrl
     */
    reg_template: string;
}
/**
 * 
 * @export
 * @interface PagingByPage
 */
export interface PagingByPage {
    /**
     * 
     * @type {number}
     * @memberof PagingByPage
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PagingByPage
     */
    limit?: number;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    current: number;
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    progress: number;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface PositionStatistic
 */
export interface PositionStatistic {
    /**
     * 
     * @type {string}
     * @memberof PositionStatistic
     */
    date: string;
    /**
     * 
     * @type {Position}
     * @memberof PositionStatistic
     */
    position?: Position;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface PriceHistoryEntity
 */
export interface PriceHistoryEntity {
    /**
     * 
     * @type {PriceHistoryFieldType}
     * @memberof PriceHistoryEntity
     */
    field: PriceHistoryFieldType;
    /**
     * 
     * @type {string}
     * @memberof PriceHistoryEntity
     */
    product_id?: string;
    /**
     * 
     * @type {Array<HistoryEntity>}
     * @memberof PriceHistoryEntity
     */
    history: Array<HistoryEntity>;
    /**
     * 
     * @type {SourceType}
     * @memberof PriceHistoryEntity
     */
    dataSource?: SourceType;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum PriceHistoryFieldType {
    PRICE = 'PRICE',
    DISCOUNT_PRICE = 'DISCOUNT_PRICE',
    PROMO = 'PROMO',
    SALES = 'SALES'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductAggrFieldType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7
}

/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductAnalyticsCategoryEntity
 */
export interface ProductAnalyticsCategoryEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsCategoryEntity
     */
    categoryId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsCategoryEntity
     */
    categoryName?: string;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductAnalyticsCurrentFieldEntity
 */
export interface ProductAnalyticsCurrentFieldEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsCurrentFieldEntity
     */
    current?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsCurrentFieldEntity
     */
    old?: number;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductAnalyticsEntity
 */
export interface ProductAnalyticsEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    marketplaceArticle?: string;
    /**
     * 
     * @type {ProductAnalyticsCategoryEntity}
     * @memberof ProductAnalyticsEntity
     */
    category?: ProductAnalyticsCategoryEntity;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    externalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAnalyticsEntity
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    marketplaceUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    mainImage?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    stock?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    price?: string;
    /**
     * 
     * @type {ProductStorageStatusEntity}
     * @memberof ProductAnalyticsEntity
     */
    status?: ProductStorageStatusEntity;
    /**
     * 
     * @type {string}
     * @memberof ProductAnalyticsEntity
     */
    abcLabel?: string;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    positionCats?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    positionSearch?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    ordersCount?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    ordersMoney?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    redeemedCount?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    redeemedMoney?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    canceled?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    returned?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsEntity
     */
    zeroStockDay?: number;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    rating?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {ProductAnalyticsCurrentFieldEntity}
     * @memberof ProductAnalyticsEntity
     */
    review?: ProductAnalyticsCurrentFieldEntity;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsEntity
     */
    hitsView?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsEntity
     */
    hitsTocart?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductAnalyticsEntity
     */
    convTocart?: number;
}
/**
 * Сущность атрибутов продукта.
 * @export
 * @interface ProductAttributesEntity
 */
export interface ProductAttributesEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductAttributesEntity
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributesEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributesEntity
     */
    categoryName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductAttributesEntity
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductAttributesEntity
     */
    isRequired?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductAttributesEntity
     */
    values?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity
 */
export interface ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {Array<ProductAttributesEntity>}
     * @memberof ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity
     */
    data?: Array<ProductAttributesEntity>;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperListStr
 */
export interface ProductBlockContentWrapperListStr {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperListStr
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBlockContentWrapperListStr
     */
    data?: Array<string>;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperListStr
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperProductContentImagesDto
 */
export interface ProductBlockContentWrapperProductContentImagesDto {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperProductContentImagesDto
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {ProductContentImagesDto}
     * @memberof ProductBlockContentWrapperProductContentImagesDto
     */
    data?: ProductContentImagesDto;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperProductContentImagesDto
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperProductContentPriceDto
 */
export interface ProductBlockContentWrapperProductContentPriceDto {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperProductContentPriceDto
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {ProductContentPriceDto}
     * @memberof ProductBlockContentWrapperProductContentPriceDto
     */
    data?: ProductContentPriceDto;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperProductContentPriceDto
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperProductContentRatingReviewDto
 */
export interface ProductBlockContentWrapperProductContentRatingReviewDto {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperProductContentRatingReviewDto
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {ProductContentRatingReviewDto}
     * @memberof ProductBlockContentWrapperProductContentRatingReviewDto
     */
    data?: ProductContentRatingReviewDto;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperProductContentRatingReviewDto
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperProductDescriptionDto
 */
export interface ProductBlockContentWrapperProductDescriptionDto {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperProductDescriptionDto
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {ProductDescriptionDto}
     * @memberof ProductBlockContentWrapperProductDescriptionDto
     */
    data?: ProductDescriptionDto;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperProductDescriptionDto
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * 
 * @export
 * @interface ProductBlockContentWrapperStr
 */
export interface ProductBlockContentWrapperStr {
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductBlockContentWrapperStr
     */
    block: ProductContentBlock;
    /**
     * 
     * @type {string}
     * @memberof ProductBlockContentWrapperStr
     */
    data?: string;
    /**
     * 
     * @type {Array<ProductContentRecommendationDto>}
     * @memberof ProductBlockContentWrapperStr
     */
    recommendations?: Array<ProductContentRecommendationDto>;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductConcurrentCountEntity
 */
export interface ProductConcurrentCountEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentCountEntity
     */
    productWithConcurrentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentCountEntity
     */
    concurrentCount?: number;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductConcurrentEntity
 */
export interface ProductConcurrentEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    sourceUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentEntity
     */
    productId?: number;
    /**
     * 
     * @type {SearchType}
     * @memberof ProductConcurrentEntity
     */
    searchType?: SearchType;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    name?: string;
    /**
     * 
     * @type {ShopEntity}
     * @memberof ProductConcurrentEntity
     */
    shop?: ShopEntity;
    /**
     * 
     * @type {MarketplaceEntity}
     * @memberof ProductConcurrentEntity
     */
    marketplace?: MarketplaceEntity;
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentEntity
     */
    shopId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    shopName?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentEntity
     */
    marketplaceId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentEntity
     */
    dataMiningId?: number;
    /**
     * 
     * @type {ConcurrentStatus}
     * @memberof ProductConcurrentEntity
     */
    status?: ConcurrentStatus;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    errorMsg?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductConcurrentEntity
     */
    mainImage?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductConcurrentEntity
     */
    aliwayShopId?: number;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductContentBlock {
    NAME = 'NAME',
    DESCRIPTION = 'DESCRIPTION',
    IMAGE = 'IMAGE',
    VIDEO = 'VIDEO',
    IMAGE360 = 'IMAGE360',
    CHARACTERISTICS = 'CHARACTERISTICS',
    PRICE = 'PRICE',
    RATING = 'RATING',
    REVIEW = 'REVIEW',
    SEARCH_PHRASE = 'SEARCH_PHRASE',
    SEARCH_CATEGORIES = 'SEARCH_CATEGORIES',
    RATING_REVIEW = 'RATING_REVIEW'
}

/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductContentConcurrentPriceDto
 */
export interface ProductContentConcurrentPriceDto {
    /**
     * 
     * @type {string}
     * @memberof ProductContentConcurrentPriceDto
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductContentConcurrentPriceDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductContentConcurrentPriceDto
     */
    sellerName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductContentConcurrentPriceDto
     */
    image?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductContentConcurrentPriceDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentConcurrentPriceDto
     */
    regularPrice?: number;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductContentImagesDto
 */
export interface ProductContentImagesDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductContentImagesDto
     */
    images?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductContentImagesDto
     */
    max_count?: number;
}
/**
 * Сущность цен продукта.
 * @export
 * @interface ProductContentPriceDto
 */
export interface ProductContentPriceDto {
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceDto
     */
    discountPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceDto
     */
    minConcurrent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceDto
     */
    maxConcurrent?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceDto
     */
    averageConcurrent?: number;
    /**
     * 
     * @type {Array<ProductContentConcurrentPriceDto>}
     * @memberof ProductContentPriceDto
     */
    concurrent_prices?: Array<ProductContentConcurrentPriceDto>;
}
/**
 * Сущность цен продукта.
 * @export
 * @interface ProductContentPriceEntity
 */
export interface ProductContentPriceEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceEntity
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentPriceEntity
     */
    discountPrice?: number;
}
/**
 * Сущность рейтинга и отзывов продукта.
 * @export
 * @interface ProductContentRatingReviewDto
 */
export interface ProductContentRatingReviewDto {
    /**
     * 
     * @type {number}
     * @memberof ProductContentRatingReviewDto
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductContentRatingReviewDto
     */
    reviews?: number;
}
/**
 * 
 * @export
 * @interface ProductContentRecommendationDto
 */
export interface ProductContentRecommendationDto {
    /**
     * 
     * @type {string}
     * @memberof ProductContentRecommendationDto
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof ProductContentRecommendationDto
     */
    full?: string;
    /**
     * 
     * @type {ProductContentRecommendationStatus}
     * @memberof ProductContentRecommendationDto
     */
    status: ProductContentRecommendationStatus;
    /**
     * 
     * @type {ProductContentBlock}
     * @memberof ProductContentRecommendationDto
     */
    block: ProductContentBlock;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductContentRecommendationStatus {
    GOOD = 'GOOD',
    BAD = 'BAD',
    SOSO = 'SOSO'
}

/**
 * 
 * @export
 * @interface ProductContentWrapper
 */
export interface ProductContentWrapper {
    /**
     * 
     * @type {ProductBlockContentWrapperStr}
     * @memberof ProductContentWrapper
     */
    block_name?: ProductBlockContentWrapperStr;
    /**
     * 
     * @type {ProductBlockContentWrapperProductDescriptionDto}
     * @memberof ProductContentWrapper
     */
    block_description?: ProductBlockContentWrapperProductDescriptionDto;
    /**
     * 
     * @type {ProductBlockContentWrapperProductContentImagesDto}
     * @memberof ProductContentWrapper
     */
    block_image?: ProductBlockContentWrapperProductContentImagesDto;
    /**
     * 
     * @type {ProductBlockContentWrapperListStr}
     * @memberof ProductContentWrapper
     */
    block_video?: ProductBlockContentWrapperListStr;
    /**
     * 
     * @type {ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity}
     * @memberof ProductContentWrapper
     */
    block_characteristics?: ProductBlockContentWrapperListAppDomainEntitiesProductContentProductAttributesEntity;
    /**
     * 
     * @type {ProductBlockContentWrapperListStr}
     * @memberof ProductContentWrapper
     */
    block_image360?: ProductBlockContentWrapperListStr;
    /**
     * 
     * @type {ProductBlockContentWrapperProductContentPriceDto}
     * @memberof ProductContentWrapper
     */
    block_price?: ProductBlockContentWrapperProductContentPriceDto;
    /**
     * 
     * @type {ProductBlockContentWrapperProductContentRatingReviewDto}
     * @memberof ProductContentWrapper
     */
    block_rating_review?: ProductBlockContentWrapperProductContentRatingReviewDto;
    /**
     * 
     * @type {ProductBlockContentWrapperListStr}
     * @memberof ProductContentWrapper
     */
    block_search_phrase?: ProductBlockContentWrapperListStr;
    /**
     * 
     * @type {ProductBlockContentWrapperListStr}
     * @memberof ProductContentWrapper
     */
    block_search_categories?: ProductBlockContentWrapperListStr;
}
/**
 * Сущность описания продукта.
 * @export
 * @interface ProductDescriptionDto
 */
export interface ProductDescriptionDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDescriptionDto
     */
    description?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductDescriptionDto
     */
    search_phrase?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof ProductDescriptionDto
     */
    found_phrases?: number;
}
/**
 * Бизнес-сущность продукта расширенная.
 * @export
 * @interface ProductEntity
 */
export interface ProductEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    dataMiningId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    name?: string;
    /**
     * 
     * @type {ShopEntity}
     * @memberof ProductEntity
     */
    shop?: ShopEntity;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    mainImage?: string;
    /**
     * 
     * @type {CategoryEntity}
     * @memberof ProductEntity
     */
    category?: CategoryEntity;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    type_sku?: string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductEntity
     */
    status?: ProductStatus;
    /**
     * 
     * @type {string}
     * @memberof ProductEntity
     */
    linkMarketplace?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductEntity
     */
    available?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductEntity
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    regularPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    stock?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    priceMarketplace?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    stockMarketplace?: number;
    /**
     * 
     * @type {Array<ProductIdsSkuEntity>}
     * @memberof ProductEntity
     */
    skus?: Array<ProductIdsSkuEntity>;
    /**
     * 
     * @type {ProductStatusGroup}
     * @memberof ProductEntity
     */
    statusGroup?: ProductStatusGroup;
    /**
     * 
     * @type {boolean}
     * @memberof ProductEntity
     */
    onSelling?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductEntity
     */
    requiresSellerAttention?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    ordersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    ordersMoney?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    redeemedMoney?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    redeemedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductEntity
     */
    position?: number;
}
/**
 * Бизнес-сущность фильтра продукта.
 * @export
 * @interface ProductFilter
 */
export interface ProductFilter {
    /**
     * 
     * @type {string}
     * @memberof ProductFilter
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFilter
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFilter
     */
    article?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilter
     */
    externalsIds?: Array<string>;
    /**
     * 
     * @type {Array<CategoryEntity>}
     * @memberof ProductFilter
     */
    categoryIds?: Array<CategoryEntity>;
    /**
     * 
     * @type {string}
     * @memberof ProductFilter
     */
    dateFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFilter
     */
    dateTo?: string;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    ordersMoney?: FilterItemComplex;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    ordersCount?: FilterItemComplex;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    redeemedMoney?: FilterItemComplex;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    redeemedCount?: FilterItemComplex;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    price?: FilterItemComplex;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    stocks?: FilterItemComplex;
    /**
     * 
     * @type {FilterItemComplex}
     * @memberof ProductFilter
     */
    position?: FilterItemComplex;
    /**
     * 
     * @type {boolean}
     * @memberof ProductFilter
     */
    inSale?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProductFilter
     */
    ids?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductFilter
     */
    skus?: Array<string>;
    /**
     * 
     * @type {Array<ProductAggrFieldType>}
     * @memberof ProductFilter
     */
    aggrFieldType?: Array<ProductAggrFieldType>;
}
/**
 * Бизнес-сущность продукта расширенная.
 * @export
 * @interface ProductFullEntity
 */
export interface ProductFullEntity {
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    productId?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    dataMiningId?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    externalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    article?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    vendorCode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    name?: string;
    /**
     * 
     * @type {ShopEntity}
     * @memberof ProductFullEntity
     */
    shop?: ShopEntity;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    mainImage?: string;
    /**
     * 
     * @type {CategoryEntity}
     * @memberof ProductFullEntity
     */
    category?: CategoryEntity;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    createdAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    type_sku?: string;
    /**
     * 
     * @type {ProductStatus}
     * @memberof ProductFullEntity
     */
    status?: ProductStatus;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    linkMarketplace?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProductFullEntity
     */
    available?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductFullEntity
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    regularPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    stock?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    priceMarketplace?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    stockMarketplace?: number;
    /**
     * 
     * @type {Array<ProductIdsSkuEntity>}
     * @memberof ProductFullEntity
     */
    skus?: Array<ProductIdsSkuEntity>;
    /**
     * 
     * @type {ProductStatusGroup}
     * @memberof ProductFullEntity
     */
    statusGroup?: ProductStatusGroup;
    /**
     * 
     * @type {boolean}
     * @memberof ProductFullEntity
     */
    onSelling?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductFullEntity
     */
    requiresSellerAttention?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    ordersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    ordersMoney?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    redeemedMoney?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    redeemedCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    position?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    rating?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductFullEntity
     */
    reviews?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductFullEntity
     */
    brand_name?: string;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductIdsSkuEntity
 */
export interface ProductIdsSkuEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductIdsSkuEntity
     */
    skuType?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductIdsSkuEntity
     */
    sku?: string;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductPositionEntity
 */
export interface ProductPositionEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductPositionEntity
     */
    field: string;
    /**
     * 
     * @type {SearchType}
     * @memberof ProductPositionEntity
     */
    searchType?: SearchType;
    /**
     * 
     * @type {CategoryEntity}
     * @memberof ProductPositionEntity
     */
    category?: CategoryEntity;
    /**
     * 
     * @type {number}
     * @memberof ProductPositionEntity
     */
    categoryProductCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductPositionEntity
     */
    productId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPositionEntity
     */
    type_sku?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductPositionEntity
     */
    queryPopularity?: number;
    /**
     * 
     * @type {SourceType}
     * @memberof ProductPositionEntity
     */
    dataSource?: SourceType;
    /**
     * 
     * @type {Array<PositionStatistic>}
     * @memberof ProductPositionEntity
     */
    positions: Array<PositionStatistic>;
}
/**
 * 
 * @export
 * @interface ProductSorterEntity
 */
export interface ProductSorterEntity {
    /**
     * 
     * @type {ProductSorterField}
     * @memberof ProductSorterEntity
     */
    field: ProductSorterField;
    /**
     * 
     * @type {SorterDirection}
     * @memberof ProductSorterEntity
     */
    direction?: SorterDirection;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductSorterField {
    PRODUCT_ID = 'productId',
    DATA_MINING_ID = 'dataMiningId',
    EXTERNAL_ID = 'externalId',
    ARTICLE = 'article',
    NAME = 'name',
    CREATED_AT = 'createdAt',
    UPDATED_AT = 'updatedAt',
    ORDERS_COUNT = 'ordersCount',
    ORDERS_MONEY = 'ordersMoney',
    ORDERS_SUMMA = 'ordersSumma',
    REDEEMED_MONEY = 'redeemedMoney',
    REDEEMED_COUNT = 'redeemedCount',
    STOCK = 'stock',
    POSITION = 'position',
    PRICE = 'price'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductStatus {
    ONLINE = 'ONLINE',
    UPDATE_PENDING = 'UPDATE_PENDING',
    ONLINE_AND_REFRESHING = 'ONLINE_AND_REFRESHING',
    UPDATE_REJECTED = 'UPDATE_REJECTED',
    NO_STOCK = 'NO_STOCK',
    OFFLINE = 'OFFLINE',
    CREATING = 'CREATING',
    CREATION_ERROR = 'CREATION_ERROR',
    AWAITING_MODERATION = 'AWAITING_MODERATION',
    REJECTED_BUT_FIXABLE = 'REJECTED_BUT_FIXABLE',
    REJECTED_CANT_SELL = 'REJECTED_CANT_SELL',
    BLOCKED = 'BLOCKED',
    DELETED = 'DELETED',
    UNKNOWN = 'UNKNOWN'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ProductStatusGroup {
    ONLINE = 'ONLINE',
    READY_FOR_SALE = 'READY_FOR_SALE',
    OFFLINE = 'OFFLINE',
    REJECTED_BY_MARKETPLACE = 'REJECTED_BY_MARKETPLACE',
    UNKNOWN = 'UNKNOWN'
}

/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface ProductStorageStatusEntity
 */
export interface ProductStorageStatusEntity {
    /**
     * 
     * @type {string}
     * @memberof ProductStorageStatusEntity
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductStorageStatusEntity
     */
    statusTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductStorageStatusEntity
     */
    onSelling?: string;
}
/**
 * 
 * @export
 * @interface RegexpSourceUrls
 */
export interface RegexpSourceUrls {
    /**
     * Список regexp для фильтрации source url
     * @type {Array<MpRegexpSourceUrl>}
     * @memberof RegexpSourceUrls
     */
    regexpUrls?: Array<MpRegexpSourceUrl>;
}
/**
 * 
 * @export
 * @interface ResponsePagingByPage
 */
export interface ResponsePagingByPage {
    /**
     * 
     * @type {number}
     * @memberof ResponsePagingByPage
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePagingByPage
     */
    limit?: number;
    /**
     * 
     * @type {number}
     * @memberof ResponsePagingByPage
     */
    total?: number;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<CategoryEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings
     */
    data?: Array<CategoryEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ConcurrentPriceHistoryEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings
     */
    data?: Array<ConcurrentPriceHistoryEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ConcurrentStockHistoryEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings
     */
    data?: Array<ConcurrentStockHistoryEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<MenuDto>}
     * @memberof ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings
     */
    data?: Array<MenuDto>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<MarketplaceEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings
     */
    data?: Array<MarketplaceEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ConcurrentPositionEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings
     */
    data?: Array<ConcurrentPositionEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ProductPositionEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings
     */
    data?: Array<ProductPositionEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<PriceHistoryEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings
     */
    data?: Array<PriceHistoryEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ProductAnalyticsEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings
     */
    data?: Array<ProductAnalyticsEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ConcurrentContentEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings
     */
    data?: Array<ConcurrentContentEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ProductEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings
     */
    data?: Array<ProductEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings
 */
export interface ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<StockHistoryEntity>}
     * @memberof ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings
     */
    data?: Array<StockHistoryEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings
 */
export interface ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<MpRegexpSourceUrl>}
     * @memberof ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings
     */
    data?: Array<MpRegexpSourceUrl>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperMarketplaceEntityBaseFrontSettings
 */
export interface ResponseWrapperMarketplaceEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {MarketplaceEntity}
     * @memberof ResponseWrapperMarketplaceEntityBaseFrontSettings
     */
    data?: MarketplaceEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperMarketplaceEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperMarketplaceEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperMarketplaceEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperMarketplaceEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperProductConcurrentCountEntityBaseFrontSettings
 */
export interface ResponseWrapperProductConcurrentCountEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {ProductConcurrentCountEntity}
     * @memberof ResponseWrapperProductConcurrentCountEntityBaseFrontSettings
     */
    data?: ProductConcurrentCountEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperProductConcurrentCountEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperProductConcurrentCountEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperProductConcurrentCountEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperProductConcurrentCountEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperProductConcurrentEntityBaseFrontSettings
 */
export interface ResponseWrapperProductConcurrentEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {ProductConcurrentEntity}
     * @memberof ResponseWrapperProductConcurrentEntityBaseFrontSettings
     */
    data?: ProductConcurrentEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperProductConcurrentEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperProductConcurrentEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperProductConcurrentEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperProductConcurrentEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperProductContentWrapperRegexpSourceUrls
 */
export interface ResponseWrapperProductContentWrapperRegexpSourceUrls {
    /**
     * Результат запроса (основные данные)
     * @type {ProductContentWrapper}
     * @memberof ResponseWrapperProductContentWrapperRegexpSourceUrls
     */
    data?: ProductContentWrapper;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperProductContentWrapperRegexpSourceUrls
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {RegexpSourceUrls}
     * @memberof ResponseWrapperProductContentWrapperRegexpSourceUrls
     */
    settings?: RegexpSourceUrls;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperProductContentWrapperRegexpSourceUrls
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperProductContentWrapperRegexpSourceUrls
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperProductFullEntityBaseFrontSettings
 */
export interface ResponseWrapperProductFullEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {ProductFullEntity}
     * @memberof ResponseWrapperProductFullEntityBaseFrontSettings
     */
    data?: ProductFullEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperProductFullEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperProductFullEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperProductFullEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperProductFullEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperSyncShopLogEntityBaseFrontSettings
 */
export interface ResponseWrapperSyncShopLogEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {SyncShopLogEntity}
     * @memberof ResponseWrapperSyncShopLogEntityBaseFrontSettings
     */
    data?: SyncShopLogEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperSyncShopLogEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperSyncShopLogEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperSyncShopLogEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperSyncShopLogEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked
 */
export interface ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ConcurrentIndicatorEntity>}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked
     */
    data?: Array<ConcurrentIndicatorEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {IndicatorChartChecked}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked
     */
    settings?: IndicatorChartChecked;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings
 */
export interface ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<IndicatorEntity>}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings
     */
    data?: Array<IndicatorEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked
 */
export interface ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked {
    /**
     * Результат запроса (основные данные)
     * @type {Array<IndicatorEntity>}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked
     */
    data?: Array<IndicatorEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {IndicatorChartChecked}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked
     */
    settings?: IndicatorChartChecked;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls
 */
export interface ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ProductConcurrentEntity>}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls
     */
    data?: Array<ProductConcurrentEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {RegexpSourceUrls}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls
     */
    settings?: RegexpSourceUrls;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings
 */
export interface ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {Array<ShopEntity>}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings
     */
    data?: Array<ShopEntity>;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings
 */
export interface ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {ProductConcurrentEntity}
     * @memberof ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings
     */
    data?: ProductConcurrentEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Бизнес-сущность объекта возврата результата сервисов.
 * @export
 * @interface ResponseWrapperUserEntityBaseFrontSettings
 */
export interface ResponseWrapperUserEntityBaseFrontSettings {
    /**
     * Результат запроса (основные данные)
     * @type {UserEntity}
     * @memberof ResponseWrapperUserEntityBaseFrontSettings
     */
    data?: UserEntity;
    /**
     * Признак успешности выполнения запроса
     * @type {boolean}
     * @memberof ResponseWrapperUserEntityBaseFrontSettings
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {object}
     * @memberof ResponseWrapperUserEntityBaseFrontSettings
     */
    settings?: object;
    /**
     * Объект пагинации (если предусмотрено запросом)
     * @type {ResponsePagingByPage}
     * @memberof ResponseWrapperUserEntityBaseFrontSettings
     */
    pagination?: ResponsePagingByPage;
    /**
     * Объект содержащий данные об ошибках в случае неудачного выполнения запроса
     * @type {ErrorWrapper}
     * @memberof ResponseWrapperUserEntityBaseFrontSettings
     */
    error?: ErrorWrapper;
}
/**
 * Список возможных типов ошибок.
 * @export
 * @enum {string}
 */

export enum SearchType {
    CATEGORY = 'CATEGORY',
    SEARCH = 'SEARCH'
}

/**
 * Бизнес-сущность магазина.
 * @export
 * @interface ShopEntity
 */
export interface ShopEntity {
    /**
     * 
     * @type {number}
     * @memberof ShopEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopEntity
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopEntity
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopEntity
     */
    isSuperstore?: boolean;
    /**
     * 
     * @type {MarketplaceEntity}
     * @memberof ShopEntity
     */
    marketplace?: MarketplaceEntity;
    /**
     * 
     * @type {number}
     * @memberof ShopEntity
     */
    productCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopEntity
     */
    createdAt?: string;
}
/**
 * Бизнес-сущность магазина (полная).
 * @export
 * @interface ShopFullEntity
 */
export interface ShopFullEntity {
    /**
     * 
     * @type {string}
     * @memberof ShopFullEntity
     */
    ozonKey?: string;
    /**
     * 
     * @type {string}
     * @memberof ShopFullEntity
     */
    ozonClientId?: string;
    /**
     * 
     * @type {number}
     * @memberof ShopFullEntity
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopFullEntity
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ShopFullEntity
     */
    isActive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ShopFullEntity
     */
    isSuperstore?: boolean;
    /**
     * 
     * @type {MarketplaceEntity}
     * @memberof ShopFullEntity
     */
    marketplace?: MarketplaceEntity;
    /**
     * 
     * @type {number}
     * @memberof ShopFullEntity
     */
    productCount?: number;
    /**
     * 
     * @type {string}
     * @memberof ShopFullEntity
     */
    createdAt?: string;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ShopSyncStatus {
    NEW = 'NEW',
    FOR_UPDATE = 'FOR_UPDATE',
    DONE = 'DONE',
    ERROR = 'ERROR',
    INVALID_TOKEN = 'INVALID_TOKEN',
    DEACTIVATED = 'DEACTIVATED'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum ShopSyncType {
    SKU = 'GET_SKU',
    ANALYTICS_INDICATOR = 'GET_ANALYTICS_INDICATOR'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SorterDirection {
    ASC = 'ASC',
    DESC = 'DESC'
}

/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum SourceType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    UNKNOWN = 'UNKNOWN'
}

/**
 * 
 * @export
 * @interface StockHistoryEntity
 */
export interface StockHistoryEntity {
    /**
     * 
     * @type {StockHistoryFieldType}
     * @memberof StockHistoryEntity
     */
    field: StockHistoryFieldType;
    /**
     * 
     * @type {Warehouse}
     * @memberof StockHistoryEntity
     */
    warehouse?: Warehouse;
    /**
     * 
     * @type {string}
     * @memberof StockHistoryEntity
     */
    product_id?: string;
    /**
     * 
     * @type {SourceType}
     * @memberof StockHistoryEntity
     */
    dataSource?: SourceType;
    /**
     * 
     * @type {Array<HistoryEntity>}
     * @memberof StockHistoryEntity
     */
    history: Array<HistoryEntity>;
}
/**
 * An enumeration.
 * @export
 * @enum {string}
 */

export enum StockHistoryFieldType {
    STOCK = 'STOCK'
}

/**
 * Бизнес-сущность текущей подписки.
 * @export
 * @interface SubscriptionEntity
 */
export interface SubscriptionEntity {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionEntity
     */
    startedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionEntity
     */
    expiresAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionEntity
     */
    days_left?: number;
    /**
     * 
     * @type {TariffEntity}
     * @memberof SubscriptionEntity
     */
    tariff?: TariffEntity;
}
/**
 * Sync shop log entity.
 * @export
 * @interface SyncShopLogEntity
 */
export interface SyncShopLogEntity {
    /**
     * 
     * @type {number}
     * @memberof SyncShopLogEntity
     */
    shop_id: number;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogEntity
     */
    shop_name?: string;
    /**
     * 
     * @type {ShopSyncType}
     * @memberof SyncShopLogEntity
     */
    type: ShopSyncType;
    /**
     * 
     * @type {number}
     * @memberof SyncShopLogEntity
     */
    run_every: number;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogEntity
     */
    marketplace_code: string;
    /**
     * 
     * @type {Array<SyncShopLogError>}
     * @memberof SyncShopLogEntity
     */
    errors?: Array<SyncShopLogError>;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogEntity
     */
    last_error_at?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncShopLogEntity
     */
    rerun_count?: number;
    /**
     * 
     * @type {ShopSyncStatus}
     * @memberof SyncShopLogEntity
     */
    status: ShopSyncStatus;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogEntity
     */
    task_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogEntity
     */
    updated_at?: string;
    /**
     * 
     * @type {number}
     * @memberof SyncShopLogEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogEntity
     */
    created_at: string;
}
/**
 * Общая бизнес сущность для всех entity.
 * @export
 * @interface SyncShopLogError
 */
export interface SyncShopLogError {
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogError
     */
    task_id?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogError
     */
    exception: string;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogError
     */
    traceback?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogError
     */
    details?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncShopLogError
     */
    timestamp: string;
}
/**
 * Бизнес-сущность тарифа.
 * @export
 * @interface TariffEntity
 */
export interface TariffEntity {
    /**
     * 
     * @type {number}
     * @memberof TariffEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TariffEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TariffEntity
     */
    key?: string;
    /**
     * 
     * @type {number}
     * @memberof TariffEntity
     */
    offers_limit?: number;
}
/**
 * Бизнес-сущность пользователя (клиента).
 * @export
 * @interface UserEntity
 */
export interface UserEntity {
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    fullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserEntity
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<ShopEntity>}
     * @memberof UserEntity
     */
    shops?: Array<ShopEntity>;
    /**
     * 
     * @type {ClientEntity}
     * @memberof UserEntity
     */
    client?: ClientEntity;
    /**
     * 
     * @type {TariffEntity}
     * @memberof UserEntity
     */
    tariff?: TariffEntity;
    /**
     * 
     * @type {SubscriptionEntity}
     * @memberof UserEntity
     */
    subscription?: SubscriptionEntity;
    /**
     * 
     * @type {boolean}
     * @memberof UserEntity
     */
    hasDueBill?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserEntity
     */
    subscriptionTerm?: number;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<string>}
     * @memberof ValidationError
     */
    loc: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    msg: string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    type: string;
}
/**
 * 
 * @export
 * @interface Warehouse
 */
export interface Warehouse {
    /**
     * 
     * @type {number}
     * @memberof Warehouse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Warehouse
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof Warehouse
     */
    mp_owner?: boolean;
}

/**
 * ClientApi - axios parameter creator
 * @export
 */
export const ClientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Получение профиля пользователя по его ID.
         * @summary Users:Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileV1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/clients/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClientApi - functional programming interface
 * @export
 */
export const ClientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ClientApiAxiosParamCreator(configuration)
    return {
        /**
         * Получение профиля пользователя по его ID.
         * @summary Users:Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfileV1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUserEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfileV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ClientApi - factory interface
 * @export
 */
export const ClientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ClientApiFp(configuration)
    return {
        /**
         * Получение профиля пользователя по его ID.
         * @summary Users:Profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfileV1(options?: any): AxiosPromise<ResponseWrapperUserEntityBaseFrontSettings> {
            return localVarFp.getProfileV1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ClientApi - object-oriented interface
 * @export
 * @class ClientApi
 * @extends {BaseAPI}
 */
export class ClientApi extends BaseAPI {
    /**
     * Получение профиля пользователя по его ID.
     * @summary Users:Profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClientApi
     */
    public getProfileV1(options?: any) {
        return ClientApiFp(this.configuration).getProfileV1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConcurrentApi - axios parameter creator
 * @export
 */
export const ConcurrentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Add Concurrent
         * @param {number} productId 
         * @param {string} sourceUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConcurrentV1: async (productId: number, sourceUrl: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('addConcurrentV1', 'productId', productId)
            // verify required parameter 'sourceUrl' is not null or undefined
            assertParamExists('addConcurrentV1', 'sourceUrl', sourceUrl)
            const localVarPath = `/api/v1/products/{product_id}/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sourceUrl !== undefined) {
                localVarQueryParameter['sourceUrl'] = sourceUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Concurrent
         * @param {number} productId 
         * @param {number} concurrentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConcurrentV1: async (productId: number, concurrentId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('deleteConcurrentV1', 'productId', productId)
            // verify required parameter 'concurrentId' is not null or undefined
            assertParamExists('deleteConcurrentV1', 'concurrentId', concurrentId)
            const localVarPath = `/api/v1/products/{product_id}/concurrent/{concurrent_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"concurrent_id"}}`, encodeURIComponent(String(concurrentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Concurrent
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentV1: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getConcurrentV1', 'productId', productId)
            const localVarPath = `/api/v1/products/{product_id}/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Concurrent
         * @param {number} productId 
         * @param {number} concurrentId 
         * @param {string} sourceUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConcurrentV1: async (productId: number, concurrentId: number, sourceUrl: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('updateConcurrentV1', 'productId', productId)
            // verify required parameter 'concurrentId' is not null or undefined
            assertParamExists('updateConcurrentV1', 'concurrentId', concurrentId)
            // verify required parameter 'sourceUrl' is not null or undefined
            assertParamExists('updateConcurrentV1', 'sourceUrl', sourceUrl)
            const localVarPath = `/api/v1/products/{product_id}/concurrent/{concurrent_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)))
                .replace(`{${"concurrent_id"}}`, encodeURIComponent(String(concurrentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sourceUrl !== undefined) {
                localVarQueryParameter['sourceUrl'] = sourceUrl;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConcurrentApi - functional programming interface
 * @export
 */
export const ConcurrentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConcurrentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Add Concurrent
         * @param {number} productId 
         * @param {string} sourceUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addConcurrentV1(productId: number, sourceUrl: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperProductConcurrentEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addConcurrentV1(productId, sourceUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete Concurrent
         * @param {number} productId 
         * @param {number} concurrentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConcurrentV1(productId: number, concurrentId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConcurrentV1(productId, concurrentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Concurrent
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConcurrentV1(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConcurrentV1(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Concurrent
         * @param {number} productId 
         * @param {number} concurrentId 
         * @param {string} sourceUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConcurrentV1(productId: number, concurrentId: number, sourceUrl: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperProductConcurrentEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConcurrentV1(productId, concurrentId, sourceUrl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConcurrentApi - factory interface
 * @export
 */
export const ConcurrentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConcurrentApiFp(configuration)
    return {
        /**
         * 
         * @summary Add Concurrent
         * @param {number} productId 
         * @param {string} sourceUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addConcurrentV1(productId: number, sourceUrl: string, options?: any): AxiosPromise<ResponseWrapperProductConcurrentEntityBaseFrontSettings> {
            return localVarFp.addConcurrentV1(productId, sourceUrl, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Concurrent
         * @param {number} productId 
         * @param {number} concurrentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConcurrentV1(productId: number, concurrentId: number, options?: any): AxiosPromise<ResponseWrapperUnionProductConcurrentEntityNoneTypeBaseFrontSettings> {
            return localVarFp.deleteConcurrentV1(productId, concurrentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Concurrent
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentV1(productId: number, options?: any): AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesProductConcurrentEntityProductConcurrentEntityNoneTypeRegexpSourceUrls> {
            return localVarFp.getConcurrentV1(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Concurrent
         * @param {number} productId 
         * @param {number} concurrentId 
         * @param {string} sourceUrl 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConcurrentV1(productId: number, concurrentId: number, sourceUrl: string, options?: any): AxiosPromise<ResponseWrapperProductConcurrentEntityBaseFrontSettings> {
            return localVarFp.updateConcurrentV1(productId, concurrentId, sourceUrl, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for addConcurrentV1 operation in ConcurrentApi.
 * @export
 * @interface ConcurrentApiAddConcurrentV1Request
 */
export interface ConcurrentApiAddConcurrentV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentApiAddConcurrentV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentApiAddConcurrentV1
     */
    readonly sourceUrl: string
}

/**
 * Request parameters for deleteConcurrentV1 operation in ConcurrentApi.
 * @export
 * @interface ConcurrentApiDeleteConcurrentV1Request
 */
export interface ConcurrentApiDeleteConcurrentV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentApiDeleteConcurrentV1
     */
    readonly productId: number

    /**
     * 
     * @type {number}
     * @memberof ConcurrentApiDeleteConcurrentV1
     */
    readonly concurrentId: number
}

/**
 * Request parameters for getConcurrentV1 operation in ConcurrentApi.
 * @export
 * @interface ConcurrentApiGetConcurrentV1Request
 */
export interface ConcurrentApiGetConcurrentV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentApiGetConcurrentV1
     */
    readonly productId: number
}

/**
 * Request parameters for updateConcurrentV1 operation in ConcurrentApi.
 * @export
 * @interface ConcurrentApiUpdateConcurrentV1Request
 */
export interface ConcurrentApiUpdateConcurrentV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentApiUpdateConcurrentV1
     */
    readonly productId: number

    /**
     * 
     * @type {number}
     * @memberof ConcurrentApiUpdateConcurrentV1
     */
    readonly concurrentId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentApiUpdateConcurrentV1
     */
    readonly sourceUrl: string
}

/**
 * ConcurrentApi - object-oriented interface
 * @export
 * @class ConcurrentApi
 * @extends {BaseAPI}
 */
export class ConcurrentApi extends BaseAPI {
    /**
     * 
     * @summary Add Concurrent
     * @param {ConcurrentApiAddConcurrentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentApi
     */
    public addConcurrentV1(requestParameters: ConcurrentApiAddConcurrentV1Request, options?: any) {
        return ConcurrentApiFp(this.configuration).addConcurrentV1(requestParameters.productId, requestParameters.sourceUrl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Concurrent
     * @param {ConcurrentApiDeleteConcurrentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentApi
     */
    public deleteConcurrentV1(requestParameters: ConcurrentApiDeleteConcurrentV1Request, options?: any) {
        return ConcurrentApiFp(this.configuration).deleteConcurrentV1(requestParameters.productId, requestParameters.concurrentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Concurrent
     * @param {ConcurrentApiGetConcurrentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentApi
     */
    public getConcurrentV1(requestParameters: ConcurrentApiGetConcurrentV1Request, options?: any) {
        return ConcurrentApiFp(this.configuration).getConcurrentV1(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Concurrent
     * @param {ConcurrentApiUpdateConcurrentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentApi
     */
    public updateConcurrentV1(requestParameters: ConcurrentApiUpdateConcurrentV1Request, options?: any) {
        return ConcurrentApiFp(this.configuration).updateConcurrentV1(requestParameters.productId, requestParameters.concurrentId, requestParameters.sourceUrl, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConcurrentContentApi - axios parameter creator
 * @export
 */
export const ConcurrentContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Concurrent Content
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentContentV1: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('concurrentContentV1', 'productId', productId)
            const localVarPath = `/api/v1/products/{product_id}/content/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConcurrentContentApi - functional programming interface
 * @export
 */
export const ConcurrentContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConcurrentContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Concurrent Content
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concurrentContentV1(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concurrentContentV1(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConcurrentContentApi - factory interface
 * @export
 */
export const ConcurrentContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConcurrentContentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Concurrent Content
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentContentV1(productId: number, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesProductContentConcurrentContentEntityBaseFrontSettings> {
            return localVarFp.concurrentContentV1(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for concurrentContentV1 operation in ConcurrentContentApi.
 * @export
 * @interface ConcurrentContentApiConcurrentContentV1Request
 */
export interface ConcurrentContentApiConcurrentContentV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentContentApiConcurrentContentV1
     */
    readonly productId: number
}

/**
 * ConcurrentContentApi - object-oriented interface
 * @export
 * @class ConcurrentContentApi
 * @extends {BaseAPI}
 */
export class ConcurrentContentApi extends BaseAPI {
    /**
     * 
     * @summary Get Concurrent Content
     * @param {ConcurrentContentApiConcurrentContentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentContentApi
     */
    public concurrentContentV1(requestParameters: ConcurrentContentApiConcurrentContentV1Request, options?: any) {
        return ConcurrentContentApiFp(this.configuration).concurrentContentV1(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConcurrentIndicatorApi - axios parameter creator
 * @export
 */
export const ConcurrentIndicatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Indicators:Concurrent:Download Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentIndicatorDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('concurrentIndicatorDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('concurrentIndicatorDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('concurrentIndicatorDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/indicator/concurrent/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Indicators:Concurrent:Get Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentIndicatorV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getConcurrentIndicatorV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getConcurrentIndicatorV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getConcurrentIndicatorV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/indicator/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConcurrentIndicatorApi - functional programming interface
 * @export
 */
export const ConcurrentIndicatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConcurrentIndicatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Indicators:Concurrent:Download Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concurrentIndicatorDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concurrentIndicatorDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Indicators:Concurrent:Get Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConcurrentIndicatorV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConcurrentIndicatorV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConcurrentIndicatorApi - factory interface
 * @export
 */
export const ConcurrentIndicatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConcurrentIndicatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Indicators:Concurrent:Download Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentIndicatorDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.concurrentIndicatorDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Indicators:Concurrent:Get Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentIndicatorV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesConcurrentIndicatorEntityConcurrentIndicatorEntityNoneTypeIndicatorChartChecked> {
            return localVarFp.getConcurrentIndicatorV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for concurrentIndicatorDownloadV1 operation in ConcurrentIndicatorApi.
 * @export
 * @interface ConcurrentIndicatorApiConcurrentIndicatorDownloadV1Request
 */
export interface ConcurrentIndicatorApiConcurrentIndicatorDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentIndicatorApiConcurrentIndicatorDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentIndicatorApiConcurrentIndicatorDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ConcurrentIndicatorApiConcurrentIndicatorDownloadV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for getConcurrentIndicatorV1 operation in ConcurrentIndicatorApi.
 * @export
 * @interface ConcurrentIndicatorApiGetConcurrentIndicatorV1Request
 */
export interface ConcurrentIndicatorApiGetConcurrentIndicatorV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentIndicatorApiGetConcurrentIndicatorV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentIndicatorApiGetConcurrentIndicatorV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ConcurrentIndicatorApiGetConcurrentIndicatorV1
     */
    readonly dateTo: string
}

/**
 * ConcurrentIndicatorApi - object-oriented interface
 * @export
 * @class ConcurrentIndicatorApi
 * @extends {BaseAPI}
 */
export class ConcurrentIndicatorApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Indicators:Concurrent:Download Indicators
     * @param {ConcurrentIndicatorApiConcurrentIndicatorDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentIndicatorApi
     */
    public concurrentIndicatorDownloadV1(requestParameters: ConcurrentIndicatorApiConcurrentIndicatorDownloadV1Request, options?: any) {
        return ConcurrentIndicatorApiFp(this.configuration).concurrentIndicatorDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Indicators:Concurrent:Get Indicators
     * @param {ConcurrentIndicatorApiGetConcurrentIndicatorV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentIndicatorApi
     */
    public getConcurrentIndicatorV1(requestParameters: ConcurrentIndicatorApiGetConcurrentIndicatorV1Request, options?: any) {
        return ConcurrentIndicatorApiFp(this.configuration).getConcurrentIndicatorV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConcurrentPriceHistoryApi - axios parameter creator
 * @export
 */
export const ConcurrentPriceHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Price History:Concurrent:Download Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentPriceHistoryDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('concurrentPriceHistoryDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('concurrentPriceHistoryDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('concurrentPriceHistoryDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/price_history/concurrent/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Price History:Concurrent:Get Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentPriceHistoryV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getConcurrentPriceHistoryV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getConcurrentPriceHistoryV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getConcurrentPriceHistoryV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/price_history/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConcurrentPriceHistoryApi - functional programming interface
 * @export
 */
export const ConcurrentPriceHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConcurrentPriceHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Price History:Concurrent:Download Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concurrentPriceHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concurrentPriceHistoryDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Price History:Concurrent:Get Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConcurrentPriceHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConcurrentPriceHistoryV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConcurrentPriceHistoryApi - factory interface
 * @export
 */
export const ConcurrentPriceHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConcurrentPriceHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Price History:Concurrent:Download Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentPriceHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.concurrentPriceHistoryDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Price History:Concurrent:Get Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentPriceHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesConcurrentPriceHistoryEntityConcurrentPriceHistoryEntityBaseFrontSettings> {
            return localVarFp.getConcurrentPriceHistoryV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for concurrentPriceHistoryDownloadV1 operation in ConcurrentPriceHistoryApi.
 * @export
 * @interface ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1Request
 */
export interface ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for getConcurrentPriceHistoryV1 operation in ConcurrentPriceHistoryApi.
 * @export
 * @interface ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1Request
 */
export interface ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1
     */
    readonly dateTo: string
}

/**
 * ConcurrentPriceHistoryApi - object-oriented interface
 * @export
 * @class ConcurrentPriceHistoryApi
 * @extends {BaseAPI}
 */
export class ConcurrentPriceHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Price History:Concurrent:Download Price History
     * @param {ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentPriceHistoryApi
     */
    public concurrentPriceHistoryDownloadV1(requestParameters: ConcurrentPriceHistoryApiConcurrentPriceHistoryDownloadV1Request, options?: any) {
        return ConcurrentPriceHistoryApiFp(this.configuration).concurrentPriceHistoryDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Price History:Concurrent:Get Price History
     * @param {ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentPriceHistoryApi
     */
    public getConcurrentPriceHistoryV1(requestParameters: ConcurrentPriceHistoryApiGetConcurrentPriceHistoryV1Request, options?: any) {
        return ConcurrentPriceHistoryApiFp(this.configuration).getConcurrentPriceHistoryV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConcurrentStockHistoryApi - axios parameter creator
 * @export
 */
export const ConcurrentStockHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Stock History:Concurrent:Download Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentStockHistoryDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('concurrentStockHistoryDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('concurrentStockHistoryDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('concurrentStockHistoryDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/stock_history/concurrent/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Stock History:Concurrent:Get Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentStockHistoryV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getConcurrentStockHistoryV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getConcurrentStockHistoryV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getConcurrentStockHistoryV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/stock_history/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConcurrentStockHistoryApi - functional programming interface
 * @export
 */
export const ConcurrentStockHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConcurrentStockHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Stock History:Concurrent:Download Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concurrentStockHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concurrentStockHistoryDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Stock History:Concurrent:Get Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConcurrentStockHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConcurrentStockHistoryV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConcurrentStockHistoryApi - factory interface
 * @export
 */
export const ConcurrentStockHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConcurrentStockHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Stock History:Concurrent:Download Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentStockHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.concurrentStockHistoryDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Stock History:Concurrent:Get Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConcurrentStockHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesConcurrentStockHistoryEntityConcurrentStockHistoryEntityBaseFrontSettings> {
            return localVarFp.getConcurrentStockHistoryV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for concurrentStockHistoryDownloadV1 operation in ConcurrentStockHistoryApi.
 * @export
 * @interface ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1Request
 */
export interface ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for getConcurrentStockHistoryV1 operation in ConcurrentStockHistoryApi.
 * @export
 * @interface ConcurrentStockHistoryApiGetConcurrentStockHistoryV1Request
 */
export interface ConcurrentStockHistoryApiGetConcurrentStockHistoryV1Request {
    /**
     * 
     * @type {number}
     * @memberof ConcurrentStockHistoryApiGetConcurrentStockHistoryV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof ConcurrentStockHistoryApiGetConcurrentStockHistoryV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ConcurrentStockHistoryApiGetConcurrentStockHistoryV1
     */
    readonly dateTo: string
}

/**
 * ConcurrentStockHistoryApi - object-oriented interface
 * @export
 * @class ConcurrentStockHistoryApi
 * @extends {BaseAPI}
 */
export class ConcurrentStockHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Stock History:Concurrent:Download Stock History
     * @param {ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentStockHistoryApi
     */
    public concurrentStockHistoryDownloadV1(requestParameters: ConcurrentStockHistoryApiConcurrentStockHistoryDownloadV1Request, options?: any) {
        return ConcurrentStockHistoryApiFp(this.configuration).concurrentStockHistoryDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Stock History:Concurrent:Get Stock History
     * @param {ConcurrentStockHistoryApiGetConcurrentStockHistoryV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConcurrentStockHistoryApi
     */
    public getConcurrentStockHistoryV1(requestParameters: ConcurrentStockHistoryApiGetConcurrentStockHistoryV1Request, options?: any) {
        return ConcurrentStockHistoryApiFp(this.configuration).getConcurrentStockHistoryV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Product Content
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productContentV1: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('productContentV1', 'productId', productId)
            const localVarPath = `/api/v1/products/{product_id}/content`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Product Content
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productContentV1(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperProductContentWrapperRegexpSourceUrls>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productContentV1(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Product Content
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productContentV1(productId: number, options?: any): AxiosPromise<ResponseWrapperProductContentWrapperRegexpSourceUrls> {
            return localVarFp.productContentV1(productId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productContentV1 operation in ContentApi.
 * @export
 * @interface ContentApiProductContentV1Request
 */
export interface ContentApiProductContentV1Request {
    /**
     * 
     * @type {number}
     * @memberof ContentApiProductContentV1
     */
    readonly productId: number
}

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 
     * @summary Get Product Content
     * @param {ContentApiProductContentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public productContentV1(requestParameters: ContentApiProductContentV1Request, options?: any) {
        return ContentApiFp(this.configuration).productContentV1(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CoreApi - axios parameter creator
 * @export
 */
export const CoreApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Menu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoreMenuV1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/core/menu`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Regexp For Mp Link
         * @param {string} [mpCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegexpForMpLinkV1: async (mpCode?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/core/get_regexp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (mpCode !== undefined) {
                localVarQueryParameter['mp_code'] = mpCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoreApi - functional programming interface
 * @export
 */
export const CoreApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Menu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCoreMenuV1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCoreMenuV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Regexp For Mp Link
         * @param {string} [mpCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRegexpForMpLinkV1(mpCode?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRegexpForMpLinkV1(mpCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CoreApi - factory interface
 * @export
 */
export const CoreApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Menu
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCoreMenuV1(options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesDtosMenuEntityDtoMenuDtoBaseFrontSettings> {
            return localVarFp.getCoreMenuV1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Regexp For Mp Link
         * @param {string} [mpCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRegexpForMpLinkV1(mpCode?: string, options?: any): AxiosPromise<ResponseWrapperListAppRestDtosSettingsConcurrentRegexpUrlsMpRegexpSourceUrlBaseFrontSettings> {
            return localVarFp.getRegexpForMpLinkV1(mpCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getRegexpForMpLinkV1 operation in CoreApi.
 * @export
 * @interface CoreApiGetRegexpForMpLinkV1Request
 */
export interface CoreApiGetRegexpForMpLinkV1Request {
    /**
     * 
     * @type {string}
     * @memberof CoreApiGetRegexpForMpLinkV1
     */
    readonly mpCode?: string
}

/**
 * CoreApi - object-oriented interface
 * @export
 * @class CoreApi
 * @extends {BaseAPI}
 */
export class CoreApi extends BaseAPI {
    /**
     * 
     * @summary Get Menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getCoreMenuV1(options?: any) {
        return CoreApiFp(this.configuration).getCoreMenuV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Regexp For Mp Link
     * @param {CoreApiGetRegexpForMpLinkV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreApi
     */
    public getRegexpForMpLinkV1(requestParameters: CoreApiGetRegexpForMpLinkV1Request = {}, options?: any) {
        return CoreApiFp(this.configuration).getRegexpForMpLinkV1(requestParameters.mpCode, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IndicatorApi - axios parameter creator
 * @export
 */
export const IndicatorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Indicators:Get Comparison
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicatorComparisonV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getIndicatorComparisonV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getIndicatorComparisonV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getIndicatorComparisonV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/indicator/comparison`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Indicators:Get Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicatorV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getIndicatorV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getIndicatorV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getIndicatorV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/indicator`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Indicators:Download Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indicatorDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('indicatorDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('indicatorDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('indicatorDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/indicator/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IndicatorApi - functional programming interface
 * @export
 */
export const IndicatorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IndicatorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Indicators:Get Comparison
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndicatorComparisonV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicatorComparisonV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Indicators:Get Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndicatorV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicatorV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Indicators:Download Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async indicatorDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.indicatorDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IndicatorApi - factory interface
 * @export
 */
export const IndicatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IndicatorApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Indicators:Get Comparison
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicatorComparisonV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeBaseFrontSettings> {
            return localVarFp.getIndicatorComparisonV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Indicators:Get Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicatorV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesIndicatorEntityIndicatorEntityNoneTypeIndicatorChartChecked> {
            return localVarFp.getIndicatorV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Indicators:Download Indicators
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        indicatorDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.indicatorDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getIndicatorComparisonV1 operation in IndicatorApi.
 * @export
 * @interface IndicatorApiGetIndicatorComparisonV1Request
 */
export interface IndicatorApiGetIndicatorComparisonV1Request {
    /**
     * 
     * @type {number}
     * @memberof IndicatorApiGetIndicatorComparisonV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof IndicatorApiGetIndicatorComparisonV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof IndicatorApiGetIndicatorComparisonV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for getIndicatorV1 operation in IndicatorApi.
 * @export
 * @interface IndicatorApiGetIndicatorV1Request
 */
export interface IndicatorApiGetIndicatorV1Request {
    /**
     * 
     * @type {number}
     * @memberof IndicatorApiGetIndicatorV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof IndicatorApiGetIndicatorV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof IndicatorApiGetIndicatorV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for indicatorDownloadV1 operation in IndicatorApi.
 * @export
 * @interface IndicatorApiIndicatorDownloadV1Request
 */
export interface IndicatorApiIndicatorDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof IndicatorApiIndicatorDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof IndicatorApiIndicatorDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof IndicatorApiIndicatorDownloadV1
     */
    readonly dateTo: string
}

/**
 * IndicatorApi - object-oriented interface
 * @export
 * @class IndicatorApi
 * @extends {BaseAPI}
 */
export class IndicatorApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Indicators:Get Comparison
     * @param {IndicatorApiGetIndicatorComparisonV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicatorApi
     */
    public getIndicatorComparisonV1(requestParameters: IndicatorApiGetIndicatorComparisonV1Request, options?: any) {
        return IndicatorApiFp(this.configuration).getIndicatorComparisonV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Indicators:Get Indicators
     * @param {IndicatorApiGetIndicatorV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicatorApi
     */
    public getIndicatorV1(requestParameters: IndicatorApiGetIndicatorV1Request, options?: any) {
        return IndicatorApiFp(this.configuration).getIndicatorV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Indicators:Download Indicators
     * @param {IndicatorApiIndicatorDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IndicatorApi
     */
    public indicatorDownloadV1(requestParameters: IndicatorApiIndicatorDownloadV1Request, options?: any) {
        return IndicatorApiFp(this.configuration).indicatorDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * MarketplaceApi - axios parameter creator
 * @export
 */
export const MarketplaceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Marketplace:Get Marketplace By Code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpByCodeV1: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('getMpByCodeV1', 'code', code)
            const localVarPath = `/api/v1/marketplaces/by_code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marketplace:Get Categories
         * @param {number} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpCategoriesV1: async (id: number, query: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMpCategoriesV1', 'id', id)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getMpCategoriesV1', 'query', query)
            const localVarPath = `/api/v1/marketplaces/{id}/categories`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marketplace:Get Marketplaces
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpListV1: async (name?: string, code?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/marketplaces/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Marketplace:Get Marketplace By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpV1: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getMpV1', 'id', id)
            const localVarPath = `/api/v1/marketplaces/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketplaceApi - functional programming interface
 * @export
 */
export const MarketplaceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MarketplaceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Marketplace:Get Marketplace By Code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMpByCodeV1(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperMarketplaceEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMpByCodeV1(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marketplace:Get Categories
         * @param {number} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMpCategoriesV1(id: number, query: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMpCategoriesV1(id, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marketplace:Get Marketplaces
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMpListV1(name?: string, code?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMpListV1(name, code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Marketplace:Get Marketplace By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMpV1(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperMarketplaceEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMpV1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MarketplaceApi - factory interface
 * @export
 */
export const MarketplaceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MarketplaceApiFp(configuration)
    return {
        /**
         * 
         * @summary Marketplace:Get Marketplace By Code
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpByCodeV1(code: string, options?: any): AxiosPromise<ResponseWrapperMarketplaceEntityBaseFrontSettings> {
            return localVarFp.getMpByCodeV1(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marketplace:Get Categories
         * @param {number} id 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpCategoriesV1(id: number, query: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesCategoryEntityCategoryEntityBaseFrontSettings> {
            return localVarFp.getMpCategoriesV1(id, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marketplace:Get Marketplaces
         * @param {string} [name] 
         * @param {string} [code] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpListV1(name?: string, code?: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesMarketpaceEntityMarketplaceEntityBaseFrontSettings> {
            return localVarFp.getMpListV1(name, code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Marketplace:Get Marketplace By Id
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMpV1(id: number, options?: any): AxiosPromise<ResponseWrapperMarketplaceEntityBaseFrontSettings> {
            return localVarFp.getMpV1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getMpByCodeV1 operation in MarketplaceApi.
 * @export
 * @interface MarketplaceApiGetMpByCodeV1Request
 */
export interface MarketplaceApiGetMpByCodeV1Request {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceApiGetMpByCodeV1
     */
    readonly code: string
}

/**
 * Request parameters for getMpCategoriesV1 operation in MarketplaceApi.
 * @export
 * @interface MarketplaceApiGetMpCategoriesV1Request
 */
export interface MarketplaceApiGetMpCategoriesV1Request {
    /**
     * 
     * @type {number}
     * @memberof MarketplaceApiGetMpCategoriesV1
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof MarketplaceApiGetMpCategoriesV1
     */
    readonly query: string
}

/**
 * Request parameters for getMpListV1 operation in MarketplaceApi.
 * @export
 * @interface MarketplaceApiGetMpListV1Request
 */
export interface MarketplaceApiGetMpListV1Request {
    /**
     * 
     * @type {string}
     * @memberof MarketplaceApiGetMpListV1
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof MarketplaceApiGetMpListV1
     */
    readonly code?: string
}

/**
 * Request parameters for getMpV1 operation in MarketplaceApi.
 * @export
 * @interface MarketplaceApiGetMpV1Request
 */
export interface MarketplaceApiGetMpV1Request {
    /**
     * 
     * @type {number}
     * @memberof MarketplaceApiGetMpV1
     */
    readonly id: number
}

/**
 * MarketplaceApi - object-oriented interface
 * @export
 * @class MarketplaceApi
 * @extends {BaseAPI}
 */
export class MarketplaceApi extends BaseAPI {
    /**
     * 
     * @summary Marketplace:Get Marketplace By Code
     * @param {MarketplaceApiGetMpByCodeV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getMpByCodeV1(requestParameters: MarketplaceApiGetMpByCodeV1Request, options?: any) {
        return MarketplaceApiFp(this.configuration).getMpByCodeV1(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marketplace:Get Categories
     * @param {MarketplaceApiGetMpCategoriesV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getMpCategoriesV1(requestParameters: MarketplaceApiGetMpCategoriesV1Request, options?: any) {
        return MarketplaceApiFp(this.configuration).getMpCategoriesV1(requestParameters.id, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marketplace:Get Marketplaces
     * @param {MarketplaceApiGetMpListV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getMpListV1(requestParameters: MarketplaceApiGetMpListV1Request = {}, options?: any) {
        return MarketplaceApiFp(this.configuration).getMpListV1(requestParameters.name, requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Marketplace:Get Marketplace By Id
     * @param {MarketplaceApiGetMpV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketplaceApi
     */
    public getMpV1(requestParameters: MarketplaceApiGetMpV1Request, options?: any) {
        return MarketplaceApiFp(this.configuration).getMpV1(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External:Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/external/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary External:Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @summary External:Ping
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<string> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @summary External:Ping
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public ping(options?: any) {
        return PingApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PositionApi - axios parameter creator
 * @export
 */
export const PositionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Position:Download Concurrent Positions Report
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticConcurrentPositionDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('statisticConcurrentPositionDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('statisticConcurrentPositionDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('statisticConcurrentPositionDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/position/concurrent/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Position:Get Concurrent Positions
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticConcurrentPositionV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('statisticConcurrentPositionV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('statisticConcurrentPositionV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('statisticConcurrentPositionV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/position/concurrent`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Position:Download Positions Report
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticPositionDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('statisticPositionDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('statisticPositionDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('statisticPositionDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/position/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Position:Get Positions
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticPositionV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('statisticPositionV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('statisticPositionV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('statisticPositionV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/position`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PositionApi - functional programming interface
 * @export
 */
export const PositionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PositionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Position:Download Concurrent Positions Report
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticConcurrentPositionDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticConcurrentPositionDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Position:Get Concurrent Positions
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticConcurrentPositionV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticConcurrentPositionV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Position:Download Positions Report
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticPositionDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticPositionDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Position:Get Positions
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async statisticPositionV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.statisticPositionV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PositionApi - factory interface
 * @export
 */
export const PositionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PositionApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Position:Download Concurrent Positions Report
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticConcurrentPositionDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.statisticConcurrentPositionDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Position:Get Concurrent Positions
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticConcurrentPositionV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesPositionEntityConcurrentPositionEntityBaseFrontSettings> {
            return localVarFp.statisticConcurrentPositionV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Position:Download Positions Report
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticPositionDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.statisticPositionDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Position:Get Positions
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        statisticPositionV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesPositionEntityProductPositionEntityBaseFrontSettings> {
            return localVarFp.statisticPositionV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for statisticConcurrentPositionDownloadV1 operation in PositionApi.
 * @export
 * @interface PositionApiStatisticConcurrentPositionDownloadV1Request
 */
export interface PositionApiStatisticConcurrentPositionDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof PositionApiStatisticConcurrentPositionDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticConcurrentPositionDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticConcurrentPositionDownloadV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for statisticConcurrentPositionV1 operation in PositionApi.
 * @export
 * @interface PositionApiStatisticConcurrentPositionV1Request
 */
export interface PositionApiStatisticConcurrentPositionV1Request {
    /**
     * 
     * @type {number}
     * @memberof PositionApiStatisticConcurrentPositionV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticConcurrentPositionV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticConcurrentPositionV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for statisticPositionDownloadV1 operation in PositionApi.
 * @export
 * @interface PositionApiStatisticPositionDownloadV1Request
 */
export interface PositionApiStatisticPositionDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof PositionApiStatisticPositionDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticPositionDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticPositionDownloadV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for statisticPositionV1 operation in PositionApi.
 * @export
 * @interface PositionApiStatisticPositionV1Request
 */
export interface PositionApiStatisticPositionV1Request {
    /**
     * 
     * @type {number}
     * @memberof PositionApiStatisticPositionV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticPositionV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof PositionApiStatisticPositionV1
     */
    readonly dateTo: string
}

/**
 * PositionApi - object-oriented interface
 * @export
 * @class PositionApi
 * @extends {BaseAPI}
 */
export class PositionApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Position:Download Concurrent Positions Report
     * @param {PositionApiStatisticConcurrentPositionDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public statisticConcurrentPositionDownloadV1(requestParameters: PositionApiStatisticConcurrentPositionDownloadV1Request, options?: any) {
        return PositionApiFp(this.configuration).statisticConcurrentPositionDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Position:Get Concurrent Positions
     * @param {PositionApiStatisticConcurrentPositionV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public statisticConcurrentPositionV1(requestParameters: PositionApiStatisticConcurrentPositionV1Request, options?: any) {
        return PositionApiFp(this.configuration).statisticConcurrentPositionV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Position:Download Positions Report
     * @param {PositionApiStatisticPositionDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public statisticPositionDownloadV1(requestParameters: PositionApiStatisticPositionDownloadV1Request, options?: any) {
        return PositionApiFp(this.configuration).statisticPositionDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Position:Get Positions
     * @param {PositionApiStatisticPositionV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PositionApi
     */
    public statisticPositionV1(requestParameters: PositionApiStatisticPositionV1Request, options?: any) {
        return PositionApiFp(this.configuration).statisticPositionV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PriceHistoryApi - axios parameter creator
 * @export
 */
export const PriceHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Price History:Get Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceHistoryV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getPriceHistoryV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getPriceHistoryV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getPriceHistoryV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/price_history`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Price History:Download Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceHistoryDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('priceHistoryDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('priceHistoryDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('priceHistoryDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/price_history/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PriceHistoryApi - functional programming interface
 * @export
 */
export const PriceHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PriceHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Price History:Get Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriceHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriceHistoryV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Price History:Download Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async priceHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.priceHistoryDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PriceHistoryApi - factory interface
 * @export
 */
export const PriceHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PriceHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Price History:Get Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriceHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesPriceHistoryEntityPriceHistoryEntityBaseFrontSettings> {
            return localVarFp.getPriceHistoryV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Price History:Download Price History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        priceHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.priceHistoryDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getPriceHistoryV1 operation in PriceHistoryApi.
 * @export
 * @interface PriceHistoryApiGetPriceHistoryV1Request
 */
export interface PriceHistoryApiGetPriceHistoryV1Request {
    /**
     * 
     * @type {number}
     * @memberof PriceHistoryApiGetPriceHistoryV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof PriceHistoryApiGetPriceHistoryV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof PriceHistoryApiGetPriceHistoryV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for priceHistoryDownloadV1 operation in PriceHistoryApi.
 * @export
 * @interface PriceHistoryApiPriceHistoryDownloadV1Request
 */
export interface PriceHistoryApiPriceHistoryDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof PriceHistoryApiPriceHistoryDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof PriceHistoryApiPriceHistoryDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof PriceHistoryApiPriceHistoryDownloadV1
     */
    readonly dateTo: string
}

/**
 * PriceHistoryApi - object-oriented interface
 * @export
 * @class PriceHistoryApi
 * @extends {BaseAPI}
 */
export class PriceHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Price History:Get Price History
     * @param {PriceHistoryApiGetPriceHistoryV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceHistoryApi
     */
    public getPriceHistoryV1(requestParameters: PriceHistoryApiGetPriceHistoryV1Request, options?: any) {
        return PriceHistoryApiFp(this.configuration).getPriceHistoryV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Price History:Download Price History
     * @param {PriceHistoryApiPriceHistoryDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PriceHistoryApi
     */
    public priceHistoryDownloadV1(requestParameters: PriceHistoryApiPriceHistoryDownloadV1Request, options?: any) {
        return PriceHistoryApiFp(this.configuration).priceHistoryDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Product Analytics
         * @param {BodyGetProductAnalyticsApiV1ProductsAnalyticsPost} [bodyGetProductAnalyticsApiV1ProductsAnalyticsPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAnalyticsV1: async (bodyGetProductAnalyticsApiV1ProductsAnalyticsPost?: BodyGetProductAnalyticsApiV1ProductsAnalyticsPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/products/analytics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyGetProductAnalyticsApiV1ProductsAnalyticsPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductV1: async (productId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getProductV1', 'productId', productId)
            const localVarPath = `/api/v1/products/{product_id}`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Product List
         * @param {BodyGetProductListApiV1ProductsListPost} [bodyGetProductListApiV1ProductsListPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsV1: async (bodyGetProductListApiV1ProductsListPost?: BodyGetProductListApiV1ProductsListPost, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/products/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(bodyGetProductListApiV1ProductsListPost, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count Concurrent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productConcurrentCountV1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/products/concurrent_count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Product Analytics
         * @param {BodyGetProductAnalyticsApiV1ProductsAnalyticsPost} [bodyGetProductAnalyticsApiV1ProductsAnalyticsPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductAnalyticsV1(bodyGetProductAnalyticsApiV1ProductsAnalyticsPost?: BodyGetProductAnalyticsApiV1ProductsAnalyticsPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductAnalyticsV1(bodyGetProductAnalyticsApiV1ProductsAnalyticsPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductV1(productId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperProductFullEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductV1(productId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Product List
         * @param {BodyGetProductListApiV1ProductsListPost} [bodyGetProductListApiV1ProductsListPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProductsV1(bodyGetProductListApiV1ProductsListPost?: BodyGetProductListApiV1ProductsListPost, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProductsV1(bodyGetProductListApiV1ProductsListPost, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count Concurrent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productConcurrentCountV1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperProductConcurrentCountEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productConcurrentCountV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Product Analytics
         * @param {BodyGetProductAnalyticsApiV1ProductsAnalyticsPost} [bodyGetProductAnalyticsApiV1ProductsAnalyticsPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductAnalyticsV1(bodyGetProductAnalyticsApiV1ProductsAnalyticsPost?: BodyGetProductAnalyticsApiV1ProductsAnalyticsPost, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesProductAnalitycsProductAnalyticsEntityBaseFrontSettings> {
            return localVarFp.getProductAnalyticsV1(bodyGetProductAnalyticsApiV1ProductsAnalyticsPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Product
         * @param {number} productId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductV1(productId: number, options?: any): AxiosPromise<ResponseWrapperProductFullEntityBaseFrontSettings> {
            return localVarFp.getProductV1(productId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Product List
         * @param {BodyGetProductListApiV1ProductsListPost} [bodyGetProductListApiV1ProductsListPost] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsV1(bodyGetProductListApiV1ProductsListPost?: BodyGetProductListApiV1ProductsListPost, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesProductEntityProductEntityBaseFrontSettings> {
            return localVarFp.getProductsV1(bodyGetProductListApiV1ProductsListPost, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count Concurrent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productConcurrentCountV1(options?: any): AxiosPromise<ResponseWrapperProductConcurrentCountEntityBaseFrontSettings> {
            return localVarFp.productConcurrentCountV1(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getProductAnalyticsV1 operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductAnalyticsV1Request
 */
export interface ProductsApiGetProductAnalyticsV1Request {
    /**
     * 
     * @type {BodyGetProductAnalyticsApiV1ProductsAnalyticsPost}
     * @memberof ProductsApiGetProductAnalyticsV1
     */
    readonly bodyGetProductAnalyticsApiV1ProductsAnalyticsPost?: BodyGetProductAnalyticsApiV1ProductsAnalyticsPost
}

/**
 * Request parameters for getProductV1 operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductV1Request
 */
export interface ProductsApiGetProductV1Request {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiGetProductV1
     */
    readonly productId: number
}

/**
 * Request parameters for getProductsV1 operation in ProductsApi.
 * @export
 * @interface ProductsApiGetProductsV1Request
 */
export interface ProductsApiGetProductsV1Request {
    /**
     * 
     * @type {BodyGetProductListApiV1ProductsListPost}
     * @memberof ProductsApiGetProductsV1
     */
    readonly bodyGetProductListApiV1ProductsListPost?: BodyGetProductListApiV1ProductsListPost
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @summary Get Product Analytics
     * @param {ProductsApiGetProductAnalyticsV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductAnalyticsV1(requestParameters: ProductsApiGetProductAnalyticsV1Request = {}, options?: any) {
        return ProductsApiFp(this.configuration).getProductAnalyticsV1(requestParameters.bodyGetProductAnalyticsApiV1ProductsAnalyticsPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Product
     * @param {ProductsApiGetProductV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductV1(requestParameters: ProductsApiGetProductV1Request, options?: any) {
        return ProductsApiFp(this.configuration).getProductV1(requestParameters.productId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Product List
     * @param {ProductsApiGetProductsV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public getProductsV1(requestParameters: ProductsApiGetProductsV1Request = {}, options?: any) {
        return ProductsApiFp(this.configuration).getProductsV1(requestParameters.bodyGetProductListApiV1ProductsListPost, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count Concurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productConcurrentCountV1(options?: any) {
        return ProductsApiFp(this.configuration).productConcurrentCountV1(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Report:Concurrent With Price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentWithPriceV1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reports/concurrent_with_price/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report:Dynamic Position Products
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicPositionProductsV1: async (dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('dynamicPositionProductsV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('dynamicPositionProductsV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/reports/dynamic-position-products/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report:Abc
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAbcV1: async (dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getReportAbcV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getReportAbcV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/reports/abc/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report:Products Ordered
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsOrderedV1: async (dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('productsOrderedV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('productsOrderedV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/reports/products-ordered/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report:Summary Of Turnover Short
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryOfTurnoverShortV1: async (dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('summaryOfTurnoverShortV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('summaryOfTurnoverShortV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/reports/summary-of-turnover-short/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report:Template Upload Concurrent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templaceUploadConcurrentV1: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/reports/template_upload_concurrent/download`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Report:Upload Concurrent
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConcurrentV1: async (file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadConcurrentV1', 'file', file)
            const localVarPath = `/api/v1/reports/upload_concurrent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Report:Concurrent With Price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async concurrentWithPriceV1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.concurrentWithPriceV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report:Dynamic Position Products
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dynamicPositionProductsV1(dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.dynamicPositionProductsV1(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report:Abc
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportAbcV1(dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportAbcV1(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report:Products Ordered
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsOrderedV1(dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsOrderedV1(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report:Summary Of Turnover Short
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summaryOfTurnoverShortV1(dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summaryOfTurnoverShortV1(dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report:Template Upload Concurrent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async templaceUploadConcurrentV1(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.templaceUploadConcurrentV1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Report:Upload Concurrent
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadConcurrentV1(file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadConcurrentV1(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Report:Concurrent With Price
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        concurrentWithPriceV1(options?: any): AxiosPromise<any> {
            return localVarFp.concurrentWithPriceV1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report:Dynamic Position Products
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dynamicPositionProductsV1(dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.dynamicPositionProductsV1(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report:Abc
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportAbcV1(dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.getReportAbcV1(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report:Products Ordered
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsOrderedV1(dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.productsOrderedV1(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report:Summary Of Turnover Short
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summaryOfTurnoverShortV1(dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.summaryOfTurnoverShortV1(dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report:Template Upload Concurrent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        templaceUploadConcurrentV1(options?: any): AxiosPromise<any> {
            return localVarFp.templaceUploadConcurrentV1(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Report:Upload Concurrent
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadConcurrentV1(file: any, options?: any): AxiosPromise<any> {
            return localVarFp.uploadConcurrentV1(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for dynamicPositionProductsV1 operation in ReportsApi.
 * @export
 * @interface ReportsApiDynamicPositionProductsV1Request
 */
export interface ReportsApiDynamicPositionProductsV1Request {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiDynamicPositionProductsV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiDynamicPositionProductsV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for getReportAbcV1 operation in ReportsApi.
 * @export
 * @interface ReportsApiGetReportAbcV1Request
 */
export interface ReportsApiGetReportAbcV1Request {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportAbcV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiGetReportAbcV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for productsOrderedV1 operation in ReportsApi.
 * @export
 * @interface ReportsApiProductsOrderedV1Request
 */
export interface ReportsApiProductsOrderedV1Request {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiProductsOrderedV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiProductsOrderedV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for summaryOfTurnoverShortV1 operation in ReportsApi.
 * @export
 * @interface ReportsApiSummaryOfTurnoverShortV1Request
 */
export interface ReportsApiSummaryOfTurnoverShortV1Request {
    /**
     * 
     * @type {string}
     * @memberof ReportsApiSummaryOfTurnoverShortV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof ReportsApiSummaryOfTurnoverShortV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for uploadConcurrentV1 operation in ReportsApi.
 * @export
 * @interface ReportsApiUploadConcurrentV1Request
 */
export interface ReportsApiUploadConcurrentV1Request {
    /**
     * 
     * @type {any}
     * @memberof ReportsApiUploadConcurrentV1
     */
    readonly file: any
}

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Report:Concurrent With Price
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public concurrentWithPriceV1(options?: any) {
        return ReportsApiFp(this.configuration).concurrentWithPriceV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report:Dynamic Position Products
     * @param {ReportsApiDynamicPositionProductsV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public dynamicPositionProductsV1(requestParameters: ReportsApiDynamicPositionProductsV1Request, options?: any) {
        return ReportsApiFp(this.configuration).dynamicPositionProductsV1(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report:Abc
     * @param {ReportsApiGetReportAbcV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportAbcV1(requestParameters: ReportsApiGetReportAbcV1Request, options?: any) {
        return ReportsApiFp(this.configuration).getReportAbcV1(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report:Products Ordered
     * @param {ReportsApiProductsOrderedV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public productsOrderedV1(requestParameters: ReportsApiProductsOrderedV1Request, options?: any) {
        return ReportsApiFp(this.configuration).productsOrderedV1(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report:Summary Of Turnover Short
     * @param {ReportsApiSummaryOfTurnoverShortV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public summaryOfTurnoverShortV1(requestParameters: ReportsApiSummaryOfTurnoverShortV1Request, options?: any) {
        return ReportsApiFp(this.configuration).summaryOfTurnoverShortV1(requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report:Template Upload Concurrent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public templaceUploadConcurrentV1(options?: any) {
        return ReportsApiFp(this.configuration).templaceUploadConcurrentV1(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Report:Upload Concurrent
     * @param {ReportsApiUploadConcurrentV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public uploadConcurrentV1(requestParameters: ReportsApiUploadConcurrentV1Request, options?: any) {
        return ReportsApiFp(this.configuration).uploadConcurrentV1(requestParameters.file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ShopApi - axios parameter creator
 * @export
 */
export const ShopApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Shops
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopsV1: async (query?: string, limit?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/shops`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShopApi - functional programming interface
 * @export
 */
export const ShopApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShopApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Shops
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShopsV1(query?: string, limit?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShopsV1(query, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShopApi - factory interface
 * @export
 */
export const ShopApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShopApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Shops
         * @param {string} [query] 
         * @param {number} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShopsV1(query?: string, limit?: number, options?: any): AxiosPromise<ResponseWrapperUnionListAppDomainEntitiesShopEntityShopEntityNoneTypeBaseFrontSettings> {
            return localVarFp.getShopsV1(query, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getShopsV1 operation in ShopApi.
 * @export
 * @interface ShopApiGetShopsV1Request
 */
export interface ShopApiGetShopsV1Request {
    /**
     * 
     * @type {string}
     * @memberof ShopApiGetShopsV1
     */
    readonly query?: string

    /**
     * 
     * @type {number}
     * @memberof ShopApiGetShopsV1
     */
    readonly limit?: number
}

/**
 * ShopApi - object-oriented interface
 * @export
 * @class ShopApi
 * @extends {BaseAPI}
 */
export class ShopApi extends BaseAPI {
    /**
     * 
     * @summary Get Shops
     * @param {ShopApiGetShopsV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShopApi
     */
    public getShopsV1(requestParameters: ShopApiGetShopsV1Request = {}, options?: any) {
        return ShopApiFp(this.configuration).getShopsV1(requestParameters.query, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StockHistoryApi - axios parameter creator
 * @export
 */
export const StockHistoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Statistic:Stock History:Get Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockHistoryV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('getStockHistoryV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getStockHistoryV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getStockHistoryV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/stock_history`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Statistic:Stock History:Download Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockHistoryDownloadV1: async (productId: number, dateFrom: string, dateTo: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'productId' is not null or undefined
            assertParamExists('stockHistoryDownloadV1', 'productId', productId)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('stockHistoryDownloadV1', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('stockHistoryDownloadV1', 'dateTo', dateTo)
            const localVarPath = `/api/v1/products/{product_id}/statistics/stock_history/download`
                .replace(`{${"product_id"}}`, encodeURIComponent(String(productId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString().substr(0,10) :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString().substr(0,10) :
                    dateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockHistoryApi - functional programming interface
 * @export
 */
export const StockHistoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockHistoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Statistic:Stock History:Get Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStockHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStockHistoryV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Statistic:Stock History:Download Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockHistoryDownloadV1(productId, dateFrom, dateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StockHistoryApi - factory interface
 * @export
 */
export const StockHistoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockHistoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Statistic:Stock History:Get Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockHistoryV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<ResponseWrapperListAppDomainEntitiesStockHistoryEntityStockHistoryEntityBaseFrontSettings> {
            return localVarFp.getStockHistoryV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Statistic:Stock History:Download Stock History
         * @param {number} productId 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockHistoryDownloadV1(productId: number, dateFrom: string, dateTo: string, options?: any): AxiosPromise<any> {
            return localVarFp.stockHistoryDownloadV1(productId, dateFrom, dateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getStockHistoryV1 operation in StockHistoryApi.
 * @export
 * @interface StockHistoryApiGetStockHistoryV1Request
 */
export interface StockHistoryApiGetStockHistoryV1Request {
    /**
     * 
     * @type {number}
     * @memberof StockHistoryApiGetStockHistoryV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof StockHistoryApiGetStockHistoryV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof StockHistoryApiGetStockHistoryV1
     */
    readonly dateTo: string
}

/**
 * Request parameters for stockHistoryDownloadV1 operation in StockHistoryApi.
 * @export
 * @interface StockHistoryApiStockHistoryDownloadV1Request
 */
export interface StockHistoryApiStockHistoryDownloadV1Request {
    /**
     * 
     * @type {number}
     * @memberof StockHistoryApiStockHistoryDownloadV1
     */
    readonly productId: number

    /**
     * 
     * @type {string}
     * @memberof StockHistoryApiStockHistoryDownloadV1
     */
    readonly dateFrom: string

    /**
     * 
     * @type {string}
     * @memberof StockHistoryApiStockHistoryDownloadV1
     */
    readonly dateTo: string
}

/**
 * StockHistoryApi - object-oriented interface
 * @export
 * @class StockHistoryApi
 * @extends {BaseAPI}
 */
export class StockHistoryApi extends BaseAPI {
    /**
     * 
     * @summary Statistic:Stock History:Get Stock History
     * @param {StockHistoryApiGetStockHistoryV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockHistoryApi
     */
    public getStockHistoryV1(requestParameters: StockHistoryApiGetStockHistoryV1Request, options?: any) {
        return StockHistoryApiFp(this.configuration).getStockHistoryV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Statistic:Stock History:Download Stock History
     * @param {StockHistoryApiStockHistoryDownloadV1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockHistoryApi
     */
    public stockHistoryDownloadV1(requestParameters: StockHistoryApiStockHistoryDownloadV1Request, options?: any) {
        return StockHistoryApiFp(this.configuration).stockHistoryDownloadV1(requestParameters.productId, requestParameters.dateFrom, requestParameters.dateTo, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SyncApi - axios parameter creator
 * @export
 */
export const SyncApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Manual Run Sync Shop
         * @param {number} syncLogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRunSyncShop: async (syncLogId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'syncLogId' is not null or undefined
            assertParamExists('manualRunSyncShop', 'syncLogId', syncLogId)
            const localVarPath = `/api/v1/sync/manual_run_sync_shop/{sync_log_id}`
                .replace(`{${"sync_log_id"}}`, encodeURIComponent(String(syncLogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SyncApi - functional programming interface
 * @export
 */
export const SyncApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SyncApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Manual Run Sync Shop
         * @param {number} syncLogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async manualRunSyncShop(syncLogId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseWrapperSyncShopLogEntityBaseFrontSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.manualRunSyncShop(syncLogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SyncApi - factory interface
 * @export
 */
export const SyncApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SyncApiFp(configuration)
    return {
        /**
         * 
         * @summary Manual Run Sync Shop
         * @param {number} syncLogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        manualRunSyncShop(syncLogId: number, options?: any): AxiosPromise<ResponseWrapperSyncShopLogEntityBaseFrontSettings> {
            return localVarFp.manualRunSyncShop(syncLogId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for manualRunSyncShop operation in SyncApi.
 * @export
 * @interface SyncApiManualRunSyncShopRequest
 */
export interface SyncApiManualRunSyncShopRequest {
    /**
     * 
     * @type {number}
     * @memberof SyncApiManualRunSyncShop
     */
    readonly syncLogId: number
}

/**
 * SyncApi - object-oriented interface
 * @export
 * @class SyncApi
 * @extends {BaseAPI}
 */
export class SyncApi extends BaseAPI {
    /**
     * 
     * @summary Manual Run Sync Shop
     * @param {SyncApiManualRunSyncShopRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SyncApi
     */
    public manualRunSyncShop(requestParameters: SyncApiManualRunSyncShopRequest, options?: any) {
        return SyncApiFp(this.configuration).manualRunSyncShop(requestParameters.syncLogId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TestGrpcApi - axios parameter creator
 * @export
 */
export const TestGrpcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary External:Test Grpc
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGrpc: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('testGrpc', 'id', id)
            const localVarPath = `/api/external/test_grpc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TestGrpcApi - functional programming interface
 * @export
 */
export const TestGrpcApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TestGrpcApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary External:Test Grpc
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testGrpc(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testGrpc(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TestGrpcApi - factory interface
 * @export
 */
export const TestGrpcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TestGrpcApiFp(configuration)
    return {
        /**
         * 
         * @summary External:Test Grpc
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGrpc(id: number, options?: any): AxiosPromise<string> {
            return localVarFp.testGrpc(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for testGrpc operation in TestGrpcApi.
 * @export
 * @interface TestGrpcApiTestGrpcRequest
 */
export interface TestGrpcApiTestGrpcRequest {
    /**
     * 
     * @type {number}
     * @memberof TestGrpcApiTestGrpc
     */
    readonly id: number
}

/**
 * TestGrpcApi - object-oriented interface
 * @export
 * @class TestGrpcApi
 * @extends {BaseAPI}
 */
export class TestGrpcApi extends BaseAPI {
    /**
     * 
     * @summary External:Test Grpc
     * @param {TestGrpcApiTestGrpcRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TestGrpcApi
     */
    public testGrpc(requestParameters: TestGrpcApiTestGrpcRequest, options?: any) {
        return TestGrpcApiFp(this.configuration).testGrpc(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}


