var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PeriodDataGrid',{attrs:{"remote":false,"columns":_vm.columns,"items":_vm.items,"itemKey":_vm.itemKey,"loading":_vm.loading,"selected":_vm.selectedItems,"rowClasses":_vm.yourProductRowClasses,"primaryItemMatcher":_vm.primaryItemMatcher,"showSelect":"","coloredCheckboxes":""},on:{"update:selected":function($event){return _vm.$emit('update:selectedItems', $event)}},scopedSlots:_vm._u([{key:"cell.shop",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shopName(item))+" ")]}},{key:"cell.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.product.name))]),_c('div',{staticClass:"gray-darker--text"},[_vm._v(_vm._s(_vm.$t('Article:', [item.product.article])))])]}},{key:"period-cell",fn:function(ref){
var item = ref.item;
var parentItem = ref.parentItem;
return [_c('div',{class:parentItem.isMy && 'font-weight-semibold'},[_vm._v(" "+_vm._s(_vm.valueFormatter(item))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }