export function discardable<T = any> (cb: (...args: any[]) => Promise<T>) {
  let lastId = 0

  const thenCbs: ((res: T) => any)[] = []
  const catchCbs: ((err: any) => any)[] = []
  const finallyCbs: (() => any)[] = []

  const fn = (...args: any[]) => {
    const id = ++lastId

    // eslint-disable-next-line
    cb(...args)
      .then(res => {
        if (thenCbs.length && id === lastId) {
          thenCbs.reduce((acc, cb) => cb(acc), res)
        }
      })
      .catch(res => {
        if (catchCbs.length && id === lastId) {
          catchCbs.reduce((acc, cb) => cb(acc), res)
        }
      })
      .finally(() => {
        if (finallyCbs.length && id === lastId) {
          finallyCbs.forEach(cb => {
            cb()
          })
        }
      })

    return fn
  }

  fn.then = (cb: (res: T) => any) => {
    thenCbs.push(cb)
    return fn
  }

  fn.catch = (cb: (err: any) => any) => {
    catchCbs.push(cb)
    return fn
  }

  fn.finally = (cb: () => any) => {
    finallyCbs.push(cb)
    return fn
  }

  fn.discard = () => {
    lastId++
    return fn
  }

  return fn
}
