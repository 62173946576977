


































import { defineComponent } from '@vue/composition-api'
import { SearchType } from '@/openapi'

export default defineComponent({
  inheritAttrs: false,
  model: {
    prop: 'modelValue',
    event: 'change'
  },
  setup () {
    return {
      SearchType
    }
  }
})
