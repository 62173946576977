var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PeriodDataGrid',{attrs:{"remote":false,"columns":_vm.columns,"customDateColumn":_vm.customDateColumn,"items":_vm.items,"itemKey":_vm.itemKey,"loading":_vm.loading,"selected":_vm.selectedItems,"rowClasses":_vm.yourProductRowClasses,"primaryItemMatcher":_vm.primaryItemMatcher,"search":_vm.search,"customFilter":_vm.customFilter,"customSort":_vm.sortPositions,"showSelect":"","coloredCheckboxes":""},on:{"update:selected":function($event){return _vm.$emit('update:selectedItems', $event)}},scopedSlots:_vm._u([{key:"above.headerCell.shop",fn:function(){return [_c('div',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.request.field))]),_c('div',{staticClass:"gray-darker--text"},[_vm._v(_vm._s(_vm.$t('Type category', [_vm.searchTypeMapper(_vm.request.searchType)])))])]},proxy:true},{key:"above.headerCell.product",fn:function(){return [_c('x-input',{attrs:{"placeholder":_vm.$t('Search by products'),"prependIcon":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"cell.shop",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatShopName(item))+" "),(item.isMy && _vm.request && _vm.request.withoutMy)?_c('div',{staticClass:"gray-darker--text"},[_vm._v(" "+_vm._s(_vm.$t('Missing in request'))+" ")]):_vm._e()]}},{key:"cell.product",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(item.product.name))]),_c('div',{staticClass:"gray-darker--text"},[_vm._v(_vm._s(_vm.$t('Article:', [item.product.article])))])]}},{key:"period-cell",fn:function(ref){
var item = ref.item;
var parentItem = ref.parentItem;
return [_c('div',{class:parentItem.isMy && 'font-weight-semibold'},[_vm._v(" "+_vm._s(_vm.formatCount(item.current))+" ")]),_c('div',{staticClass:"font-weight-semibold",class:_vm.progressClasses(item.progress)},[_vm._v(" "+_vm._s(_vm.formatCountWithSign(item.progress))+" ")])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }