import { getCurrentInstance, inject, onActivated, provide, Ref, ref, watch } from '@vue/composition-api'

const TOKEN = 'COMPETITORS_TOKEN'
const INVALIDATE_COMPETITORS = 'INVALIDATE_COMPETITORS'

type Token = Ref<number>
type InvalidateCompetitors = () => Token

export function useCompetitorsProviders () {
  const token = ref(0) as Token
  const invalidateCompetitors = () => token.value++

  provide(TOKEN, token)
  provide(INVALIDATE_COMPETITORS, invalidateCompetitors)
}

export function useCompetitorsInjections () {
  return {
    token: inject(TOKEN) as Token,
    invalidateCompetitors: inject(INVALIDATE_COMPETITORS) as InvalidateCompetitors
  }
}

export function useBindCompetitors (cb: () => void) {
  const vm = getCurrentInstance()!
  const { token } = useCompetitorsInjections()

  let savedToken = token.value

  watch(token, val => {
    if (!vm.isDeactivated) {
      cb()
      savedToken = val
    }
  })

  onActivated(() => {
    const isDifferentTokens = savedToken !== token.value

    if (isDifferentTokens) {
      cb()
      savedToken = token.value
    }
  })
}
