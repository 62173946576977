<template>
  <x-btn
    v-bind="$attrs"
    :loading="loading"
    :color="color"
    prepend="download"
    @click="onClick"
  >
    <slot>
      {{ $t('download') }}
    </slot>
  </x-btn>
</template>

<script>
import { downloadFileFromResponse } from '@/utils/downloadFileFromResponse'
export default {
  inheritAttrs: false,
  props: {
    color: {
      type: String,
      default: 'gray'
    }
  },
  data: () => ({
    loading: false
  }),
  methods: {
    async onClick () {
      if (!this.$listeners.click) return

      this.loading = true
      try {
        const res = await this.$listeners.click()

        downloadFileFromResponse(res)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
