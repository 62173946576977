








































































import { computed, defineComponent, watch } from '@vue/composition-api'
import { useInnerTab, useFilter, useSelectedItems } from '../../../inner-tab-template/useInnerTab'
import { useSearchTypeFilter } from '../useSearchTypeFilter'
import { useStore } from '@/store/store'
import { positionsApi } from '@/api'

import InnerTabTemplate from '../../../inner-tab-template/InnerTabTemplate.vue'
import CurrentPeriodPicker from '@/components/CurrentPeriodPicker.vue'
import PositionsComparisonChart from './PositionsComparisonChart.vue'
import PositionsComparisonGrid from './PositionsComparisonGrid.vue'

import dayjs from 'dayjs'
import { ConcurrentPositionEntitySpanRow } from './positionsComparisonTypes'
import { SearchType } from '@/openapi'
import CompetitorsLink from '../../../CompetitorsLink.vue'
import SearchTypeFilter from '../SearchTypeFilter.vue'
import { useProductId } from '@/views/product-review/useProductId'
import { useMetrics } from '@/utils/useMetrics'
import PERMISSION_KEY from '@/constants/PERMISSION_KEY'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'

export default defineComponent({
  components: {
    NotPermitByTariff,
    InnerTabTemplate,
    PositionsComparisonChart,
    PositionsComparisonGrid,
    CurrentPeriodPicker,
    CompetitorsLink,
    SearchTypeFilter
  },
  setup () {
    const store = useStore()
    const metrics = useMetrics()
    const productId = useProductId()

    const downloadComparison = () => {
      const from = dayjs(store.currentPeriod.start).format('YYYY-MM-DD')
      const to = dayjs(store.currentPeriod.end).format('YYYY-MM-DD')

      metrics.hit('analytics_products_product_positions_compare_report', {
        period: `from_${from}_to_${to}`
      })

      return positionsApi.statisticConcurrentPositionDownloadV1(
        productId.value,
        from,
        to,
        { responseType: 'blob' }
      )
    }

    const tab = useInnerTab({
      async fetchItems () {
        const res = await positionsApi.statisticConcurrentPositionV1(
          productId.value,
          dayjs(store.currentPeriod.start).format('YYYY-MM-DD'),
          dayjs(store.currentPeriod.end).format('YYYY-MM-DD')
        )

        res.data.data?.forEach(el => {
          // @ts-ignore
          // TODO: remove
          el.id = el.field
        })

        return res.data.data
      },
      trackCompetitorsChanges: true
    })

    const { searchType } = useSearchTypeFilter(() => tab.items)

    const prefilteredItems = computed(() => {
      return tab.items?.filter(el => el.searchType === searchType.value)
    })

    const { filter, filterOptions } = useFilter(
      prefilteredItems,
      item => item.field + (item.concurrentCount ? ` (${item.concurrentCount})` : '')
    )

    const filteredItems = computed(() => {
      return prefilteredItems.value?.find(el => el.field === filter.value?.field)?.spanRows as (ConcurrentPositionEntitySpanRow[] | undefined)
    })

    watch(prefilteredItems, () => {
      if (filterOptions.value.length) {
        const notSelected = !filter.value
        const notAvailable = !filterOptions.value.find(el => el === filter.value)

        if (
          notSelected ||
          notAvailable
        ) {
          filter.value = filterOptions.value[0]
        }
      } else {
        filter.value = null
      }
    })

    const { selectedItems } = useSelectedItems(filteredItems, {
      itemKey: item => item.product.article
    })

    const noCompetitors = computed(() =>
      filteredItems.value?.length === 1 && (filteredItems.value[0] as ConcurrentPositionEntitySpanRow).isMy
    )

    const isPermitByTariff = computed(() => {
      return store.isPermitByTariff(PERMISSION_KEY.ANALYTICS_COMPETITORS_COMPARE)
    })

    return {
      tab,
      filter,
      filterOptions,
      filteredItems,
      selectedItems,
      downloadComparison,
      noCompetitors,
      SearchType,
      searchType,
      isPermitByTariff
    }
  }
})
